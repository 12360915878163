import {
  shiftModalRoutingService,
  useShiftModalUIContext,
} from 'components/ShiftModal/ShiftModal.ui.context';
import { useShiftModalDataContext } from 'components/ShiftModal/ShiftModalProvider.data.context';
import { useSendToAgenciesFormContext } from 'components/ShiftModal/src/views/SendToAgenciesView/SendToAgencies.form.context';
import { SendToAgenciesTargetType } from 'components/ShiftModal/src/views/SendToAgenciesView/SendToAgenciesView.types';
import { useURLParameters } from 'hooks';
import { createContext, FC, useCallback, useContext, useMemo, useEffect } from 'react';

interface ISendToAgenciesProps {
  goToNextView: () => void;
}

export const SendToAgenciesPropsContext = createContext<ISendToAgenciesProps>(
  {} as ISendToAgenciesProps,
);

export const SendToAgenciesPropsProvider: FC = ({ children }) => {
  const { shiftModalActiveModalView } = useShiftModalUIContext();
  const { values } = useSendToAgenciesFormContext();
  const { data } = useShiftModalDataContext();

  useEffect(() => {
    if (!values.send_to_agencies_target && shiftModalActiveModalView !== 'PreRequestOptionsView')
      shiftModalRoutingService.goToView('PreRequestOptionsView');
  }, [shiftModalActiveModalView]); // eslint-disable-line react-hooks/exhaustive-deps

  const [, { removeParam }] = useURLParameters();
  useEffect(() => {
    if (data.fetchOrder && !data.fetchOrder.canSendToAgency)
      removeParam(['shiftModalShiftId', 'shiftModalActiveModalView']);
  }, [data.fetchOrder, data.fetchOrder.canSendToAgency, removeParam]);

  const goToNextView = useCallback(() => {
    switch (shiftModalActiveModalView) {
      case 'PreRequestOptionsView':
        switch (values.send_to_agencies_target as SendToAgenciesTargetType) {
          case 'all':
            shiftModalRoutingService.goToView('SendToAgenciesSummaryView');
            break;
          case 'request_member':
            shiftModalRoutingService.goToView('RequestTeamMemberView');
            break;
          case 'request_agencies':
            shiftModalRoutingService.goToView('SelectAgenciesView');
            break;
        }
        break;

      case 'RequestTeamMemberView':
        shiftModalRoutingService.goToView('PostRequestOptionsView');
        break;

      case 'PostRequestOptionsView':
      case 'SelectAgenciesView':
        shiftModalRoutingService.goToView('SendToAgenciesSummaryView');
        break;

      case 'SendToAgenciesSummaryView':
        break;
    }
  }, [shiftModalActiveModalView, values.send_to_agencies_target]);

  const contextValue = useMemo(
    () => ({
      goToNextView,
    }),
    [goToNextView],
  );

  return (
    <SendToAgenciesPropsContext.Provider value={contextValue}>
      {children}
    </SendToAgenciesPropsContext.Provider>
  );
};

export const useSendToAgenciesPropsContext = (): ISendToAgenciesProps =>
  useContext(SendToAgenciesPropsContext);
