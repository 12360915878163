import {
  BKJButton,
  BKJComboBox,
  BKJComboBoxAsync,
  BKJListItem,
  BKJSelect,
} from '@bookjane2/bookjane-design-library';
import { AutoMargin } from 'components/AutoMargin';
import { BKJSideModalFooter } from 'components/BKJSideModalFooter';
import closeSVG from 'assets/scheduling/close.svg';
import { Flex } from 'components/Flex';
import {
  LocationHeader,
  ScheduleRulesDescription,
  TableRowCellButton,
} from 'components/ScheduleSettingsModal/views/ScheduleRules.styled';
import { SectionDivider } from 'components/ShiftModal/ShiftModal.styled';
import { H3 } from 'components/Typography';
import { BKJIconWrapper } from 'pages/SettingsPage/CustomFieldsSettingsPage/CustomFieldsSettingsComponent/CustomFieldsSettingsComponent.styled';
import { FC, Fragment, MouseEventHandler, useState } from 'react';
import { css } from 'styled-components';
import {
  ShiftDistributionRulesGroupsModalDataContext,
  useShiftDistributionRulesGroupsModalDataContext,
} from 'components/ShiftDistributionRulesGroupsModal/ShiftDistributionRulesGroupsModal.data.context';
import { shiftDistributionRulesGroupsModalRoutingService } from 'components/ShiftDistributionRulesGroupsModal/ShiftDistributionRulesGroupsModal.ui.context';
import {
  CloseButtonImage,
  DeleteRowColumn,
  OrderColumn,
  PositionRuleTableRowCell,
  PositionRuleTableRowCellContent,
  SendToGroupColumn,
  SendToGroupsDropdownLabel,
  SendToGroupsLabel,
  SendToGroupsSection,
  SendToGroupsTableLabel,
  ShiftThresholdHeader,
} from 'components/ShiftDistributionRulesGroupsModal/views/AddPositionRule.styled';
import {
  waitTimesDropdownOptions,
  skipWaitTimeMinutesThresholdDropdownOptions,
} from 'components/ShiftDistributionRulesGroupsModal/ShiftDistributionRulesGroupsModal.constants';
import { formatNumberWithOrdinalSuffix } from 'utils';
import { useLocationByIdDataContext } from 'components/ShiftDistributionRulesGroupsModal/LocationById.data.context';
import {
  ViewDetailsActionsProvider,
  useViewDetailsActionsContext,
} from 'components/ShiftDistributionRulesGroupsModal/views/ViewDetails.actions.context';
import {
  ViewDetailsDataProvider,
  useViewDetailsDataContext,
} from 'components/ShiftDistributionRulesGroupsModal/views/ViewDetails.data.context';
import { useURLParameters } from 'hooks';
import {
  EditRuleFormProvider,
  useEditRuleFormContext,
} from 'components/ShiftDistributionRulesGroupsModal/views/EditRule.form.context';
import {
  DefaultRuleLabel,
  LocationName,
  ModalContentWrapper,
} from 'components/ShiftDistributionRulesGroupsModal/views/ViewDetails.styled';
import { BKJCenteredDialog } from 'components/BKJCenteredDialog';
import { useTranslation } from 'hooks/useTranslation';

export const EditView: FC = () => {
  const { onDelete } = useViewDetailsActionsContext();
  const { data, isLoading } = useViewDetailsDataContext();

  const {
    data: groupsOptions = [],
    values: searchValues,
    onChange: onSearchChange,
  } = useShiftDistributionRulesGroupsModalDataContext();

  const { location } = useLocationByIdDataContext();

  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false);

  const {
    values,
    onChange,
    onSubmit,
    isSubmitting,
    isDisabled,
    addGroupRule,
    onGroupSelect,
    onGroupDelete,
    onWaitTimeSelect,
    groupRules,
  } = useEditRuleFormContext();

  const waitTimeThreshold = Math.floor(
    Number.parseInt(values.skip_wait_time_threshold_minutes[0]) / 60,
  );

  const waitTimeThresholdLabel = waitTimeThreshold !== 0 ? `${waitTimeThreshold}` : '<6';

  const { t } = useTranslation();

  if (isLoading) {
    return <Fragment />;
  }

  if (!groupsOptions) return null;

  return (
    <ModalContentWrapper>
      <Flex flexDirection="column" padding="32px 40px 0">
        <H3>{t('location_settings:CONFIGURE_RULES_FOR_POSITION')}</H3>
        <ScheduleRulesDescription>
          {t('location_settings:CONFIGURE_RULES_FOR_POSITION_DESCRIPTION')}
        </ScheduleRulesDescription>

        <LocationHeader>{t('common:LOCATION')}</LocationHeader>
        <LocationName>{location?.label}</LocationName>

        <LocationHeader
          css={css`
            padding-bottom: 5px;
          `}
        >
          {t('common:POSITION')}
        </LocationHeader>
        {!!data?.position?.name ? (
          <LocationName>{data?.position?.name}</LocationName>
        ) : (
          <LocationName>
            {t('common:DEFAULT')} &nbsp;
            <DefaultRuleLabel>{t('location_settings:APPLIES_TO_ALL')}</DefaultRuleLabel>
          </LocationName>
        )}
      </Flex>
      <Flex flexDirection="column" width="100%">
        <SectionDivider margin="0 0 24px 0" variant="Thin" />

        <SendToGroupsSection>
          <OrderColumn>
            <SendToGroupsLabel>{t('location_settings:ORDER')}</SendToGroupsLabel>
          </OrderColumn>
          <SendToGroupColumn>
            <SendToGroupsLabel>{t('location_settings:SEND_TO_GROUP')}</SendToGroupsLabel>
          </SendToGroupColumn>
        </SendToGroupsSection>
        {groupRules.map(({ groupOption, wait_time_minutes }, i) => (
          <Fragment key={i}>
            {i !== 0 && (
              <PositionRuleTableRowCell isGrey={false}>
                <PositionRuleTableRowCellContent>
                  <OrderColumn>
                    <SendToGroupsTableLabel>{t('location_settings:WAIT')}*</SendToGroupsTableLabel>
                  </OrderColumn>
                  <SendToGroupColumn>
                    <SendToGroupsDropdownLabel>
                      <BKJComboBox
                        type="Single"
                        value={[wait_time_minutes]}
                        options={waitTimesDropdownOptions()}
                        name={`${groupOption.name}-${i}-${wait_time_minutes}`}
                        action="OpenToggle"
                        filter="TimeStartsWith"
                        placeholder="0 mins"
                        onChange={(e) => {
                          return onWaitTimeSelect(e.target.value, i);
                        }}
                      />
                    </SendToGroupsDropdownLabel>
                  </SendToGroupColumn>
                </PositionRuleTableRowCellContent>
              </PositionRuleTableRowCell>
            )}
            <PositionRuleTableRowCell isGrey={true}>
              <PositionRuleTableRowCellContent>
                <OrderColumn>
                  <SendToGroupsTableLabel>{`${formatNumberWithOrdinalSuffix(
                    i + 1,
                  )}`}</SendToGroupsTableLabel>
                </OrderColumn>
                <SendToGroupColumn>
                  <SendToGroupsDropdownLabel>
                    <BKJComboBoxAsync
                      key={groupOption.key}
                      name={groupOption.name}
                      label=""
                      value={groupOption}
                      type="Single"
                      searchKey="withName"
                      onChange={(e) => {
                        return onGroupSelect(e.target.value, i);
                      }}
                      action="OpenToggle"
                      options={groupsOptions}
                      searchValue={searchValues?.filter?.withName || ''}
                      onSearchChange={onSearchChange}
                      context={ShiftDistributionRulesGroupsModalDataContext}
                      placeholder={t('location_settings:SELECT_GROUP')}
                    />
                  </SendToGroupsDropdownLabel>
                </SendToGroupColumn>
                {i > 0 && (
                  <DeleteRowColumn>
                    <CloseButtonImage onClick={() => onGroupDelete(i)} alt="" src={closeSVG} />
                  </DeleteRowColumn>
                )}
              </PositionRuleTableRowCellContent>
            </PositionRuleTableRowCell>
          </Fragment>
        ))}
      </Flex>
      <Flex flexDirection="column" padding="24px 22px 0">
        <TableRowCellButton variant="PurpleText" onClick={addGroupRule}>
          <BKJIconWrapper iconName="Add" color={'PrimaryDefault'} />
          {t('location_settings:ADD_GROUP')}
        </TableRowCellButton>
        <ShiftThresholdHeader>
          {t('location_settings:SKIP_WAIT_TIME_WITHIN', { value: waitTimeThresholdLabel })}
        </ShiftThresholdHeader>
        <Flex width="318px" padding="0 0 110px 20px">
          <BKJSelect
            width="475px"
            dropdownWidth="475px"
            variant="GreyOutlined"
            renderOptions={({ options, selected, onChange }) => {
              return (
                <Fragment>
                  {options.map(({ key, label, value }) => {
                    return (
                      <BKJListItem
                        name="skip_wait_time_threshold_minutes"
                        selected={selected}
                        key={key}
                        label={label}
                        value={value}
                        onChange={onChange}
                      />
                    );
                  })}
                </Fragment>
              );
            }}
            type="Single"
            value={values.skip_wait_time_threshold_minutes}
            options={skipWaitTimeMinutesThresholdDropdownOptions}
            name={`skip_wait_time_threshold_minutes`}
            action="OpenToggle"
            placeholder="24 hrs"
            onChange={onChange}
          />
        </Flex>
      </Flex>
      <BKJCenteredDialog
        isOpen={isDeleteConfirmationOpen}
        variant="Danger"
        title={t('location_settings:DELETE_RULES_FOR_POSITION')}
        data-cy="delete-rules-modal"
        text={t('location_settings:DELETE_RULES_FOR_POSITION_DESCRIPTION')}
        cancelLabel={t('auth:CANCEL')}
        actionLabel={t('auth:DELETE')}
        onAction={() => {
          setIsDeleteConfirmationOpen(false);
          return onDelete({ shiftDistributionRuleId: `${data.id}` });
        }}
        onCancel={() => setIsDeleteConfirmationOpen(false)}
        onClose={() => setIsDeleteConfirmationOpen(false)}
      />
      <Flex height="100%" />
      <BKJSideModalFooter justifyContent="space-between" flexDirection="row">
        {!!data?.position?.id && (
          <BKJButton
            variant="RedText"
            width="180px"
            onClick={() => setIsDeleteConfirmationOpen(true)}
            css={css`
              margin-right: 5px;
            `}
            type="button"
            disabled={false}
          >
            {t('auth:DELETE')}
          </BKJButton>
        )}
        <AutoMargin />
        <BKJButton
          variant="PurpleOutlined"
          width="180px"
          onClick={() => shiftDistributionRulesGroupsModalRoutingService.goToPrevView()}
          css={css`
            margin-right: 5px;
          `}
          type="button"
          disabled={false}
        >
          {t('auth:CANCEL')}
        </BKJButton>
        <BKJButton
          variant="PurpleSolid"
          width="180px"
          onClick={onSubmit as unknown as MouseEventHandler<HTMLButtonElement>}
          disabled={isDisabled || isSubmitting}
        >
          {t('auth:SAVE')}
        </BKJButton>
      </BKJSideModalFooter>
    </ModalContentWrapper>
  );
};

const EditRuleFormView: FC = () => {
  const { isLoading } = useViewDetailsDataContext();
  if (isLoading) return <Fragment />;
  return (
    <EditRuleFormProvider>
      <ViewDetailsActionsProvider>
        <EditView />
      </ViewDetailsActionsProvider>
    </EditRuleFormProvider>
  );
};

export const EditRule: FC = () => {
  const [{ positionSettingsId }] = useURLParameters<{ positionSettingsId?: string }>();
  return (
    <ViewDetailsDataProvider positionSettingsId={positionSettingsId}>
      <EditRuleFormView />
    </ViewDetailsDataProvider>
  );
};
