import {
  CustomDataType,
  EventListenerFn,
  J360PushNotificationEvent,
  PusherClient,
  pusherConnect,
} from 'services/SocketService/pusher-functions';
import { SocketServiceProviderType } from 'services/SocketService/SocketService.constants';
import { SHA1_BASE64 } from 'utils/sha1-base64';

export interface SocketConnectionOptions {}

interface Client extends Partial<PusherClient> {
  disconnect: () => void;
  connect: () => void;
  addEventListener: () => void;
}

type SocketServiceProto = {
  client?: Client;
  readonly provider: SocketServiceProviderType;
  listeners: Record<string, [i0: J360PushNotificationEvent, i1: EventListenerFn]>;
};

/**
 * BookJane's Web Socket Service
 * @authors [David Zahiri](https://github.com/twistedrc1017)
 */
export class SocketService {
  __proto__: SocketServiceProto;
  constructor(provider: SocketServiceProviderType) {
    this.__proto__ = {
      provider,
      listeners: {},
      client: {
        disconnect: () => null,
        connect: () => null,
        addEventListener: () => null,
      },
    };
  }

  private handleEvent = (event: J360PushNotificationEvent, data: CustomDataType) => {
    const entries = Object.entries(this.__proto__.listeners);
    entries.forEach(([, [registeredEvent, callback]]) => {
      if (event === registeredEvent) callback(data);
    });
  };

  public connect = () => {
    const { provider } = this.__proto__;
    switch (provider) {
      case 'Pusher': {
        const pusher = pusherConnect(this.handleEvent)!;
        if (!pusher) throw new Error('Client undefined');
      }
    }
  };

  public addEventListener = (event: J360PushNotificationEvent, cb: EventListenerFn) => {
    this.__proto__.listeners[SHA1_BASE64(cb.toString())] = [event, cb];
  };

  public disconnect = () => {
    const { client, provider } = this.__proto__;
    if (provider === 'Pusher') {
      client?.disconnect();
      delete this.__proto__.client;
      this.__proto__.client = {
        disconnect: () => null,
        connect: () => null,
        addEventListener: () => null,
      };
      return void 0;
    }
  };
}
