import { DateTime } from 'luxon';
import {
  IDaySchedule,
  IDynamicMappedShiftResponse,
  IMappedShiftResponse,
  IPublishedShiftResponse,
  ITotals,
  ShiftCategory,
} from 'mappers/scheduling/schedule.rest.types';

export const TYPE_SHARED_TEAM_MEMBER = 'shared_team_member';
export const TYPE_INTERNAL_TEAM_MEMBER = 'internal_team_member';
export const TYPE_PUBLISHED_UNASSIGNED = 'published_unassigned';
export const TYPE_AGENCY_MEMBER = 'agency_member';
export const TYPE_PUBLISHED_OTHER_POSITION = 'published_other_position';

function dayArrToDayMap(arr: IDaySchedule[], _type: ShiftCategory) {
  const data: { [key: string]: IMappedShiftResponse } = {};
  const totals: ITotals = {
    statuses: {
      open: 0,
      pending: 0,
      assigned: 0,
      in_progress: 0,
      accepted: 0,
      completed: 0,
      unfulfilled: 0,
      cancelled: 0,
      swapped: 0,
    },
    sent_to_agencies: 0,
  };
  arr.forEach(({ date, shifts }) => {
    const shiftsMapByDay: IMappedShiftResponse = { shifts: {}, shifts_ids: [] };
    shifts.forEach((shift) => {
      shiftsMapByDay.shifts[shift.id] = { ...shift, _type };
      if (shift.sent_to_agencies) totals.sent_to_agencies += 1;
      if (shift.status === 'pending' && shift.current_status === 'Unfulfilled') {
        totals.statuses.unfulfilled += 1;
      }

      if (['assigned', 'accepted'].includes(shift.status) && shift.current_status === 'Completed') {
        totals.statuses.completed += 1;
      } else {
        totals.statuses[shift.status] += 1;
      }
      shiftsMapByDay.shifts_ids.push(shift.id);
    });

    data[DateTime.fromISO(date).startOf('day').toString()] = shiftsMapByDay;
  });
  return [data, totals];
}

function janeArrToJaneMap(arr: IPublishedShiftResponse[], _type: ShiftCategory) {
  const data: {
    [key: string]: IDynamicMappedShiftResponse;
  } = {};

  const totals: ITotals = {
    statuses: {
      open: 0,
      pending: 0,
      assigned: 0,
      in_progress: 0,
      accepted: 0,
      completed: 0,
      unfulfilled: 0,
      cancelled: 0,
      swapped: 0,
    },
    sent_to_agencies: 0,
  };
  arr.forEach(({ jane_id, day_schedules }) => {
    let numShifts = 0;
    data[jane_id] = {
      numShifts: 0,
    };
    day_schedules.forEach(({ date, shifts }) => {
      const shiftsMapByDay: IMappedShiftResponse = { shifts: {}, shifts_ids: [] };
      shifts.forEach((shift) => {
        shiftsMapByDay.shifts[shift.id] = { ...shift, _type };
        if (shift.sent_to_agencies) totals.sent_to_agencies += 1;

        if (shift.status === 'assigned' && shift.current_status === 'In Progress') {
          totals.statuses.in_progress += 1;
        } else if (shift.status === 'pending' && shift.current_status === 'Unfulfilled') {
          totals.statuses.unfulfilled += 1;
        }
        if (
          ['assigned', 'accepted'].includes(shift.status) &&
          shift.current_status === 'Completed'
        ) {
          totals.statuses.completed += 1;
        } else {
          totals.statuses[shift.status] += 1;
        }
        shiftsMapByDay.shifts_ids.push(shift.id);
        numShifts += 1;
        data[jane_id].numShifts = numShifts;
      });

      data[jane_id][DateTime.fromISO(date).startOf('day').toString()] = shiftsMapByDay;
    });
  });
  return [data, totals];
}

const GET_publishedShiftsMapper = (
  apiResponseData = {
    published_shifts: {
      agency_member_shifts: [],
      internal_team_member_shifts: [],
      shared_team_member_shifts: [],
      unassigned_shifts: [],
    },
  },
) => {
  const {
    agency_member_shifts,
    internal_team_member_shifts,
    shared_team_member_shifts,
    unassigned_shifts,
  } = apiResponseData.published_shifts;

  const [shared_team_member_data, shared_team_member_totals] = dayArrToDayMap(
    shared_team_member_shifts,
    TYPE_SHARED_TEAM_MEMBER,
  );
  const [internal_team_member_data, internal_team_member_totals] = janeArrToJaneMap(
    internal_team_member_shifts,
    TYPE_INTERNAL_TEAM_MEMBER,
  );
  const [unassigned_shifts_data, unassigned_shifts_totals] = dayArrToDayMap(
    unassigned_shifts,
    TYPE_PUBLISHED_UNASSIGNED,
  );
  const [agency_member_data, agency_member_totals] = dayArrToDayMap(
    agency_member_shifts,
    TYPE_AGENCY_MEMBER,
  );

  const shared_team_members_totals_implicit_cast = shared_team_member_totals as ITotals;
  const internal_team_member_totals_implicit_cast = internal_team_member_totals as ITotals;
  const unassigned_shifts_totals_implicit_cast = unassigned_shifts_totals as ITotals;
  const agency_member_totals_implicit_cast = agency_member_totals as ITotals;

  const data = {
    shared_team_member: shared_team_member_data,
    internal_team_member: internal_team_member_data,
    unassigned_shifts: unassigned_shifts_data,
    agency_member: agency_member_data,
    totals: {
      statuses: {
        open:
          shared_team_members_totals_implicit_cast.statuses.open +
          internal_team_member_totals_implicit_cast.statuses.open +
          unassigned_shifts_totals_implicit_cast.statuses.open +
          agency_member_totals_implicit_cast.statuses.open,

        pending:
          shared_team_members_totals_implicit_cast.statuses.pending +
          internal_team_member_totals_implicit_cast.statuses.pending +
          unassigned_shifts_totals_implicit_cast.statuses.pending +
          agency_member_totals_implicit_cast.statuses.pending,
        assigned:
          shared_team_members_totals_implicit_cast.statuses.assigned +
          internal_team_member_totals_implicit_cast.statuses.assigned +
          unassigned_shifts_totals_implicit_cast.statuses.assigned +
          agency_member_totals_implicit_cast.statuses.assigned,
        in_progress:
          shared_team_members_totals_implicit_cast.statuses.in_progress +
          internal_team_member_totals_implicit_cast.statuses.in_progress +
          unassigned_shifts_totals_implicit_cast.statuses.in_progress +
          agency_member_totals_implicit_cast.statuses.in_progress,
        accepted:
          shared_team_members_totals_implicit_cast.statuses.accepted +
          internal_team_member_totals_implicit_cast.statuses.accepted +
          unassigned_shifts_totals_implicit_cast.statuses.accepted +
          agency_member_totals_implicit_cast.statuses.accepted,
        completed:
          shared_team_members_totals_implicit_cast.statuses.completed +
          internal_team_member_totals_implicit_cast.statuses.completed +
          unassigned_shifts_totals_implicit_cast.statuses.completed +
          agency_member_totals_implicit_cast.statuses.completed,
        unfulfilled:
          shared_team_members_totals_implicit_cast.statuses.unfulfilled +
          internal_team_member_totals_implicit_cast.statuses.unfulfilled +
          unassigned_shifts_totals_implicit_cast.statuses.unfulfilled +
          agency_member_totals_implicit_cast.statuses.unfulfilled,
        cancelled:
          shared_team_members_totals_implicit_cast.statuses.cancelled +
          internal_team_member_totals_implicit_cast.statuses.cancelled +
          unassigned_shifts_totals_implicit_cast.statuses.cancelled +
          agency_member_totals_implicit_cast.statuses.cancelled,
        swapped:
          shared_team_members_totals_implicit_cast.statuses.swapped +
          internal_team_member_totals_implicit_cast.statuses.swapped +
          unassigned_shifts_totals_implicit_cast.statuses.swapped +
          agency_member_totals_implicit_cast.statuses.swapped,
      },
      sent_to_agencies:
        shared_team_members_totals_implicit_cast.sent_to_agencies +
        internal_team_member_totals_implicit_cast.sent_to_agencies +
        unassigned_shifts_totals_implicit_cast.sent_to_agencies +
        agency_member_totals_implicit_cast.sent_to_agencies,
    },
  };
  return data;
};

export default GET_publishedShiftsMapper;
