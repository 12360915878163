import { LocationOptionType } from 'components/LocationFilterInput/LocationFilterInput.types';
import { useGQLQuery } from 'hooks';
import { FC, useContext, useEffect, useMemo } from 'react';
import { GQLAPIContextType } from 'types/common.types';
import { locationDropdownData } from 'types/graphql-types';
import { useLocationFilterQueryConfig } from 'providers/AuthRouteDataProvider/AuthRouteDataProvider.constants';
import { createGQLDataContext } from 'utils/createDataContext';

export const LocationByIdDataContext = createGQLDataContext<{
  location: LocationOptionType;
}>() as GQLAPIContextType;

export const LocationByIdDataProvider: FC<{ locationId: string }> = ({ locationId, children }) => {
  const { fetch, data } = useGQLQuery<locationDropdownData, LocationOptionType[], undefined>(
    useLocationFilterQueryConfig(),
  );

  useEffect(() => fetch(), [fetch]);

  const location = data?.find((location) => location.id === `${locationId}`);

  const contextValue = useMemo(() => ({ location }), [location]);

  return (
    <LocationByIdDataContext.Provider value={contextValue}>
      {children}
    </LocationByIdDataContext.Provider>
  );
};

export const useLocationByIdDataContext = (): GQLAPIContextType<{ location: LocationOptionType }> =>
  useContext(LocationByIdDataContext);
