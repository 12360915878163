import { createSelector } from '@reduxjs/toolkit';
import { IGlobalReduxStore } from 'store';

const getUserPermissions = (state: IGlobalReduxStore) => state.auth.permissions;

export const isMultiLocationFeatureEnabledSelector = (communityId: number) =>
  createSelector(getUserPermissions, (userPermissions) => {
    if (userPermissions?.MULTI_LOCATION?.access)
      return (
        userPermissions.MULTI_LOCATION.access.includes('read') &&
        !!userPermissions.MULTI_LOCATION.communities &&
        !!userPermissions.MULTI_LOCATION.communities[communityId] &&
        !!userPermissions.MULTI_LOCATION.communities[communityId].includes('read')
      );
    else return false;
  });
