import {
  BKJButton,
  BKJIconTooltip,
  BKJLoadingSpinner,
  BKJPagination,
} from '@bookjane2/bookjane-design-library';
import {
  GroupsTableRowWrapper,
  GroupsTableFooter,
  GroupsTableFooterPaginationWrapper,
  GroupsTableWrapper,
} from 'pages/GroupsPage/GroupsPage.styled';
import { BKJStickyTableHead, BKJTableBody } from 'components/BKJTable/BKJTable.styled';
import { BKJTableData } from 'components/BKJTable/BKJTableRow/BKJTableRow.styled';
import { BKJTableSubHeader } from 'components/BKJTable/BKJTableSubHeader';
import { Flex } from 'components/Flex';
import {
  groupsModalRoutingService,
  groupsPageTableDataModalControlState,
} from 'components/GroupsModal/GroupsModal.ui.context';
import {
  GroupsPageDataProvider,
  useGroupsPageDataContext,
} from 'pages/GroupsPage/GroupsPage.data.context';
import { GroupsPageEmptyView } from 'pages/GroupsPage/GroupsPageEmptyView';
import { UnderlineButton } from 'pages/ShiftDistributionPage/ShiftDistributionPage.styled';
import { FC } from 'react';
import { fetchGroups_fetchCommunity_groups_nodes } from 'types/graphql-types';
import { css } from 'styled-components';
import { BKJTableSubHeaderWrapper } from 'components/BKJTable/BKJTableSubHeader/BKJTableSubHeader.styled';
import { useTranslation } from 'hooks/useTranslation';

const GroupsRow: FC<fetchGroups_fetchCommunity_groups_nodes & { isGrey?: boolean }> = ({
  __typename,
  id,
  name,
  isGrey,
}) => {
  const { t } = useTranslation();
  return (
    <GroupsTableRowWrapper isGrey={isGrey} key={`${id}-${name}`}>
      <BKJTableData>{name}</BKJTableData>
      <BKJTableData>
        {__typename === 'StaticGroup' ? t('groups:STATIC') : t('groups:DYNAMIC')}
      </BKJTableData>
      <BKJTableData>
        <Flex flexDirection="row" flexGrow={1} width="100%">
          <Flex flexBasis="50%">
            <UnderlineButton
              onClick={(e) => {
                e.preventDefault();
                groupsModalRoutingService.open(
                  { groupId: id },
                  __typename === 'StaticGroup' ? `ViewStaticGroup` : `ViewDynamicGroup`,
                );
              }}
            >
              {t('groups:VIEW')}
            </UnderlineButton>
          </Flex>
          <Flex flexBasis="50%">
            <UnderlineButton
              onClick={(e) => {
                e.preventDefault();
                groupsModalRoutingService.open({ groupId: id }, 'EditGroup');
              }}
            >
              {t('groups:EDIT')}
            </UnderlineButton>
          </Flex>
        </Flex>
      </BKJTableData>
    </GroupsTableRowWrapper>
  );
};

const GroupsPageComponent: FC = () => {
  const { prevPage, nextPage, firstPage, lastPage, currentPage, data, isLoading } =
    useGroupsPageDataContext();

  const { t } = useTranslation();
  const groupsNodes = data?.fetchCommunity?.groups.nodes;
  const groupsPageCount = data?.fetchCommunity?.groups.pageCount;

  // if modal control state is set to refresh table data, update state and reset table pagination
  if (groupsPageTableDataModalControlState.refresh) {
    groupsPageTableDataModalControlState.refresh = false;
    firstPage();
  }

  // Display empty view if we don't have any groups
  if (!groupsNodes || (groupsNodes && groupsNodes.length < 1)) {
    if (isLoading) return <BKJLoadingSpinner variant="Default" />;
    else return <GroupsPageEmptyView />;
  }
  return (
    <GroupsTableWrapper>
      <BKJTableBody gridTemplateColumns="50% 20% 30%">
        <BKJStickyTableHead>
          <BKJTableSubHeader title={t('groups:GROUP_NAME')} name="groupName" direction="default" />
        </BKJStickyTableHead>
        <BKJStickyTableHead
          css={css`
            overflow: visible;
          `}
        >
          <BKJTableSubHeaderWrapper
            css={css`
              gap: 5px;
            `}
          >
            {t('groups:GROUP_TYPE')}
            <BKJIconTooltip
              iconName="Info"
              placement="top"
              css={css`
                margin-right: auto;
              `}
            >
              <Flex width="258px">
                {t('groups:GROUP_TYPE_TOOLTIP_1')}
                <br />
                <br />
                {t('groups:GROUP_TYPE_TOOLTIP_2')}
              </Flex>
            </BKJIconTooltip>
            <Flex width="100%" />
          </BKJTableSubHeaderWrapper>
        </BKJStickyTableHead>
        <BKJStickyTableHead>
          <BKJTableSubHeader title="Action" name="action" direction="default" />
        </BKJStickyTableHead>
        {groupsNodes.map((group, i) => (
          <GroupsRow key={`${i}-${group.name}`} isGrey={i % 2 === 1} {...group} />
        ))}
      </BKJTableBody>
      <GroupsTableFooter flexDirection="column" justifyContent="flex-end" flexGrow={1}>
        <Flex flexDirection="row" flexGrow={1} justifyContent="space-between" width="100%">
          <GroupsTableFooterPaginationWrapper>
            <BKJPagination
              currentPage={currentPage}
              pageCount={groupsPageCount}
              onFirstPage={firstPage}
              onPrevPage={prevPage}
              onNextPage={nextPage}
              onLastPage={lastPage}
            />
          </GroupsTableFooterPaginationWrapper>
          <BKJButton
            variant="PurpleSolid"
            width="180px"
            onClick={(e) => {
              e.preventDefault();
              groupsModalRoutingService.open({}, 'NewGroup');
            }}
            css={css`
              position: fixed;
              bottom: 10px;
              right: 40px;
            `}
          >
            {t('groups:NEW_GROUP')}
          </BKJButton>
        </Flex>
      </GroupsTableFooter>
    </GroupsTableWrapper>
  );
};

export const GroupsPage: FC = () => {
  return (
    <GroupsPageDataProvider>
      <GroupsPageComponent />
    </GroupsPageDataProvider>
  );
};
