import { BKJCheckboxListItem, BKJIcon, BKJImage } from '@bookjane2/bookjane-design-library';
import { ChangeFunctionType } from '@bookjane2/bookjane-design-library/lib/common.types';
import { BKJRadioInput } from 'components/BKJRadioInput/BKJRadioInput';
import { Flex } from 'components/Flex';
import {
  JaneGridWrapper,
  BKJImageWrapper,
  JaneGridCard,
  JaneGridErrorText,
  JaneGridJaneName,
  JaneGridAgencyName,
  JaneGridSelectButton,
} from 'components/AwardJaneGridSelect/AwardJaneGridSelect.styled';
import {
  IJaneGridCardComponentProps,
  IJaneGridSelectProps,
  formatString,
} from 'components/AwardJaneGridSelect/AwardJaneGridSelect.types';

import { FC, useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { InfiniteScrollLoading } from 'components/InfiniteScrollLoading';
import { ChangeEventType } from '@bookjane2/bookjane-design-library/lib/common.types';
import { fetchOrderBidders_fetchOrder_bidders } from 'types/graphql-types';
import { useResponsive } from 'providers/ResponsiveProvider/Responsive';

const WebProfilePicLoadingFallbackComponent = () => <BKJIcon iconName="AvatarFill" />;
const MobileProfilePicLoadingFallbackComponent = () => <BKJIcon iconName="MobileAvatarFill" />;

const AwardJaneGridCardComponent: FC<IJaneGridCardComponentProps> = ({
  type = 'Single',
  max = 1,
  name,
  label,
  index,
  getData,
  id,
  value,
  onChange: _onChange,
}) => {
  const { t } = useTranslation();
  const { isMobile } = useResponsive();
  const ProfilePicLoadingFallbackComponent = isMobile
    ? MobileProfilePicLoadingFallbackComponent
    : WebProfilePicLoadingFallbackComponent;
  const data = useMemo(
    () => getData(index),
    [getData, index],
  ) as fetchOrderBidders_fetchOrder_bidders;

  const onChange: ChangeFunctionType = useCallback(
    (e: ChangeEventType) => {
      _onChange({
        target: {
          name: e.target.name,
          value: e.target.value,
        },
      });
    },
    [_onChange],
  );

  const disabled = !value?.includes(id) && value?.length === max ? true : false;

  return (
    <JaneGridCard tabIndex={0}>
      <BKJImageWrapper height="96px" width="100%">
        <BKJImage
          height="100%"
          width="100%"
          alt="Avatar"
          src={data?.avatarUrls?.origin || ''}
          variant="Rectangle"
          LoadingComponent={ProfilePicLoadingFallbackComponent}
          FallbackComponent={ProfilePicLoadingFallbackComponent}
        />
      </BKJImageWrapper>

      <Flex flexDirection="column" padding="8px" flex="1" width="100%">
        <JaneGridJaneName>
          {data?.firstName} {data?.lastName}
        </JaneGridJaneName>
        <JaneGridAgencyName>
          {t('create_shifts:RANKING')}: {data?.orderRank}
        </JaneGridAgencyName>
        <JaneGridAgencyName>{formatString(data?.employmentStatus?.name)}</JaneGridAgencyName>
        <JaneGridAgencyName>{data?.primaryPosition?.details?.name}</JaneGridAgencyName>
      </Flex>

      <JaneGridSelectButton
        alignItems="center"
        height="44px"
        minHeight="44px"
        maxHeight="44px"
        width="100%"
        minWidth="100%"
        padding="0 10px"
      >
        {type === 'Single' && (
          <BKJRadioInput
            {...{
              name,
              value: id,
              checked: value.includes(id),
              onChange,
              label,
              width: '100%',
            }}
          />
        )}
        {type === 'Multiple' && (
          <BKJCheckboxListItem
            {...{
              label,
              selected: value,
              value: id,
              name,
              disabled,
              onChange,
            }}
          />
        )}
      </JaneGridSelectButton>
    </JaneGridCard>
  );
};
export const AwardJaneGridSelect: FC<IJaneGridSelectProps> = ({
  loadMore,
  paginationType = 'Normal',
  type = 'Single',
  label = 'create_shifts:SELECT',
  max = 1,
  context,
  name,
  value,
  onChange,
  errorText = 'create_shifts:THERE_ARE_NO_TEAM_MEMBERS_AVAILABLE',
}) => {
  const { t } = useTranslation();
  const { data } = useContext(context);

  const janes = useMemo(() => data?.fetchOrder?.bidders || [], [data?.fetchOrder?.bidders]);
  const pageInfo = data?.fetchOrder?.pageInfo || {};

  const getData = useCallback(
    (index) => {
      return { ...janes[index] };
    },
    [janes],
  );

  if (janes?.length === 0)
    return (
      <Flex
        width="100%"
        flex="1"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <JaneGridErrorText>{t(errorText)}</JaneGridErrorText>
      </Flex>
    );

  return (
    <JaneGridWrapper flexWrap="wrap" width="100%">
      {janes?.map((jane: { id: string }, index: number) => {
        return (
          <AwardJaneGridCardComponent
            max={max}
            type={type}
            getData={getData}
            id={jane?.id}
            index={index}
            label={t(label)}
            key={jane?.id}
            name={name}
            value={value}
            onChange={onChange}
          />
        );
      })}
      {paginationType === 'InfiniteScroll' && loadMore && !!pageInfo.hasNextPage ? (
        <Flex justifyContent="center" alignItems="center" width="100%" padding="24px 0px 0px 0px">
          <InfiniteScrollLoading onViewportEnter={loadMore!} />
        </Flex>
      ) : null}
    </JaneGridWrapper>
  );
};
