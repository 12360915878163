import { BKJButton } from '@bookjane2/bookjane-design-library';
import { AutoMargin } from 'components/AutoMargin';
import { BKJSideModalFooter } from 'components/BKJSideModalFooter';
import { Flex } from 'components/Flex';
import {
  LocationHeader,
  ScheduleRulesDescription,
} from 'components/ScheduleSettingsModal/views/ScheduleRules.styled';
import { SectionDivider } from 'components/ShiftModal/ShiftModal.styled';
import { H3 } from 'components/Typography';
import { FC, Fragment, useState } from 'react';
import { css } from 'styled-components';
import {
  OrderColumn,
  PositionRuleTableRowCell,
  PositionRuleTableRowCellContent,
  SendToGroupColumn,
  SendToGroupsDropdownLabel,
  SendToGroupsLabel,
  SendToGroupsSection,
  SendToGroupsTableLabel,
  ShiftThresholdHeader,
} from 'components/ShiftDistributionRulesGroupsModal/views/AddPositionRule.styled';
import { useURLParameters } from 'hooks';
import {
  ViewDetailsDataProvider,
  useViewDetailsDataContext,
} from 'components/ShiftDistributionRulesGroupsModal/views/ViewDetails.data.context';
import {
  DefaultRuleLabel,
  LocationName,
  ModalContentWrapper,
} from 'components/ShiftDistributionRulesGroupsModal/views/ViewDetails.styled';
import { formatNumberWithOrdinalSuffix } from 'utils';
import {
  ViewDetailsActionsProvider,
  useViewDetailsActionsContext,
} from 'components/ShiftDistributionRulesGroupsModal/views/ViewDetails.actions.context';
import { useLocationByIdDataContext } from 'components/ShiftDistributionRulesGroupsModal/LocationById.data.context';
import { useTranslation } from 'hooks/useTranslation';
import { useShiftDistributionRulesGroupsModalUIContext } from 'components/ShiftDistributionRulesGroupsModal/ShiftDistributionRulesGroupsModal.ui.context';
import { BKJCenteredDialog } from 'components/BKJCenteredDialog';
import { getWaitTimeLabel } from 'components/ShiftDistributionRulesGroupsModal/ShiftDistributionRulesGroupsModal.constants';

export const ViewDetailsView: FC = () => {
  const { t } = useTranslation();
  const { goToEditRuleView } = useShiftDistributionRulesGroupsModalUIContext();
  const { onDelete } = useViewDetailsActionsContext();
  const { data, isLoading } = useViewDetailsDataContext();

  const { location } = useLocationByIdDataContext();

  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false);

  if (!data || isLoading) {
    return <Fragment />;
  }

  return (
    <ModalContentWrapper>
      <Flex flexDirection="column" padding="32px 40px 0">
        <H3>{t('schedule:CONFIGURE_RULES_POSITIONS')}</H3>
        <ScheduleRulesDescription>
          {t('schedule:CONFIGURE_RULES_POSITIONS_DESCRIPTION')}
        </ScheduleRulesDescription>
        <LocationHeader>{t('common:LOCATION')}</LocationHeader>
        <LocationName>{location?.label}</LocationName>
        <LocationHeader
          css={css`
            padding-bottom: 5px;
          `}
        >
          {t('common:POSITION')}
        </LocationHeader>
        {!!data?.position?.name ? (
          <LocationName>{data?.position?.name}</LocationName>
        ) : (
          <LocationName>
            {t('common:DEFAULT')} &nbsp;
            <DefaultRuleLabel>({t('schedule:SCHEDULING_RULES_INFORMATION')})</DefaultRuleLabel>
          </LocationName>
        )}
      </Flex>
      <Flex flexDirection="column" width="100%" flexGrow={1}>
        <SectionDivider margin="0 0 24px 0" variant="Thin" />

        <SendToGroupsSection>
          <OrderColumn>
            <SendToGroupsLabel>{t('location_settings:ORDER')}</SendToGroupsLabel>
          </OrderColumn>
          <SendToGroupColumn>
            <SendToGroupsLabel>{t('location_settings:SEND_TO_GROUP')}</SendToGroupsLabel>
          </SendToGroupColumn>
        </SendToGroupsSection>
        {data?.groupRules?.map(({ rank, group, waitTimeMinutes }, i) => (
          <Fragment key={i}>
            {i !== 0 && (
              <PositionRuleTableRowCell isGrey={false}>
                <PositionRuleTableRowCellContent>
                  <OrderColumn>
                    <SendToGroupsTableLabel>
                      {`${t('location_settings:WAIT')} *`}
                    </SendToGroupsTableLabel>
                  </OrderColumn>
                  <SendToGroupColumn>
                    <SendToGroupsDropdownLabel>
                      {getWaitTimeLabel(waitTimeMinutes)}
                    </SendToGroupsDropdownLabel>
                  </SendToGroupColumn>
                </PositionRuleTableRowCellContent>
              </PositionRuleTableRowCell>
            )}
            <PositionRuleTableRowCell isGrey={true}>
              <PositionRuleTableRowCellContent>
                <OrderColumn>
                  <SendToGroupsTableLabel>{`${formatNumberWithOrdinalSuffix(
                    rank,
                  )}`}</SendToGroupsTableLabel>
                </OrderColumn>
                <SendToGroupColumn>
                  <SendToGroupsDropdownLabel>{group.name}</SendToGroupsDropdownLabel>
                </SendToGroupColumn>
              </PositionRuleTableRowCellContent>
            </PositionRuleTableRowCell>
          </Fragment>
        ))}
        {(!data?.groupRules || data?.groupRules.length <= 0) && (
          <PositionRuleTableRowCell isGrey={true}>
            <PositionRuleTableRowCellContent>
              <OrderColumn>
                <SendToGroupsTableLabel>{formatNumberWithOrdinalSuffix(1)}</SendToGroupsTableLabel>
              </OrderColumn>
              <SendToGroupColumn
                css={css`
                  padding-bottom: 8px;
                `}
              >
                {t('location_settings:ALL_TEAM_MEMBERS')}
              </SendToGroupColumn>
            </PositionRuleTableRowCellContent>
          </PositionRuleTableRowCell>
        )}
      </Flex>
      <Flex flexDirection="column" padding="0 22px 0">
        <ShiftThresholdHeader
          css={css`
            padding-top: 30px;
          `}
        >
          {'* '}
          {t('location_settings:WAIT_TIMES_WITHIN', {
            value:
              data?.skipWaitTimeThresholdMinutes === 0
                ? '<6'
                : !data?.skipWaitTimeThresholdMinutes
                ? `24 ${t('location_settings:HOURS')}`
                : `${Math.floor(data.skipWaitTimeThresholdMinutes / 60)} ${t(
                    'location_settings:HOURS',
                  )}`,
          })}
        </ShiftThresholdHeader>
      </Flex>
      <BKJCenteredDialog
        isOpen={isDeleteConfirmationOpen}
        variant="Danger"
        title={t('location_settings:DELETE_RULES_FOR_POSITION')}
        data-cy="delete-rules-modal"
        text={t('location_settings:DELETE_RULES_FOR_POSITION_DESCRIPTION')}
        cancelLabel={t('auth:CANCEL')}
        actionLabel={t('auth:DELETE')}
        onAction={() => {
          setIsDeleteConfirmationOpen(false);
          return onDelete({ shiftDistributionRuleId: `${data.id}` });
        }}
        onCancel={() => setIsDeleteConfirmationOpen(false)}
        onClose={() => setIsDeleteConfirmationOpen(false)}
      />
      <Flex height="100%" />
      <BKJSideModalFooter justifyContent="space-between" flexDirection="row">
        {!!data?.position?.id && (
          <BKJButton
            variant="RedText"
            width="180px"
            onClick={() => setIsDeleteConfirmationOpen(true)}
            css={css`
              margin-right: 5px;
            `}
            type="button"
            disabled={false}
          >
            {t('auth:DELETE')}
          </BKJButton>
        )}
        <AutoMargin />

        <BKJButton
          variant="PurpleOutlined"
          width="180px"
          onClick={() => {
            goToEditRuleView(
              !!data?.position?.id ? { positionSettingsId: `${data.position.id}` } : {},
            );
          }}
        >
          {t('location_settings:EDIT')}
        </BKJButton>
      </BKJSideModalFooter>
    </ModalContentWrapper>
  );
};

export const ViewDetails: FC = () => {
  const [{ positionSettingsId }] = useURLParameters<{ positionSettingsId?: string }>();
  return (
    <ViewDetailsDataProvider positionSettingsId={positionSettingsId}>
      <ViewDetailsActionsProvider>
        <ViewDetailsView />
      </ViewDetailsActionsProvider>
    </ViewDetailsDataProvider>
  );
};
