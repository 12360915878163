import { Flex } from 'components/Flex';
import { useShiftModalDataContext } from 'components/ShiftModal/ShiftModalProvider.data.context';
import { formatCurrencyCAD } from 'format/formatCurrencyCAD';
import { FC, Fragment, useMemo } from 'react';
import { useTranslation } from 'hooks/useTranslation';
import { formatInterval, calculateShiftDurationInMinutes } from 'utils/time';
import { LabelText, ValueText, TimeSheetsStartEndTimeContainer } from '../DetailView.styled';
import { formatUnpaidBreakTime } from './TimesheetsTab.utils';
import { useTimeSheetsTabDataContext } from './TimesheetsTabData.context';
import { useTimesheetsTabFormContext } from './TimesheetsTabForm.context';
import { BKJComboBox } from '@bookjane2/bookjane-design-library';
import { IBKJComboBoxOption } from '@bookjane2/bookjane-design-library/lib/components/BKJComboBox/BKJComboBox.types';
import { DateTime } from 'luxon';
import { FormatCurrency } from 'components/FormatCurrency';

const marginLeft = 16;

const shiftTimeDropdownHrs: IBKJComboBoxOption[] = Array.from({ length: 24 }, (_, i) => {
  const time = DateTime.now().startOf('day').plus({ hour: i });
  return {
    label: time.toFormat('h a'),
    value: time.toFormat('HH'),
    key: time.toFormat('HH'),
    id: time.toFormat('HH'),
  };
});

const shiftTimeDropdownMins: IBKJComboBoxOption[] = Array.from({ length: 60 }, (_, i) => {
  const min = i.toString().padStart(2, '0');
  return {
    label: min,
    value: min,
    key: min,
    id: min,
  };
});

const breakTimeDropdownHrs: IBKJComboBoxOption[] = Array.from({ length: 5 }, (_, i) => {
  const hr = i.toString().padStart(2, '0');
  return {
    label: `${i} hr${i === 1 ? '' : 's'}`,
    value: hr,
    key: hr,
    id: hr,
  };
});

const breakTimeDropdownMins: IBKJComboBoxOption[] = Array.from({ length: 60 }, (_, i) => {
  const min = i.toString().padStart(2, '0');
  return {
    label: `${min} min${i === 1 ? '' : 's'}`,
    value: min,
    key: min,
    id: min,
  };
});

export const TimesheetsTabPendingView: FC = () => {
  const { t } = useTranslation();
  const { onChange, values } = useTimesheetsTabFormContext();
  const { data } = useTimeSheetsTabDataContext();
  const { data: fetchOrderData } = useShiftModalDataContext();
  const totalPaidDurationInMinutes = useMemo(
    () =>
      calculateShiftDurationInMinutes({
        startDate: fetchOrderData.fetchOrder!.startDate,
        startAt: [`${values.startTimeHrs[0]}:${values.startTimeMins[0]}`],
        endAt: [`${values.endTimeHrs[0]}:${values.endTimeMins[0]}`],
        unpaidBreakTime: [`${values.unpaidBreakHrs[0]}:${values.unpaidBreakMins[0]}`],
        isPaidDuration: true,
      }),
    [
      fetchOrderData.fetchOrder,
      values.endTimeHrs,
      values.endTimeMins,
      values.startTimeHrs,
      values.startTimeMins,
      values.unpaidBreakHrs,
      values.unpaidBreakMins,
    ],
  );

  const totalDurationInMinutes = useMemo(
    () =>
      calculateShiftDurationInMinutes({
        startDate: fetchOrderData.fetchOrder!.startDate,
        startAt: [`${values.startTimeHrs[0]}:${values.startTimeMins[0]}`],
        endAt: [`${values.endTimeHrs[0]}:${values.endTimeMins[0]}`],
        isPaidDuration: false,
      }),
    [
      fetchOrderData.fetchOrder,
      values.endTimeHrs,
      values.endTimeMins,
      values.startTimeHrs,
      values.startTimeMins,
    ],
  );
  const rawAmount =
    (totalPaidDurationInMinutes / 60) *
    (data?.fetchTimeSheet?.timeSheet?.acceptedHourlyRate?.rawCurrency || 0);

  return (
    <Flex flexDirection="column" childMarginBottom="32px">
      <TimeSheetsStartEndTimeContainer childMarginRight="5px">
        <Flex flexDirection="column" width="250px">
          {data.fetchTimeSheet.timeSheet?.status.value === 'pending' ? (
            <Fragment>
              <Flex padding="0 0 10px 0">{t('shift_modal:START_TIME')}</Flex>
              <Flex flexDirection="row" gap="5px">
                <Flex width="210px">
                  <BKJComboBox
                    placeholder={t('shift_modal:HR_PLACEHOLDER')}
                    name="startTimeHrs"
                    value={values.startTimeHrs}
                    onChange={onChange}
                    options={shiftTimeDropdownHrs}
                    type="Single"
                    action="OpenToggle"
                    filter="TimeStartsWith"
                    $dropdownHeight="240px"
                  />
                </Flex>
                <BKJComboBox
                  placeholder={t('shift_modal:MIN_PLACEHOLDER')}
                  name="startTimeMins"
                  value={values.startTimeMins}
                  onChange={onChange}
                  options={shiftTimeDropdownMins}
                  type="Single"
                  action="OpenToggle"
                  filter="TimeStartsWith"
                  $dropdownHeight="240px"
                />
              </Flex>
            </Fragment>
          ) : (
            <ValueText marginLeft={marginLeft}>
              {DateTime.fromISO(data.fetchTimeSheet.startTime).toFormat('h:mm a')}
            </ValueText>
          )}
        </Flex>
        <Flex flexDirection="column" width="245px">
          {data.fetchTimeSheet.timeSheet?.status.value === 'pending' ? (
            <Fragment>
              <Flex padding="0 0 10px 0">{t('shift_modal:END_TIME')}</Flex>
              <Flex flexDirection="row" gap="5px">
                <Flex width="220px">
                  <BKJComboBox
                    placeholder={t('shift_modal:HR_PLACEHOLDER')}
                    name="endTimeHrs"
                    value={values.endTimeHrs}
                    onChange={onChange}
                    options={shiftTimeDropdownHrs}
                    type="Single"
                    action="OpenToggle"
                    filter="TimeStartsWith"
                    $dropdownHeight="240px"
                  />
                </Flex>
                <BKJComboBox
                  placeholder={t('shift_modal:MIN_PLACEHOLDER')}
                  name="endTimeMins"
                  value={values.endTimeMins}
                  onChange={onChange}
                  options={shiftTimeDropdownMins}
                  type="Single"
                  action="OpenToggle"
                  filter="TimeStartsWith"
                  $dropdownHeight="240px"
                />
              </Flex>
            </Fragment>
          ) : (
            <ValueText marginLeft={marginLeft}>
              {DateTime.fromISO(data.fetchTimeSheet.endTime).toFormat('h:mm a')}
            </ValueText>
          )}
        </Flex>
      </TimeSheetsStartEndTimeContainer>
      <Flex flexDirection="column" width="250px">
        {data.fetchTimeSheet.timeSheet?.status.value === 'pending' ? (
          <Fragment>
            <Flex padding="0 0 10px 0">{t('shift_modal:UNPAID_BREAK')}</Flex>
            <Flex flexDirection="row" gap="5px">
              <Flex width="210px">
                <BKJComboBox
                  placeholder={t('shift_modal:HR_PLACEHOLDER')}
                  name="unpaidBreakHrs"
                  value={values.unpaidBreakHrs}
                  onChange={onChange}
                  options={breakTimeDropdownHrs}
                  type="Single"
                  action="OpenToggle"
                  filter="TimeStartsWith"
                />
              </Flex>
              <BKJComboBox
                placeholder={t('shift_modal:MIN_PLACEHOLDER')}
                name="unpaidBreakMins"
                value={values.unpaidBreakMins}
                onChange={onChange}
                options={breakTimeDropdownMins}
                type="Single"
                action="OpenToggle"
                filter="TimeStartsWith"
                $dropdownHeight="240px"
              />
            </Flex>
          </Fragment>
        ) : (
          <Fragment>
            <LabelText>{t('shift_modal:UNPAID_BREAK')}</LabelText>
            <ValueText marginLeft={marginLeft}>
              {formatUnpaidBreakTime(data?.fetchTimeSheet?.timeSheet?.unpaidBreakTime as string)}
            </ValueText>
          </Fragment>
        )}
      </Flex>
      <Flex>
        <Flex flexDirection="column" width="240px">
          <LabelText>{t('shift_modal:TOTAL_DURATION')}</LabelText>
          <ValueText marginLeft={marginLeft}>{formatInterval(totalDurationInMinutes)}</ValueText>
        </Flex>
        <Flex flexDirection="column" width="240px">
          <LabelText>{t('shift_modal:TOTAL_PAID_DURATION')}</LabelText>
          <ValueText marginLeft={marginLeft}>
            {data.fetchTimeSheet.timeSheet?.approvedTotalTime?.time}
          </ValueText>
        </Flex>
      </Flex>
      <Flex>
        <Flex flexDirection="column" width="240px">
          <LabelText>{t('shift_modal:HOURLY_PAY_RATE')}</LabelText>
          <ValueText marginLeft={marginLeft}>
            {formatCurrencyCAD(
              data?.fetchTimeSheet?.timeSheet?.acceptedHourlyRate?.rawCurrency || 0,
            )}
          </ValueText>
        </Flex>
        <Flex flexDirection="column" width="240px">
          <LabelText>{t('shift_modal:TOTAL_PAY')}</LabelText>
          <ValueText marginLeft={marginLeft}>
            <FormatCurrency amount={`${rawAmount}`} />
          </ValueText>
        </Flex>
      </Flex>
    </Flex>
  );
};
