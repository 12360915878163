import { ChangeFunctionType } from '@bookjane2/bookjane-design-library/lib/common.types';
import { ILocationFilterProps } from 'components/LocationFilterInput/LocationFilterInput.types';
import { SearchTeamMembersGroupsComponent } from 'components/GroupsModal/components/SearchTeamMembersGroups/SearchTeamMembersGroups';
import { FC } from 'react';
import { withMemo } from 'utils/withMemo';
import { SearchTeamMembersGroupsProvider } from 'components/GroupsModal/components/SearchTeamMembersGroups/SearchTeamMembersGroups.context';

export interface IProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'value'> {
  name: string;
  onChange: ChangeFunctionType;
  value: string;
  location: ILocationFilterProps['value'];
  groupId: number;
  viewAllFacilities: boolean;
}

const _SearchTeamMembersGroupsComponent: FC<IProps> = (props) => {
  return (
    <SearchTeamMembersGroupsProvider location={props.location} groupId={props.groupId}>
      <SearchTeamMembersGroupsComponent {...props} />
    </SearchTeamMembersGroupsProvider>
  );
};

export const SearchTeamMembersGroups: FC<IProps> = withMemo(_SearchTeamMembersGroupsComponent);
