import { FC, Fragment } from 'react';
import { BKJIcon } from '@bookjane2/bookjane-design-library';
import { Flex } from 'components/Flex';
import { ShiftStatusBadge } from 'components/ShiftStatusBadge';
import { JaneAvatar } from 'components/BKJJaneAvatar';
import { Tags } from 'components/Tags';
import { formatJaneNameByStatus } from 'utils/formatJaneNameByStatus';
import { parseVerboseDate } from 'utils';
import { getTranslatedResponse } from 'utils/getTranslatedResponse';
import { useTranslation } from 'hooks/useTranslation';
import {
  ShiftsTableData,
  Text,
  Label,
  DateText,
  PremiumJaneName,
  Recurring,
} from './ShiftsTableRow.styled';
import { BKJTableRowWrapper } from 'components/BKJTable/BKJTableRow/BKJTableRow.styled';
import {
  calculateBreakTime,
  calculateDuration,
  getHoursAndMinutesFromDate,
  formatDateForShiftTableRow,
} from 'pages/ShiftsListPage/ShiftsTableRow/ShiftsTableRow.utils';
import { shiftModalRoutingService } from 'components/ShiftModal/ShiftModal.ui.context';
import { OrderStatusEnum, shiftsListPage_fetchOrders_edges_node } from 'types/graphql-types';
import { getCurrentlySelectedLocation } from 'utils/getCurrentlySelectedLocation';
import { isMultiLocationFeatureEnabledSelector } from 'store/selectors/featureFlagSelectors';
import { useSelector } from 'react-redux';
import { FormatCurrency } from 'components/FormatCurrency';

export const ShiftsB2BTableRow: FC<shiftsListPage_fetchOrders_edges_node> = (props) => {
  const { t, i18n } = useTranslation();
  const {
    id,
    status,
    bidders,
    biddable,
    biddingTimeEndsAt,
    mainJane,
    sentToAgencies,
    dedicated,
    position,
    startAt,
    endAt,
    createdAt,
    recurrence,
    estimatedTotalCharge,
    totalDuration,
    acceptedBySharedTeamMember,
    rate,
    orderSchedule,
  } = props;

  function onShiftModal() {
    shiftModalRoutingService.open({ shiftModalShiftId: id });
  }

  const showRate =
    (!!mainJane?.agencyCommunity && status.value === OrderStatusEnum.completed) ||
    status.value !== OrderStatusEnum.completed;

  const currentlySelectedLocation = getCurrentlySelectedLocation();
  const { timeZone } = currentlySelectedLocation;
  const isMultiLocationFeatureEnabled = useSelector(
    isMultiLocationFeatureEnabledSelector(Number(currentlySelectedLocation?.value)),
  );

  const isSharedJanePrimaryCommunityDifferentFromSelectedLocation =
    isMultiLocationFeatureEnabled &&
    !!mainJane &&
    status.value !== OrderStatusEnum.accepted &&
    !mainJane?.agencyCommunity &&
    mainJane?.primaryCommunity?.id !== currentlySelectedLocation?.value?.toString();

  return (
    <BKJTableRowWrapper key={id} onClick={onShiftModal}>
      <ShiftsTableData>
        <Label>Shift Date</Label>
        <Flex flexDirection="column">
          <Text>{formatDateForShiftTableRow(startAt, timeZone, true, false)}</Text>
          {recurrence && (
            <Recurring>
              <BKJIcon iconName="Recurring" />
              &nbsp;Recurring Shift
            </Recurring>
          )}
        </Flex>
      </ShiftsTableData>
      <ShiftsTableData>
        <Label>Team Member</Label>
        <Flex alignItems="center" justifyContent="space-between" width="100%">
          <Flex alignItems="center">
            <Flex margin="0 13px 0 0">
              <JaneAvatar
                size={30}
                status={status.value}
                url={mainJane?.avatarUrls.small}
                pendingSignup={mainJane?.pendingSignup ?? false}
              />
            </Flex>
            <PremiumJaneName>
              {formatJaneNameByStatus({ status: status.name, mainJane })}
              {isSharedJanePrimaryCommunityDifferentFromSelectedLocation && (
                <Fragment>
                  &nbsp; &nbsp;
                  <BKJIcon iconName="Group2" width={16} height={14} />
                </Fragment>
              )}
            </PremiumJaneName>
          </Flex>
          &nbsp; &nbsp;
          <Flex alignItems="center" justifyContent="center">
            <Tags
              id={id}
              status={status.value}
              isRecurring={false}
              isDedicated={dedicated}
              isSentToAgencies={sentToAgencies}
              hasJane={!!mainJane}
              janePrimaryCommunity={mainJane?.primaryCommunity}
              isAgencyJane={!!mainJane?.agencyCommunity}
              isAcceptedBySharedTeamMember={!!acceptedBySharedTeamMember}
              biddable={biddable}
              bidders={bidders}
              biddingTimeEndsAt={biddingTimeEndsAt}
            />
          </Flex>
        </Flex>
      </ShiftsTableData>
      <ShiftsTableData>
        <Label>Position</Label>
        <Text>{position.name}</Text>
      </ShiftsTableData>
      <ShiftsTableData>
        <Label>Status</Label>
        <ShiftStatusBadge status={status.value}>
          {getTranslatedResponse(status?.name, 'status', i18n, t)}
        </ShiftStatusBadge>
      </ShiftsTableData>
      <ShiftsTableData>
        <Label>Start Time</Label>
        <Text>{getHoursAndMinutesFromDate(startAt, timeZone)}</Text>
      </ShiftsTableData>
      <ShiftsTableData>
        <Label>End Time</Label>
        <Text>{getHoursAndMinutesFromDate(endAt, timeZone)}</Text>
      </ShiftsTableData>
      <ShiftsTableData>
        <Label>Unpaid Break Time</Label>
        <Text>{calculateBreakTime(orderSchedule)}</Text>
      </ShiftsTableData>
      <ShiftsTableData>
        <Label>Total Duration</Label>
        <Text>{calculateDuration(totalDuration)}</Text>
      </ShiftsTableData>
      <ShiftsTableData>
        <Label>Hourly Pay Rate </Label>
        {showRate && rate && <Text>{rate.rateLabel}</Text>}
      </ShiftsTableData>
      <ShiftsTableData>
        <Label>Estimated Total Pay </Label>
        {showRate && estimatedTotalCharge && (
          <Text>
            <FormatCurrency amount={`${estimatedTotalCharge.rateLabel}`} />
          </Text>
        )}
      </ShiftsTableData>
      <ShiftsTableData>
        <Label>Shift No. </Label>
        <Text>{orderSchedule.id}</Text>
      </ShiftsTableData>
      <ShiftsTableData>
        <Label>Created At </Label>
        <DateText>{parseVerboseDate(createdAt, timeZone)}</DateText>
      </ShiftsTableData>
    </BKJTableRowWrapper>
  );
};
