import { ViewStaticGroup, AddMembers, EditMembers } from 'components/GroupsModal/views/static';
import { EditGroup, NewGroup } from 'components/GroupsModal/views';
import { UseGQLQueryConfig } from 'hooks/useGQLQuery/useGQLQuery.types';
import { QUERY_settingsGroupDetails } from 'queries';
import {
  fetchGroupDetails,
  fetchGroupDetailsVariables,
  fetchGroupDetails_node_StaticGroup,
  fetchGroupDetails_node_DynamicGroup,
} from 'types/graphql-types';
import { SelectCriteria, ViewDynamicGroup } from 'components/GroupsModal/views/dynamic';

export const GroupsDetailsComponentMap = {
  NewGroup,
  ViewStaticGroup,
  ViewDynamicGroup,
  EditGroup,
  AddMembers,
  EditMembers,
  SelectCriteria,
  EditCriteria: SelectCriteria,
};
export type GroupsDetailsModalViewType = keyof typeof GroupsDetailsComponentMap;

export const fetchGroupConfig: (
  groupId: string,
) => UseGQLQueryConfig<
  fetchGroupDetails,
  Nullable<fetchGroupDetails_node_StaticGroup | fetchGroupDetails_node_DynamicGroup>,
  fetchGroupDetailsVariables
> = (groupId) => {
  return {
    key: 'FETCH_GROUP_DETAILS_QUERY',
    query: QUERY_settingsGroupDetails,
    variables: {
      id: groupId,
    },
    responseTransformer: (data) =>
      data?.node as fetchGroupDetails_node_StaticGroup | fetchGroupDetails_node_DynamicGroup,
  };
};
