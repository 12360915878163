import {
  CreateShiftFormValuesType,
  ICreateShift,
} from 'pages/CreateShiftsPage/CreateShiftsPage.types';
import { getShiftStartTimeAndEndTime } from 'pages/CreateShiftsPage/CreateShiftsPage.utils';
import { ISO8601Type } from 'types/common.types';
import { castStringToInteger, formatNumberWithOrdinalSuffix } from 'utils';
import { getPluralizedTimeUnitString } from 'utils/time.js';
import i18next from 'i18next';
import { DateTime } from 'luxon';
import { cloneDeep } from 'utils/cloneDeep';
export const getShiftStartTime = (shift: ICreateShift) => {
  const { startDate, startAt, endAt } = shift.values;
  return getShiftStartTimeAndEndTime({
    startDate,
    startAt,
    endAt,
  })[0];
};

export const sortShiftsByStartTime = (createShifts: ICreateShift[]): ICreateShift[] =>
  createShifts.reduce((acc: ICreateShift[], _currentCreateShift) => {
    const currentCreateShift = cloneDeep(_currentCreateShift);
    if (!acc.length) {
      return acc.concat([currentCreateShift]);
    }

    const currentCreateShiftIndex = acc.findIndex(
      (createShift) =>
        DateTime.fromISO(getShiftStartTime(createShift)) >=
        DateTime.fromISO(getShiftStartTime(currentCreateShift)),
    );

    if (currentCreateShiftIndex >= 0) {
      acc.splice(currentCreateShiftIndex, 0, currentCreateShift);
      return acc;
    }

    return acc.concat([currentCreateShift]);
  }, []);

export const groupShiftsByPosition = (createShifts: ICreateShift[]): ICreateShift[][] =>
  createShifts.reduce((acc: ICreateShift[][], createShift) => {
    if (!acc.length) return [[createShift]];

    const positionIndex = acc.findIndex((positionShifts) => {
      return positionShifts[0].values.position_id[0] === createShift.values.position_id[0];
    });

    if (positionIndex < 0) {
      return acc.concat([[createShift]]);
    }

    acc[positionIndex] = acc[positionIndex].concat([createShift]);
    return acc;
  }, []);

export const getShiftsCount = (createShifts: ICreateShift[]): number =>
  createShifts.reduce((acc: number, shift) => {
    acc = acc + parseInt(shift.values.quantity[0]);
    return acc;
  }, 0);

export const prettyShiftDate = (shiftDate: ISO8601Type, language: string): string => {
  const shiftDateTime = DateTime.fromISO(shiftDate);

  const dayName = shiftDateTime.toFormat('EEE');
  const dayOfWeek = i18next.t(`translations:${dayName.toUpperCase()}`);

  const monthName = shiftDateTime.toFormat('MMM');
  const month = i18next.t(`translations:${monthName.toUpperCase()}`);

  return shiftDateTime.year === DateTime.now().year
    ? `${dayOfWeek}, ${month} ${shiftDateTime.toFormat('dd')}`
    : `${dayOfWeek}, ${month} ${shiftDateTime.toFormat('dd, YYYY')}`;
};

export const prettyStartTimeAndEndTime = ([startAt, endAt]: [string, string]): string => {
  const [startTime, endTime] = getShiftStartTimeAndEndTime({
    startAt,
    endAt,
  });

  return `${DateTime.fromISO(startTime).toFormat('h:mm a').toLowerCase()} - ${DateTime.fromISO(
    endTime,
  )
    .toFormat('h:mm a')
    .toLowerCase()}`;
};

export const prettyUnpaidBreakTime = (breakTime: string) => {
  const time = breakTime.split(':').map((t) => parseInt(t, 10));
  if (time[0] === 0 && time[1] === 0) {
    return `0 mins`;
  }
  return `${time[0] ? getPluralizedTimeUnitString(time[0], 'hr') : ''}  ${
    time[1] ? getPluralizedTimeUnitString(time[1], 'min') : ''
  }`;
};

export const isRecurring = (shift: CreateShiftFormValuesType) => {
  if (shift.recurring_frequency.length > 0) {
    return true;
  } else {
    return false;
  }
};

export const getRecurringLabel = (shift: CreateShiftFormValuesType) => {
  let label = i18next.t('create_shifts:RECURRING_SHIFT');
  if (isRecurring(shift)) {
    const interval = castStringToInteger(shift.recurring_interval[0]);
    const frequency = shift.recurring_frequency[0];
    const TranslatedFrequency = i18next.t(frequency.toUpperCase());
    let label = `${i18next.t('create_shifts:RECURRING')}: ${i18next.t('schedule:EVERY')} ${
      interval > 1 ? interval : ''
    } ${TranslatedFrequency}${interval > 1 ? 's' : ''}`;
    const day = DateTime.fromISO(shift.startDate).day;
    const selectedWeekdays = shift.recurring_weekday.map(
      (day) => day.charAt(0).toUpperCase() + day.slice(1),
    );

    const sortedWeekdays = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ].filter((weekday) => selectedWeekdays.includes(weekday));
    if (frequency === 'week') {
      const translatedWeekdays = sortedWeekdays.map((weekday) => i18next.t(weekday.toUpperCase()));
      const weekdaysString = translatedWeekdays.join(', ');
      return label.concat(` ${i18next.t('schedule:ON').toLowerCase()} ${weekdaysString}`);
    } else if (frequency === 'month') {
      return `${label} ${i18next
        .t('schedule:ON_THE')
        .toLowerCase()} ${formatNumberWithOrdinalSuffix(day)}`;
    }

    return label;
  }
  return label;
};
