import styled from 'styled-components';

export const TieringDetailsWrapper = styled.div`
  padding: 32px 40px 0;
  display: flex;
  flex-direction: column;
  justify-content: 'flex-start';
  align-items: 'flex-start';
  height: 100%;
`;

export const SharingDetailsWrapper = styled.div`
  padding: 32px 40px 0;
  display: flex;
  flex-direction: column;
  justify-content: 'flex-start';
  align-items: 'flex-start';
  height: 100%;
  width: 100%;
`;

export const SharingHeader = styled.div`
  ${({ theme }) => theme.fonts.PoppinsTextBold};
  padding: 58px 0 0 16px;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
`;

export const TieringDetailsTableRow = styled.div`
  display: grid;
  grid-template-columns: 1.5fr 1fr;
`;

export const TieringDetailsHeader = styled.h3`
  ${({ theme }) => theme.fonts.PoppinsTextMedium};
  font-weight: 700;
  font-size: 26px;
  line-height: 34px;
  letter-spacing: 2%;
  padding-bottom: 20px;
`;

export const TieringDetailsSubHeader = styled.div`
  font-weight: 700;
  line-height: 28px;
  font-size: 20px;
  padding: 32px 0 24px 0;
`;

export const TieringDetailsBody = styled.div`
  ${({ theme }) => theme.fonts.PoppinsText};
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  padding-bottom: 20px;
`;

export const TieringOverlayBold = styled.span`
  ${({ theme }) => theme.fonts.PoppinsTextBold};
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
`;

export const TieringTypeCategory = styled.span`
  ${({ theme }) => theme.fonts.PoppinsTextBold};
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
`;

export const TieringTypeCategoryValue = styled.span`
  ${({ theme }) => theme.fonts.PoppinsText};
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
`;

export const TieringTypeTablePadding = styled.div`
  padding: 24px 0;
`;

export const TieringRulesTableHeaderDivider = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 0;
  font-size: 14px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  ${({ theme }) => theme.fonts.PoppinsTextBold};
  border-bottom: 4px solid ${({ theme }) => theme.colors.Grey4};
`;

export const TieringDetailsTableContentCell = styled.div<{ isGrey?: boolean }>`
  background-color: ${({ isGrey, theme }) => (isGrey ? theme.colors.Grey1 : 'white')};
  min-height: 70px;
  max-height: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  padding: 0px 16px;
  font-size: 14px;
  line-height: 22px;
  ${({ theme }) => theme.fonts.PoppinsText};
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  border-bottom: 1px solid ${({ theme }) => theme.colors.Grey4};
  display: flex;
  align-items: center;
`;

export const TieringDetailsTableHeaderCell = styled.div`
  min-height: 6px;
  height: 6px;
  padding: 6px 6px;
  font-weight: 700;
  font-size: 16px;
  ${({ theme }) => theme.fonts.PoppinsTextMedium};
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
`;

export const H3 = styled.div`
  ${({ theme }) => theme.fonts.PoppinsTextBold};
  font-size: 28px;
  line-height: 34px;
  color: #1c0f51;
`;
