import { Flex, BKJButton } from '@bookjane2/bookjane-design-library';
import { useShiftModalDataContext } from 'components/ShiftModal/ShiftModalProvider.data.context';
import {
  CallOutHeader,
  BiddingWrapper,
  CallOutViewDetailsLink,
  CallOutTitleSection,
  CallOutBodySection,
  EstimatedTimeSection,
  BKJProgressBar,
  CallOutProgressBarSection,
  CallOutHeaderText,
  CallOutHeaderWrapper,
  ProgressBold,
  ProgressText,
} from 'components/ShiftModal/src/views/DetailView/GeneralTab/CallOut.styled';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'hooks/useTranslation';
import { shiftModalRoutingService } from 'components/ShiftModal/ShiftModal.ui.context';
import { getShiftBiddingStatus } from 'utils/shiftStatus';
import { DateTime } from 'luxon';
import { getCurrentlySelectedLocation } from 'utils/getCurrentlySelectedLocation';

export const calculateCurrentProgress = (
  shiftCreationTime: string | undefined,
  biddingTimeEndsAt: string | undefined,
  timeZone: string | undefined,
): number | undefined => {
  if (!shiftCreationTime || !biddingTimeEndsAt) return undefined;

  const shiftTime = DateTime.fromISO(shiftCreationTime).setZone(timeZone);
  const biddingEndTime = DateTime.fromISO(biddingTimeEndsAt).setZone(timeZone);
  const stepValue = (biddingEndTime.valueOf() - shiftTime.valueOf()) / 10;
  const currentTime = DateTime.now().setZone(timeZone);
  const currentProgress = Math.floor((currentTime.valueOf() - shiftTime.valueOf()) / stepValue);

  return currentProgress;
};

export function BiddingSection() {
  const { t } = useTranslation();
  const {
    data: { fetchOrder },
  } = useShiftModalDataContext();

  const goToAuditTrailView = useCallback(
    (calloutListId: string) =>
      shiftModalRoutingService.goToView('AuditTrailView', {
        calloutListId: calloutListId,
      }),
    [],
  );

  const biddingStatus = getShiftBiddingStatus({
    status: fetchOrder.status.value,
    biddable: fetchOrder.biddable,
    biddingTimeEndsAt: fetchOrder.biddingTimeEndsAt,
    bidders: fetchOrder.bidders,
  });

  const goToAwardTeamMemberView = useCallback(
    () => shiftModalRoutingService.goToView('AwardTeamMemberView'),
    [],
  );
  const { timeZone } = getCurrentlySelectedLocation();

  const currentProgress = useMemo(() => {
    const progress = calculateCurrentProgress(
      fetchOrder?.createdAt,
      fetchOrder?.biddingTimeEndsAt,
      timeZone,
    );

    if (progress !== undefined) {
      return progress;
    }
  }, [fetchOrder?.biddingTimeEndsAt, fetchOrder?.createdAt, timeZone]);

  const maxStep = 10;

  const biddingStatusLabel =
    typeof currentProgress === 'number'
      ? currentProgress < maxStep
        ? t('shift_modal:BIDDING_IN_PROGRESS')
        : t('shift_modal:BIDDING_COMPLETE')
      : t('shift_modal:BIDDING_PROGRESS_NOT_AVAILABLE');

  return (
    <BiddingWrapper>
      <CallOutTitleSection>
        <Flex flexDirection="row" flex="1">
          <CallOutHeader>
            <CallOutHeaderWrapper>
              <CallOutHeaderText>{biddingStatusLabel}</CallOutHeaderText>
            </CallOutHeaderWrapper>
          </CallOutHeader>
          {!!fetchOrder.currentlyActiveCalloutList?.id && (
            <CallOutViewDetailsLink
              onClick={() => goToAuditTrailView(fetchOrder.currentlyActiveCalloutList!.id)}
            >
              {t('shift_modal:VIEW_DETAILS')}
            </CallOutViewDetailsLink>
          )}
        </Flex>
      </CallOutTitleSection>
      <CallOutProgressBarSection>
        <BKJProgressBar value={currentProgress} max={maxStep} height="8px;"></BKJProgressBar>
      </CallOutProgressBarSection>
      <CallOutBodySection>
        <Flex flexDirection="row" flex="1">
          {fetchOrder.biddingTimeEndsAt && (
            <EstimatedTimeSection>
              <ProgressBold>{t('shift_modal:BIDDING_ENDS')}:&nbsp;</ProgressBold>
              <ProgressText>
                {DateTime.fromISO(fetchOrder.biddingTimeEndsAt)
                  .setZone(timeZone)
                  .toFormat(`EEE, MMM d 'at' h:mm a`)}
              </ProgressText>
            </EstimatedTimeSection>
          )}
        </Flex>
        {biddingStatus === 'AWARDING' && (
          <Flex padding="24px 0 8px 0" justifyContent="end">
            <BKJButton variant="PurpleSolid" width="180px" onClick={goToAwardTeamMemberView}>
              {t('shift_modal:AWARD')}
            </BKJButton>
          </Flex>
        )}
      </CallOutBodySection>
    </BiddingWrapper>
  );
}
