import { ErrorBoundary } from '@bookjane2/bookjane-design-library';
import { BKJCenteredDialog } from 'components/BKJCenteredDialog';
import { BKJSideModal } from 'components/BKJSideModal';
import { GroupsDetailsComponentMap } from 'components/GroupsModal/GroupsModal.constants';
import { GroupsModalDataContext } from 'components/GroupsModal/GroupsModal.data.context';
import {
  GroupsModalFormProvider,
  useGroupsModalFormContext,
} from 'components/GroupsModal/GroupsModal.form.context';
import { FormWrapper } from 'components/GroupsModal/GroupsModal.styled';
import {
  groupsModalRoutingService,
  useGroupsDetailsModalUIContext,
} from 'components/GroupsModal/GroupsModal.ui.context';
import { QuerySwitch } from 'components/QuerySwitch';
import { FC, FormEventHandler, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { WhiteLabelAccessor } from 'utils/whiteLabelAccessor';

const EditableViews = [
  'AddMembers',
  'EditGroup',
  'EditMembers',
  'NewGroup',
  'SelectCriteria',
  'EditCriteria',
];
const QuerySwitchViews = [
  'EditGroup',
  'EditCriteria',
  'ViewStaticGroup',
  'ViewDynamicGroup',
  'EditMembers',
];

export const GroupsModalViewComponent: FC = () => {
  const { isOpen, groupsActiveModalView } = useGroupsDetailsModalUIContext();
  const { onSubmit } = useGroupsModalFormContext();
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false);

  const Component = GroupsDetailsComponentMap[groupsActiveModalView];

  const { t } = useTranslation();

  const modalHeader = {
    NewGroup: t('groups:NEW_GROUP'),
    AddMembers: t('groups:NEW_GROUP'),
    SelectCriteria: t('groups:NEW_GROUP'),
    EditCriteria: t('groups:EDIT_GROUP'),
    EditGroup: t('groups:EDIT_GROUP'),
    EditMembers: t('groups:EDIT_GROUP'),
    ViewStaticGroup: t('groups:GROUP_DETAILS'),
    ViewDynamicGroup: t('groups:GROUP_DETAILS'),
  };

  return (
    <BKJSideModal
      isOpen={isOpen}
      onClose={() => {
        // Use confirmation dialog if it's an editable viewe
        if (EditableViews.includes(groupsActiveModalView)) {
          setIsConfirmDeleteOpen(true);
        } else groupsModalRoutingService.close();
      }}
      showBackButton={groupsModalRoutingService.isBackButtonVisible()}
      onBack={groupsModalRoutingService.goToPrevView}
      headerComponent={modalHeader[groupsActiveModalView]}
    >
      <ErrorBoundary componentName="GroupsModal" showLogo={!WhiteLabelAccessor.isWhiteLabelled()}>
        <GroupsModalFormProvider>
          <FormWrapper onSubmit={onSubmit as unknown as FormEventHandler<HTMLFormElement>}>
            <BKJCenteredDialog
              isOpen={isConfirmDeleteOpen && isOpen}
              variant="Danger"
              title={t('groups:DISCARD_CHANGES')}
              text={''}
              cancelLabel={t('groups:CANCEL')}
              actionLabel={t('groups:DISCARD')}
              onAction={() => {
                setIsConfirmDeleteOpen(false);
                groupsModalRoutingService.close();
              }}
              onCancel={() => setIsConfirmDeleteOpen(false)}
              onClose={() => setIsConfirmDeleteOpen(false)}
            />
            {isOpen && <Component />}
          </FormWrapper>
        </GroupsModalFormProvider>
      </ErrorBoundary>
    </BKJSideModal>
  );
};

export const GroupsModalComponent: FC = () => {
  const { groupsActiveModalView } = useGroupsDetailsModalUIContext();
  if (QuerySwitchViews.includes(groupsActiveModalView)) {
    return <QuerySwitch context={GroupsModalDataContext} component={GroupsModalViewComponent} />;
  }
  return <GroupsModalViewComponent />;
};
