import { ErrorBoundary } from '@bookjane2/bookjane-design-library';
import { CreateShiftButton } from 'components/CreateShiftButton';
import { DesktopPageHeader } from 'components/DesktopPageHeader';
import { Flex } from 'components/Flex';
import { MobilePageHeader } from 'components/MobilePageHeader/MobilePageHeader';
import { PageWrapper } from 'components/PageWrapper';
import { SettingsNavigation } from 'components/SettingsNavigation';
import { SetupBanner } from 'components/SetupBanner/SetupBanner';
import { useDimensionsRef } from 'hooks';
import { FacilitiesPage } from 'pages/OldSettingsPage/FacilitiesPage';
import { SettingsLandingPage } from 'pages/SettingsLandingPage';
import { LocationSettingsPage } from 'pages/LocationSettingsPage';
import { SettingsPageViewTemplate } from 'pages/SettingsPage/SettingsPage.styled';
import { FC, Fragment } from 'react';
import { Route, RouteProps, Switch, useRouteMatch } from 'react-router-dom';
import { MountTransition } from 'routes';
import { PositionsSettingsPage } from 'pages/SettingsPage/PositionsSettingsPage';
import { PositionsSettingsRedirectPage } from 'pages/SettingsPage/PositionsSettingsPage/PositionsSettingsPageRedirectPage';
import { SessionService } from 'services';
import { WhiteLabelAccessor } from 'utils/whiteLabelAccessor';
import { AdministratorSettingsPage } from 'pages/SettingsPage/AdministratorSettingsPage/AdministratorSettingsPage';
import { AdministratorSettingsRedirectPage } from 'pages/SettingsPage/AdministratorSettingsPage';
import { ShiftDistributionRulesTieringModal } from 'components/ShiftDistributionRulesTieringModal';
import { GroupsModal } from 'components/GroupsModal';
import { useTranslation } from 'hooks/useTranslation';
import { UserProfilePage } from 'pages/SettingsPage/UserProfilePage';
import { AccushieldDashboardButton } from 'components/AccushieldDashboardButton';
import { CompanyProfilePage } from 'pages/SettingsPage/CompanyProfilePage';

const SettingsModals: FC = () => {
  return (
    <Fragment>
      <GroupsModal />
      <ShiftDistributionRulesTieringModal />
    </Fragment>
  );
};

const SettingsPage: FC<RouteProps> = () => {
  const [ref, { width: x }] = useDimensionsRef<HTMLDivElement>();
  const { t } = useTranslation();
  const { isAccushieldProvider } = WhiteLabelAccessor;
  return (
    <PageWrapper>
      <SetupBanner />
      <DesktopPageHeader title={t('settings:SETTINGS')}>
        <Flex flexDirection="row-reverse" width="100%" gap="5px">
          {isAccushieldProvider && <AccushieldDashboardButton />}
          <CreateShiftButton />
        </Flex>
      </DesktopPageHeader>
      <MobilePageHeader title={t('settings:SETTINGS')} />
      <Flex width="100%" height="100%">
        <SettingsNavigation ref={ref} />
        <SettingsModals />
        <PageViewTemplate x={x} />
      </Flex>
    </PageWrapper>
  );
};

const PageViewTemplate: FC<{ x: number }> = ({ x }) => {
  const { path } = useRouteMatch();
  const isPendingApproval = SessionService.getUser()?.access_status === 'not_approved';

  return (
    <SettingsPageViewTemplate x={x} flexDirection="column" isPendingApproval={isPendingApproval}>
      <ErrorBoundary
        componentName="SettingsModule"
        suppressMessage
        showLogo={!WhiteLabelAccessor.isWhiteLabelled()}
      >
        <MountTransition>
          <Switch>
            <Route exact path={`${path}`} component={SettingsLandingPage} />
            <Route exact path={`${path}/locations`} component={FacilitiesPage} />
            <Route path={`${path}/locations/:locationId`} component={LocationSettingsPage} />
            <Route exact path={`${path}/positions`} component={PositionsSettingsRedirectPage} />
            <Route path={`${path}/positions/:id`} component={PositionsSettingsPage} />
            <Route
              exact
              path={`${path}/administrators`}
              component={AdministratorSettingsRedirectPage}
            />
            <Route exact path="/settings/user" component={UserProfilePage} />
            <Route exact path="/settings/company" component={CompanyProfilePage} />
            <Route path={`${path}/administrators/:id`} component={AdministratorSettingsPage} />
          </Switch>
        </MountTransition>
      </ErrorBoundary>
    </SettingsPageViewTemplate>
  );
};

export default SettingsPage;
