import { FC, Fragment, useMemo } from 'react';
import {
  DedicatedBKJBadge,
  BadgeText,
} from 'components/ShiftCardSkeleton/ShiftCardSkeleton.styled';
import { ITagsProps } from 'components/Tags/Tags.types';
import { UserGuard } from 'guards/UserGuard';
import { useTranslation } from 'hooks/useTranslation';
import { UserPermissionEnum } from 'guards/UserGuard.types';
import { isMultiLocationFeatureEnabledSelector } from 'store/selectors/featureFlagSelectors';
import { getShiftBiddingStatus } from 'utils/shiftStatus';
import { useSelector } from 'react-redux';
import { getCurrentlySelectedLocation } from 'utils/getCurrentlySelectedLocation';
import { OrderStatusEnum } from 'types/graphql-types';

const multiLocationOrderStatusesWhereSharedTagForceHidden: OrderStatusEnum[] = [
  OrderStatusEnum.assigned,
  OrderStatusEnum.in_progress,
  OrderStatusEnum.completed,
];

export const Tags: FC<ITagsProps> = ({
  isRecurring: isRecurringTagVisible,
  isDedicated: isDedicatedTagVisible,
  isSentToAgencies,
  hasJane,
  janePrimaryCommunity,
  isAgencyJane,
  isAcceptedBySharedTeamMember,
  status,
  biddable = false,
  biddingTimeEndsAt,
  bidders = [],
}) => {
  const { t } = useTranslation();
  const isAgencyTagVisible =
    isSentToAgencies &&
    ((hasJane && isAgencyJane) || !hasJane) &&
    !isDedicatedTagVisible &&
    !isAcceptedBySharedTeamMember;

  const biddableTag = getShiftBiddingStatus({ status, biddable, biddingTimeEndsAt, bidders });
  const currentlySelectedLocationId = getCurrentlySelectedLocation()?.value;
  const isMultiLocationFeatureEnabled = useSelector(
    isMultiLocationFeatureEnabledSelector(Number(currentlySelectedLocationId)),
  );
  const isSharedTagForceHidden = useMemo(
    () =>
      isMultiLocationFeatureEnabled &&
      janePrimaryCommunity !== currentlySelectedLocationId &&
      multiLocationOrderStatusesWhereSharedTagForceHidden.includes(status),
    [isMultiLocationFeatureEnabled, janePrimaryCommunity, currentlySelectedLocationId, status],
  );

  return (
    <Fragment>
      {isRecurringTagVisible && (
        <Fragment>
          <DedicatedBKJBadge variant="Grey">
            <BadgeText>{t('create_shifts:RECURRING_TAG')}</BadgeText>
          </DedicatedBKJBadge>
          &nbsp; &nbsp;
        </Fragment>
      )}

      {isDedicatedTagVisible ? (
        <Fragment>
          <DedicatedBKJBadge variant="Grey">
            <BadgeText>{t('create_shifts:DEDICATED')}</BadgeText>
          </DedicatedBKJBadge>
          &nbsp; &nbsp;
        </Fragment>
      ) : (
        isAgencyTagVisible && (
          <UserGuard
            userType={[
              UserPermissionEnum.USER_TYPE_B2B,
              UserPermissionEnum.USER_TYPE_ESSENTIAL_SECONDARY,
              UserPermissionEnum.USER_TYPE_PREMIUM_SECONDARY,
            ]}
          >
            <Fragment>
              <DedicatedBKJBadge variant="Grey">
                <BadgeText>{t('dashboard:AGENCY')}</BadgeText>
              </DedicatedBKJBadge>
              &nbsp; &nbsp;
            </Fragment>
          </UserGuard>
        )
      )}
      {isAcceptedBySharedTeamMember && !isSharedTagForceHidden && (
        <Fragment>
          <DedicatedBKJBadge variant="Grey">
            <BadgeText>{t('dashboard:SHARED')}</BadgeText>
          </DedicatedBKJBadge>
          &nbsp; &nbsp;
        </Fragment>
      )}
      {!!biddableTag && (
        <Fragment>
          <DedicatedBKJBadge variant="Grey">
            <BadgeText>{t(`dashboard:${biddableTag}`)}</BadgeText>
          </DedicatedBKJBadge>
          &nbsp; &nbsp;
        </Fragment>
      )}
    </Fragment>
  );
};
