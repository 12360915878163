import { BKJButton, BKJIcon } from '@bookjane2/bookjane-design-library';
import { BKJSideModalFooter } from 'components/BKJSideModalFooter';
import { Flex } from 'components/Flex';
import { useShiftDistributionRulesGroupsModalUIContext } from 'components/ShiftDistributionRulesGroupsModal/ShiftDistributionRulesGroupsModal.ui.context';
import {
  H3,
  LocationHeader,
  LocationName,
  ScheduleRuleInformationSection,
  ScheduleRulesDescription,
  TableHeader,
  TableRowCell,
  TableRowCellContent,
  TableRowFirstCell,
  ViewDetailsLink,
} from 'components/ScheduleSettingsModal/views/ScheduleRules.styled';
import { FC, Fragment } from 'react';
import { useSelector } from 'react-redux';
import isUserPremiumSecondarySelector from 'store/selectors/userSelectors/isUserPremiumSecondarySelector';
import { css } from 'styled-components';
import {
  GroupsRulesDataProvider,
  useGroupsRulesDataContext,
} from 'components/ShiftDistributionRulesGroupsModal/views/GroupsRules.data.context';
import { InfiniteScrollWrapper } from 'components/SearchTeamMembers/SearchTeamMembers.styled';
import { InfiniteScrollLoading } from 'components/InfiniteScrollLoading';
import { useLocationByIdDataContext } from 'components/ShiftDistributionRulesGroupsModal/LocationById.data.context';
import { ModalContentWrapper } from 'components/ShiftDistributionRulesGroupsModal/views/ViewDetails.styled';
import { useTranslation } from 'hooks/useTranslation';

export const GroupsRulesViewComponent: FC = () => {
  const { goToAddPositionView, goToViewDetailsView } =
    useShiftDistributionRulesGroupsModalUIContext();
  const { location } = useLocationByIdDataContext();
  const { data, loadMore } = useGroupsRulesDataContext();
  const isReadOnly = useSelector(isUserPremiumSecondarySelector);
  const { t } = useTranslation();

  return (
    <ModalContentWrapper>
      <Flex flexDirection="column" padding="32px 40px 15px">
        <H3>{t('location_settings:RULES_FOR_SEND_TO_GROUPS')}</H3>
        <ScheduleRulesDescription>
          {t('location_settings:RULES_FOR_SEND_TO_GROUPS_DESCRIPTION')}
        </ScheduleRulesDescription>
        <LocationHeader>{t('common:LOCATION')}</LocationHeader>
        <LocationName>{location?.label}</LocationName>
        <TableHeader>{t('common:POSITION')}</TableHeader>
      </Flex>
      <TableRowFirstCell isGrey={true}>
        <TableRowCellContent>
          <Flex flexGrow={0} flexBasis="380px" flexDirection="column">
            <div>{t('common:DEFAULT')}</div>
            <ScheduleRuleInformationSection>
              {t('location_settings:APPLIES_TO_ALL')}
            </ScheduleRuleInformationSection>
          </Flex>
          <Flex flexDirection="row" alignItems="center" flexWrap="wrap">
            <ViewDetailsLink onClick={() => goToViewDetailsView({})}>
              {t('common:VIEW_DETAILS')}
            </ViewDetailsLink>
          </Flex>
        </TableRowCellContent>
      </TableRowFirstCell>
      {data &&
        data?.fetchShiftDistributionRules &&
        data.fetchShiftDistributionRules?.nodes &&
        data.fetchShiftDistributionRules?.nodes.map((node, i) => {
          if (!node?.position) return <Fragment key={i} />;
          return (
            node.position && (
              <TableRowCell isGrey={i % 2 === 1} key={i}>
                <TableRowCellContent>
                  <Flex flexGrow={0} flexBasis="380px" flexDirection="column">
                    <div>{node.position.name}</div>
                  </Flex>
                  <Flex flexDirection="row" alignItems="center" flexWrap="wrap">
                    <ViewDetailsLink
                      onClick={() =>
                        goToViewDetailsView({
                          positionSettingsId: `${node.position?.id}`,
                        })
                      }
                    >
                      {t('common:VIEW_DETAILS')}
                    </ViewDetailsLink>
                  </Flex>
                </TableRowCellContent>
              </TableRowCell>
            )
          );
        })}
      {!!data?.fetchShiftDistributionRules?.pageInfo?.hasNextPage && loadMore ? (
        <Flex width="100%" justifyContent="center" alignItems="center">
          <InfiniteScrollWrapper>
            <InfiniteScrollLoading onViewportEnter={loadMore} />
          </InfiniteScrollWrapper>
        </Flex>
      ) : null}
      <Flex height="100%" />
      <BKJSideModalFooter justifyContent="space-between" flexDirection="row-reverse">
        {!isReadOnly && (
          <BKJButton
            variant="PurpleSolid"
            width="200px"
            onClick={goToAddPositionView}
            type="button"
          >
            <BKJIcon
              iconName="Add"
              color="White"
              secondaryColor="PrimaryDefault"
              css={css`
                padding-right: 5px;
              `}
            />
            {t('common:ADD_POSITION')}
          </BKJButton>
        )}
      </BKJSideModalFooter>
    </ModalContentWrapper>
  );
};

export const GroupsRules: FC = () => {
  return (
    <GroupsRulesDataProvider>
      <GroupsRulesViewComponent />
    </GroupsRulesDataProvider>
  );
};
