import { bindActionCreators, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { toastService } from 'services';
import {
  initialFacilityViewState,
  initialCopyParamsState,
  initialCreateShiftState,
  initialSideModalState,
  initialJaneSortState,
} from 'store/reducers/scheduleSlice/index.initialState';
import assertKeys from 'utils/assertKeys';
import {
  getInitialScheduleState,
  getCachedScheduleState,
  getPrevFacilityViewState,
  persistScheduleState,
  generateViewKey,
  mergePayloadWithFacilityViewState,
  mergePayloadWithState,
} from './index.utils';
import { ScheduleFacilityViewState } from 'store/reducers/scheduleSlice/scheduleReducer.types';
import i18next from 'i18next';

const shiftStatusOptions = [
  {
    key: 'pending',
    label: 'PENDING',
  },
  {
    key: 'accepted',
    label: 'ACCEPTED',
  },
  {
    key: 'assigned',
    label: 'ASSIGNED',
  },
  {
    key: 'in_progress',
    label: 'IN_PROGRESS',
  },
  {
    key: 'completed',
    label: 'COMPLETED',
  },
  {
    key: 'unfulfilled',
    label: 'UNFULFILLED',
  },
  {
    key: 'cancelled',
    label: 'CANCELLED',
  },
  {
    key: 'swapped',
    label: 'SWAPPED',
  },
];

export const employmentStatusOptions: {
  key: 'full_time' | 'part_time' | 'casual' | 'flex';
  label: 'FULL_TIME' | 'PART_TIME' | 'CASUAL' | 'FLEX';
}[] = [
  {
    key: 'full_time',
    label: 'FULL_TIME',
  },
  {
    key: 'part_time',
    label: 'PART_TIME',
  },
  {
    key: 'casual',
    label: 'CASUAL',
  },
  {
    key: 'flex',
    label: 'FLEX',
  },
];
export const janeStaffTypeOptions: {
  key: 'internal' | 'shared' | 'agency_marketplace';
  label: 'INTERNAL' | 'SHARED' | 'AGENCY_MARKETPLACE';
}[] = [
  {
    key: 'internal',
    label: 'INTERNAL',
  },
  {
    key: 'shared',
    label: 'SHARED',
  },
  {
    key: 'agency_marketplace',
    label: 'AGENCY_MARKETPLACE',
  },
];
export const timeFrameOptions: {
  key: 'morning' | 'evening' | 'night';
  label: 'DAY_SHIFT' | 'AFTERNOON_SHIFT' | 'OVERNIGHT_SHIFT';
}[] = [
  {
    key: 'morning',
    label: 'DAY_SHIFT',
  },
  {
    key: 'evening',
    label: 'AFTERNOON_SHIFT',
  },
  {
    key: 'night',
    label: 'OVERNIGHT_SHIFT',
  },
];
export const scheduleSlice = createSlice({
  initialState: getInitialScheduleState(getCachedScheduleState()),
  name: 'schedule',
  reducers: {
    addPositionTab: {
      reducer: (state, action: PayloadAction<{ positionId: string }>) => {
        const { selectedFacilityId, facilityViewState } = getPrevFacilityViewState(state);

        const nextFacilityViewState: ScheduleFacilityViewState = {};

        nextFacilityViewState.positionTabIds = facilityViewState.positionTabIds.concat(
          action.payload.positionId,
        );
        if (facilityViewState.activeTab) {
          nextFacilityViewState.prevActiveTab = facilityViewState.activeTab;
        }
        nextFacilityViewState.activeTab = action.payload.positionId;
        nextFacilityViewState.isCreateNewTabOpen = false;

        const jane_sort_key = `janeSortBy_${action.payload.positionId}`;

        return persistScheduleState({
          ...state,
          [generateViewKey(selectedFacilityId)]: {
            ...facilityViewState,
            ...nextFacilityViewState,
            [jane_sort_key]: initialJaneSortState,
          },
        });
      },
      prepare: (positionId: string) => ({ payload: { positionId } }),
    },
    setIsScheduleCalendarOpen: {
      reducer: mergePayloadWithFacilityViewState,
      prepare: (isScheduleCalendarOpen) => ({
        payload: { isScheduleCalendarOpen },
      }),
    },
    setIsScheduleSettingsModalOpen: {
      reducer: mergePayloadWithFacilityViewState,
      prepare: (isScheduleSettingsModalOpen) => ({
        payload: { isScheduleSettingsModalOpen },
      }),
    },
    setIsScheduleSettingsModalEditModeEnabled: {
      reducer: mergePayloadWithFacilityViewState,
      prepare: (isScheduleSettingsModalEditModeEnabled) => ({
        payload: { isScheduleSettingsModalEditModeEnabled },
      }),
    },
    removePositionTab: {
      prepare: (positionId) => ({ payload: { positionId } }),
      reducer: (state, action: PayloadAction<{ positionId: string }>) => {
        const { selectedFacilityId, facilityViewState } = getPrevFacilityViewState(state);

        const nextFacilityViewState: ScheduleFacilityViewState = {};

        const currentActiveTab = facilityViewState.activeTab;

        const isRemovedSameAsActiveTab = action.payload.positionId === currentActiveTab;

        const filteredTabs: string[] = facilityViewState.positionTabIds.filter(
          (id: string) => id !== action.payload.positionId,
        );

        if (facilityViewState.prevActiveTab) {
          nextFacilityViewState.activeTab = facilityViewState.prevActiveTab;
          nextFacilityViewState.prevActiveTab = null;
        } else {
          if (facilityViewState.activeTab)
            nextFacilityViewState.prevActiveTab = facilityViewState.activeTab;
          nextFacilityViewState.activeTab = filteredTabs[filteredTabs.length - 1];
        }

        if (!isRemovedSameAsActiveTab && currentActiveTab)
          nextFacilityViewState.activeTab = currentActiveTab;

        if (isRemovedSameAsActiveTab && !facilityViewState.prevActiveTab)
          nextFacilityViewState.activeTab = filteredTabs[filteredTabs.length - 1];

        nextFacilityViewState.positionTabIds = filteredTabs;

        if (!filteredTabs.length) {
          nextFacilityViewState.activeTab = null;
          nextFacilityViewState.prevActiveTab = null;
        }

        if (filteredTabs.length && !nextFacilityViewState.activeTab) {
          nextFacilityViewState.activeTab = filteredTabs[filteredTabs.length - 1];
        }

        return persistScheduleState({
          ...state,
          [generateViewKey(selectedFacilityId)]: {
            ...facilityViewState,
            ...nextFacilityViewState,
          },
        });
      },
    },
    saveScrollPosition: {
      prepare: ({ scrollPosition, positionId }) => ({
        payload: { positionId, scrollPosition },
      }),
      reducer: (state, action: PayloadAction<{ positionId: string; scrollPosition: string }>) => {
        const { selectedFacilityId, facilityViewState } = getPrevFacilityViewState(state);
        const pagination_key = `pagination_${action.payload.positionId}`;
        return persistScheduleState({
          ...state,
          [generateViewKey(selectedFacilityId)]: {
            ...facilityViewState,
            [pagination_key]: {
              ...facilityViewState[pagination_key],
              scrollPosition: action.payload.scrollPosition,
            },
          },
        });
      },
    },
    setActiveTab: {
      prepare: (activeTab) => ({ payload: { activeTab } }),
      reducer: (state, action: PayloadAction<{ activeTab: string }>) => {
        const { selectedFacilityId, facilityViewState } = getPrevFacilityViewState(state);

        const nextFacilityViewState: ScheduleFacilityViewState = {};

        if (facilityViewState.activeTab) {
          nextFacilityViewState.prevActiveTab = facilityViewState.activeTab;
        }
        nextFacilityViewState.activeTab = action.payload.activeTab;

        return persistScheduleState({
          ...state,
          [generateViewKey(selectedFacilityId)]: {
            ...facilityViewState,
            ...nextFacilityViewState,
          },
        });
      },
    },
    setSelectedWeekRange: {
      reducer: mergePayloadWithFacilityViewState,
      prepare: (selectedWeekRange) => ({ payload: { selectedWeekRange } }),
    },
    setJaneSortBy: {
      reducer: (state, action: PayloadAction<{ sortBy: string }>) => {
        const { selectedFacilityId, facilityViewState } = getPrevFacilityViewState(state);
        const jane_sort_key = `janeSortBy_${facilityViewState.activeTab}`;
        return persistScheduleState({
          ...state,
          [generateViewKey(selectedFacilityId)]: {
            ...facilityViewState,
            [jane_sort_key]: {
              ...facilityViewState[jane_sort_key],
              sortBy: action.payload.sortBy,
            },
          },
        });
      },
      prepare: (sortBy) => ({ payload: { sortBy } }),
    },
    setContextMenu: {
      prepare: (contextMenu) => ({ payload: { contextMenu } }),
      reducer: mergePayloadWithState,
    },
    setInitialState: {
      prepare: (prep) => prep,
      reducer: () => getInitialScheduleState(getCachedScheduleState()),
    },
    setIsCreateNewTabOpen: {
      prepare: (isCreateNewTabOpen: boolean) => ({
        payload: { isCreateNewTabOpen },
      }),
      reducer: (state, action: PayloadAction<{ isCreateNewTabOpen: boolean }>) => {
        const { selectedFacilityId, facilityViewState } = getPrevFacilityViewState(state);

        const nextFacilityViewState: ScheduleFacilityViewState = {};

        if (action.payload.isCreateNewTabOpen) {
          if (facilityViewState.activeTab) {
            // if isCreateNewTabOpen is changing to true then:
            // set previous active tab value to current active tab value
            nextFacilityViewState.prevActiveTab = facilityViewState.activeTab;
            nextFacilityViewState.activeTab = null;
          }
        }
        if (!action.payload.isCreateNewTabOpen) {
          // if isCreateNewTabOpen is changing to false then:
          // set active tab value to previous tab value and clear prev active tab
          if (facilityViewState.prevActiveTab) {
            nextFacilityViewState.activeTab = facilityViewState.prevActiveTab;
            nextFacilityViewState.prevActiveTab = null;
          }
        }

        nextFacilityViewState.isCreateNewTabOpen = action.payload.isCreateNewTabOpen;

        return persistScheduleState({
          ...state,
          [generateViewKey(selectedFacilityId)]: {
            ...facilityViewState,
            ...nextFacilityViewState,
          },
        });
      },
    },
    setShowGroupsFilter: {
      reducer: mergePayloadWithFacilityViewState,
      prepare: (showGroupsFilter) => ({
        payload: { showGroupsFilter },
      }),
    },
    setSelectedScheduleStartDate: {
      reducer: mergePayloadWithFacilityViewState,
      prepare: (selectedScheduleStartDate) => ({
        payload: { selectedScheduleStartDate },
      }),
    },
    setIsFiltersSlideDownPanelOpen: {
      reducer: mergePayloadWithFacilityViewState,
      prepare: (isFiltersSlideDownPanelOpen) => ({
        payload: { isFiltersSlideDownPanelOpen },
      }),
    },
    setIsBudgetingPanelExpanded: {
      reducer: mergePayloadWithFacilityViewState,
      prepare: (isBudgetingPanelExpanded) => ({
        payload: { isBudgetingPanelExpanded },
      }),
    },
    setIsAllShiftsStatusesFilterDropdownOpen: {
      reducer: mergePayloadWithFacilityViewState,
      prepare: (isAllShiftsStatusesFilterDropdownOpen) => ({
        payload: { isAllShiftsStatusesFilterDropdownOpen },
      }),
    },
    setIsGroupsFilterDropdownOpen: {
      reducer: mergePayloadWithFacilityViewState,
      prepare: (isGroupsFilterDropdownOpen) => ({
        payload: { isGroupsFilterDropdownOpen },
      }),
    },
    setIsEmploymentStatusFilterDropdownOpen: {
      reducer: mergePayloadWithFacilityViewState,
      prepare: (isEmploymentStatusFilterDropdownOpen) => ({
        payload: { isEmploymentStatusFilterDropdownOpen },
      }),
    },
    setIsJaneStaffTypeFilterDropdownOpen: {
      reducer: mergePayloadWithFacilityViewState,
      prepare: (isJaneStaffTypeFilterDropdownOpen) => ({
        payload: { isJaneStaffTypeFilterDropdownOpen },
      }),
    },
    setIsTimeFrameStatusFilterDropdownOpen: {
      reducer: mergePayloadWithFacilityViewState,
      prepare: (isTimeFrameStatusFilterDropdownOpen) => ({
        payload: { isTimeFrameStatusFilterDropdownOpen },
      }),
    },
    toggleStatusFilterCheckbox: {
      reducer: (state, { payload }: PayloadAction<{ statusFilterOptionKey: string }>) => {
        const { selectedFacilityId, facilityViewState } = getPrevFacilityViewState(state);

        const prevSelectedShiftStatusFilters: string[] =
          facilityViewState.selectedShiftStatusFilters;

        const { statusFilterOptionKey } = payload;

        const selectedFilterOptionIndex = prevSelectedShiftStatusFilters.findIndex(
          (selectedStatusFilterKey) => statusFilterOptionKey === selectedStatusFilterKey,
        );

        const nextFacilityViewState: ScheduleFacilityViewState = {};

        if (selectedFilterOptionIndex === -1) {
          nextFacilityViewState.selectedShiftStatusFilters =
            prevSelectedShiftStatusFilters.concat(statusFilterOptionKey);
        } else {
          nextFacilityViewState.selectedShiftStatusFilters = prevSelectedShiftStatusFilters.filter(
            (selectedStatusFilterKey) => selectedStatusFilterKey !== statusFilterOptionKey,
          );
        }

        return persistScheduleState({
          ...state,
          [generateViewKey(selectedFacilityId)]: {
            ...facilityViewState,
            ...nextFacilityViewState,
            isShowAgencyShiftsOnlyFilterSelected: false,
            isShowConflictsOnlyFilterSelected: false,
          },
        });
      },
      prepare: (statusFilterOptionKey) => ({
        payload: { statusFilterOptionKey },
      }),
    },
    toggleGroupsFilterCheckbox: {
      reducer: (state, { payload }: PayloadAction<{ groupsFilterOptionKey: number }>) => {
        const { selectedFacilityId, facilityViewState } = getPrevFacilityViewState(state);

        const nextFacilityViewState: ScheduleFacilityViewState = {};
        nextFacilityViewState.selectedGroupsFilter = [];

        if (
          !!payload &&
          !facilityViewState.selectedGroupsFilter.includes(payload.groupsFilterOptionKey)
        ) {
          nextFacilityViewState.selectedGroupsFilter.push(payload.groupsFilterOptionKey);
        }

        return persistScheduleState({
          ...state,
          [generateViewKey(selectedFacilityId)]: {
            ...facilityViewState,
            ...nextFacilityViewState,
            isShowAgencyShiftsOnlyFilterSelected: false,
            isShowConflictsOnlyFilterSelected: false,
          },
        });
      },
      prepare: (groupsFilterOptionKey) => ({
        payload: { groupsFilterOptionKey },
      }),
    },
    toggleGroupsFilterName: {
      reducer: (state, { payload }: PayloadAction<{ groupsFilterName: string }>) => {
        const { selectedFacilityId, facilityViewState } = getPrevFacilityViewState(state);

        const nextFacilityViewState: ScheduleFacilityViewState = {};
        nextFacilityViewState.selectedGroupsFilterNames = [];

        if (
          !!payload &&
          !facilityViewState.selectedGroupsFilterNames.includes(payload.groupsFilterName)
        ) {
          nextFacilityViewState.selectedGroupsFilterNames.push(payload.groupsFilterName);
        }

        return persistScheduleState({
          ...state,
          [generateViewKey(selectedFacilityId)]: {
            ...facilityViewState,
            ...nextFacilityViewState,
            isShowAgencyShiftsOnlyFilterSelected: false,
            isShowConflictsOnlyFilterSelected: false,
          },
        });
      },
      prepare: (groupsFilterName) => ({
        payload: { groupsFilterName },
      }),
    },
    toggleEmploymentStatusFilterCheckbox: {
      reducer: (state, { payload }: PayloadAction<{ employmentStatusFilterOptionKey: string }>) => {
        const { selectedFacilityId, facilityViewState } = getPrevFacilityViewState(state);

        const prevSelectedEmploymentStatusFilters: string[] =
          facilityViewState.selectedEmploymentStatusFilter;

        const { employmentStatusFilterOptionKey } = payload;

        const selectedFilterOptionIndex = prevSelectedEmploymentStatusFilters.findIndex(
          (selectedEmploymentStatusFilterKey) =>
            employmentStatusFilterOptionKey === selectedEmploymentStatusFilterKey,
        );

        const nextFacilityViewState: ScheduleFacilityViewState = {};

        if (selectedFilterOptionIndex === -1) {
          nextFacilityViewState.selectedEmploymentStatusFilter =
            prevSelectedEmploymentStatusFilters.concat(employmentStatusFilterOptionKey);
        } else {
          nextFacilityViewState.selectedEmploymentStatusFilter =
            prevSelectedEmploymentStatusFilters.filter(
              (selectedEmploymentStatusFilterKey) =>
                selectedEmploymentStatusFilterKey !== employmentStatusFilterOptionKey,
            );
        }

        return persistScheduleState({
          ...state,
          [generateViewKey(selectedFacilityId)]: {
            ...facilityViewState,
            ...nextFacilityViewState,
            isShowAgencyShiftsOnlyFilterSelected: false,
            isShowConflictsOnlyFilterSelected: false,
          },
        });
      },
      prepare: (employmentStatusFilterOptionKey) => ({
        payload: { employmentStatusFilterOptionKey },
      }),
    },
    toggleJaneStaffTypeFilterCheckbox: {
      reducer: (state, { payload }: PayloadAction<{ janeStaffTypeOptionKey: string }>) => {
        const { selectedFacilityId, facilityViewState } = getPrevFacilityViewState(state);

        const prevJaneStaffTypeFilters: string[] = facilityViewState.selectedJaneStaffTypeFilter;

        const { janeStaffTypeOptionKey } = payload;

        const selectedFilterOptionIndex = prevJaneStaffTypeFilters.findIndex(
          (selectedJaneStaffTypeFilterKey) =>
            janeStaffTypeOptionKey === selectedJaneStaffTypeFilterKey,
        );

        const nextFacilityViewState: ScheduleFacilityViewState = {};

        if (selectedFilterOptionIndex === -1) {
          nextFacilityViewState.selectedJaneStaffTypeFilter =
            prevJaneStaffTypeFilters.concat(janeStaffTypeOptionKey);
        } else {
          nextFacilityViewState.selectedJaneStaffTypeFilter = prevJaneStaffTypeFilters.filter(
            (selectedJaneStaffTypeFilterKey) =>
              selectedJaneStaffTypeFilterKey !== janeStaffTypeOptionKey,
          );
        }

        return persistScheduleState({
          ...state,
          [generateViewKey(selectedFacilityId)]: {
            ...facilityViewState,
            ...nextFacilityViewState,
            isShowAgencyShiftsOnlyFilterSelected: false,
            isShowConflictsOnlyFilterSelected: false,
          },
        });
      },
      prepare: (janeStaffTypeOptionKey) => ({
        payload: { janeStaffTypeOptionKey },
      }),
    },
    toggleTimeFrameStatusFilterCheckbox: {
      reducer: (state, { payload }: PayloadAction<{ timeFrameStatusFilterOptionKey: string }>) => {
        const { selectedFacilityId, facilityViewState } = getPrevFacilityViewState(state);

        const prevSelectedTimeFrameStatusFilters: string[] =
          facilityViewState.selectedTimeFrameStatusFilter;

        const { timeFrameStatusFilterOptionKey } = payload;

        const selectedFilterOptionIndex = prevSelectedTimeFrameStatusFilters.findIndex(
          (selectedTimeFrameStatusFilterKey) =>
            timeFrameStatusFilterOptionKey === selectedTimeFrameStatusFilterKey,
        );

        const nextFacilityViewState: ScheduleFacilityViewState = {};

        if (selectedFilterOptionIndex === -1) {
          nextFacilityViewState.selectedTimeFrameStatusFilter =
            prevSelectedTimeFrameStatusFilters.concat(timeFrameStatusFilterOptionKey);
        } else {
          nextFacilityViewState.selectedTimeFrameStatusFilter =
            prevSelectedTimeFrameStatusFilters.filter(
              (selectedTimeFrameStatusFilterKey) =>
                selectedTimeFrameStatusFilterKey !== timeFrameStatusFilterOptionKey,
            );
        }

        return persistScheduleState({
          ...state,
          [generateViewKey(selectedFacilityId)]: {
            ...facilityViewState,
            ...nextFacilityViewState,
            isShowAgencyShiftsOnlyFilterSelected: false,
            isShowConflictsOnlyFilterSelected: false,
          },
        });
      },
      prepare: (timeFrameStatusFilterOptionKey) => ({
        payload: { timeFrameStatusFilterOptionKey },
      }),
    },
    selectAllShiftStatusFilters: {
      reducer: mergePayloadWithFacilityViewState,
      prepare: () => ({
        payload: {
          selectedShiftStatusFilters: shiftStatusOptions.map((shiftStatus) => shiftStatus.key),
          selectedEmploymentStatusFilter: [],
          selectedJaneStaffTypeFilter: [],
          selectedGroupsFilter: [],
          selectedGroupsFilterNames: [],
          isShowAgencyShiftsOnlyFilterSelected: false,
          isShowConflictsOnlyFilterSelected: false,
        },
      }),
    },
    selectAllTimeFrameFilters: {
      reducer: mergePayloadWithFacilityViewState,
      prepare: () => ({
        payload: {
          selectedTimeFrameStatusFilter: timeFrameOptions.map((shiftStatus) => shiftStatus.key),
        },
      }),
    },
    selectEmploymentStatusFilter: {
      reducer: mergePayloadWithFacilityViewState,
      prepare: () => ({
        payload: {
          selectedShiftStatusFilters: [],
          selectedEmploymentStatusFilter: employmentStatusOptions.map(
            (employmentStatus) => employmentStatus.key,
          ),
          selectedGroupsFilter: [],
          selectedGroupsFilterNames: [],
          selectedTimeFrameStatusFilter: [],
          isShowAgencyShiftsOnlyFilterSelected: false,
          isShowConflictsOnlyFilterSelected: false,
        },
      }),
    },
    selectJaneStaffTypeFilter: {
      reducer: mergePayloadWithFacilityViewState,
      prepare: () => ({
        payload: {
          selectedShiftStatusFilters: [],
          selectedEmploymentStatusFilter: [],
          selectedJaneStaffTypeFilter: janeStaffTypeOptions.map(
            (janeStaffType) => janeStaffType.key,
          ),
          selectedGroupsFilter: [],
          selectedGroupsFilterNames: [],
          selectedTimeFrameStatusFilter: [],
          isShowAgencyShiftsOnlyFilterSelected: false,
          isShowConflictsOnlyFilterSelected: false,
        },
      }),
    },
    selectGroupsFilter: {
      reducer: mergePayloadWithFacilityViewState,
      prepare: () => ({
        payload: {
          selectedShiftStatusFilters: [],
          selectedEmploymentStatusFilter: [],
          selectedJaneStaffTypeFilter: [],
          selectedGroupsFilter: [],
          selectedTimeFrameStatusFilter: [],
          selectedGroupsFilterNames: [],
          isShowAgencyShiftsOnlyFilterSelected: false,
          isShowConflictsOnlyFilterSelected: false,
        },
      }),
    },
    setIsShowAgencyShiftsOnlyFilterSelected: {
      reducer: mergePayloadWithFacilityViewState,
      prepare: (isShowAgencyShiftsOnlyFilterSelected) => ({
        payload: {
          selectedShiftStatusFilters: [],
          selectedEmploymentStatusFilter: [],
          selectedJaneStaffTypeFilter: [],
          selectedGroupsFilter: [],
          selectedTimeFrameStatusFilter: [],
          selectedGroupsFilterNames: [],
          isShowAgencyShiftsOnlyFilterSelected,
          isShowConflictsOnlyFilterSelected: false,
        },
      }),
    },
    setIsShowConflictsOnlyFilterSelected: {
      reducer: mergePayloadWithFacilityViewState,
      prepare: (isShowConflictsOnlyFilterSelected) => ({
        payload: {
          selectedShiftStatusFilters: [],
          selectedEmploymentStatusFilter: [],
          selectedJaneStaffTypeFilter: [],
          selectedGroupsFilter: [],
          selectedGroupsFilterNames: [],
          selectedTimeFrameStatusFilter: [],
          isShowAgencyShiftsOnlyFilterSelected: false,
          isShowConflictsOnlyFilterSelected,
        },
      }),
    },
    resetFilters: {
      reducer: mergePayloadWithFacilityViewState,
      prepare: () => ({
        payload: {
          selectedShiftStatusFilters: [],
          selectedEmploymentStatusFilter: [],
          selectedJaneStaffTypeFilter: [],
          selectedGroupsFilter: [],
          selectedGroupsFilterNames: [],
          isShowAgencyShiftsOnlyFilterSelected: false,
          isShowConflictsOnlyFilterSelected: false,
          selectedTimeFrameStatusFilter: [],
        },
      }),
    },
    clearTimeFrameFilter: {
      reducer: mergePayloadWithFacilityViewState,
      prepare: () => ({
        payload: {
          selectedTimeFrameStatusFilter: [],
        },
      }),
    },
    setSelectedFacilityId: {
      reducer: (
        state,
        { payload: { selectedFacilityId } }: PayloadAction<{ selectedFacilityId: number }>,
      ) => {
        const key = generateViewKey(selectedFacilityId);
        return persistScheduleState({
          ...state,
          copyParams: initialCopyParamsState,
          selectedFacilityId,
          [key]: state[key] || initialFacilityViewState,
        });
      },
      prepare: (selectedFacilityId) => ({ payload: { selectedFacilityId } }),
    },
    setCreateShiftPopup: {
      reducer: mergePayloadWithState,
      prepare: (createShiftPopup) => ({ payload: { createShiftPopup } }),
    },
    resetCreateShiftPopup: {
      reducer: mergePayloadWithState,
      prepare: () => ({
        payload: { createShiftPopup: initialCreateShiftState },
      }),
    },
    setShiftModal: {
      reducer: mergePayloadWithState,
      prepare: (shiftSideModal) => ({ payload: { shiftSideModal } }),
    },
    setIsFirstTime: {
      reducer: mergePayloadWithFacilityViewState,
      prepare: (isFirstTime) => ({ payload: { isFirstTime } }),
    },
    setIsClearDraftsModalOpen: {
      reducer: mergePayloadWithFacilityViewState,
      prepare: (isClearDraftsModalOpen) => ({
        payload: { isClearDraftsModalOpen },
      }),
    },
    setDeletingTemplate: {
      reducer: mergePayloadWithState,
      prepare: (deletingTemplate) => ({
        payload: { deletingTemplate },
      }),
    },
    setClearingTemplate: {
      reducer: mergePayloadWithState,
      prepare: (clearingTemplate) => ({
        payload: { clearingTemplate },
      }),
    },
    setPublishModal: {
      reducer: mergePayloadWithFacilityViewState,
      prepare: (isPublishModalOpen) => ({ payload: { isPublishModalOpen } }),
    },
    setLegendModal: {
      reducer: mergePayloadWithFacilityViewState,
      prepare: (isLegendModalOpen) => ({ payload: { isLegendModalOpen } }),
    },
    setShiftNoteVisibilty: {
      reducer: mergePayloadWithFacilityViewState,
      prepare: (isShiftNoteVisible) => ({ payload: { isShiftNoteVisible } }),
    },
    setPDFModal: {
      reducer: mergePayloadWithState,
      prepare: (isPDFModalVisible, exportOption = 'pdf') => ({
        payload: { shiftPDFModal: { isPDFModalVisible, exportOption } },
      }),
    },
    setStateAuditPDFModal: {
      reducer: mergePayloadWithState,
      prepare: (isPDFModalVisible, exportOption = 'pdf') => ({
        payload: { stateAuditPDFModal: { isPDFModalVisible, exportOption } },
      }),
    },
    resetShiftModal: {
      reducer: mergePayloadWithState,
      prepare: () => ({
        payload: { shiftSideModal: initialSideModalState },
      }),
    },
    setCopyParams: {
      reducer: mergePayloadWithState,
      prepare: (_) => {
        const copyParams = { jane_id: null, ..._ };
        assertKeys(copyParams, ['start_time', 'end_time']);
        toastService.success(i18next.t('success:SHIFT_COPIED'));
        return { payload: { copyParams } };
      },
    },
    setSelectedJaneIds: {
      reducer: (state, action: PayloadAction<{ selectedJaneIds: number[] }>) => {
        const { selectedFacilityId, facilityViewState } = getPrevFacilityViewState(state);

        return persistScheduleState({
          ...state,
          [generateViewKey(selectedFacilityId)]: {
            ...facilityViewState,
            searchedMembers: {
              ...state[generateViewKey(selectedFacilityId)].searchedMembers,
              selectedJaneIds: action.payload.selectedJaneIds,
            },
          },
        });
      },
      prepare: (selectedJaneIds) => ({
        payload: { selectedJaneIds },
      }),
    },
    setIsHighOpenShiftCountTipOpen: {
      reducer: mergePayloadWithState,
      prepare: (isHighOpenShiftCountTipOpen) => ({
        payload: { isHighOpenShiftCountTipOpen },
      }),
    },
    setPublishPositionCodes: {
      reducer: (state, action: PayloadAction<{ publishPositionCodes: string[] }>) => {
        const { selectedFacilityId, facilityViewState } = getPrevFacilityViewState(state);

        return {
          ...state,
          [generateViewKey(selectedFacilityId)]: {
            ...facilityViewState,
            publishPositionCodes: action.payload.publishPositionCodes,
          },
        };
      },
      prepare: (publishPositionCodes) => ({
        payload: { publishPositionCodes },
      }),
    },
  },
});

export const useScheduleActions = () => bindActionCreators(scheduleSlice.actions, useDispatch());

export const scheduleReducer = scheduleSlice.reducer;
