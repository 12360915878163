import { Modifier } from '@apollo/client/cache/core/types/common';
import { DropdownWidth } from 'components/GroupsModal/components/SearchTeamMembersGroups/SearchTeamMembersGroups.styled';

export const searchTeamMembersGroupsPopperModifiers:
  | readonly Partial<Modifier<unknown | object>>[]
  | undefined = [
  {
    name: 'sameWidth',
    enabled: true,
    phase: 'beforeWrite',
    requires: ['computeStyles'],
    fn({
      state,
    }: {
      state: {
        rects: { reference: { width: string } };
        styles: { popper: { minWidth: string; maxWidth: string } };
      };
    }): void {
      state.styles.popper.minWidth = `${DropdownWidth}`;
      state.styles.popper.maxWidth = `${DropdownWidth}`;
    },
    effect({
      state,
    }: {
      state: {
        elements: {
          reference: { offsetWidth: string };
          popper: { style: { minWidth: string; maxWidth: string } };
        };
      };
    }): void {
      state.elements.popper.style.minWidth = `${DropdownWidth}`;
      state.elements.popper.style.maxWidth = `${DropdownWidth}`;
    },
  },
];
