import QUERY_agencyFilter from 'queries/QUERY_agencyFilter.graphql';
import QUERY_agencyMemberPage from 'queries/QUERY_agencyMemberPage.graphql';
import QUERY_availableTeamMembers from 'queries/QUERY_availableTeamMembers.graphql';
import QUERY_communityCustomFields from 'queries/QUERY_communityCustomFields.graphql';
import QUERY_companyProfilePage from 'queries/QUERY_companyProfilePage.graphql';
import QUERY_createCustomFieldFetchCommunities from 'queries/QUERY_createCustomFieldFetchCommunities.graphql';
import QUERY_createShiftModalRequestTeamMember from 'queries/QUERY_createShiftModalRequestTeamMember.graphql';
import QUERY_createShiftModalRequestTeamMemberRecurring from 'queries/QUERY_createShiftModalRequestTeamMemberRecurring.graphql';
import QUERY_customFieldsSettingsFetchCustomFields from 'queries/QUERY_customFieldsSettingsFetchCustomFields.graphql';
import QUERY_customFieldsSettingsFetchFacilitiesCount from 'queries/QUERY_customFieldsSettingsFetchFacilitiesCount.graphql';
import QUERY_dashboardAvailableShiftsPage from 'queries/QUERY_dashboardAvailableShiftsPage.graphql';
import QUERY_dashboardPastShiftsPage from 'queries/QUERY_dashboardPastShiftsPage.graphql';
import QUERY_fetchCommunityBiddingPeriod from 'queries/QUERY_fetchCommunityBiddingPeriod.graphql';
import QUERY_dashboardRecentlyCreatedShiftsPage from 'queries/QUERY_dashboardRecentlyCreatedShiftsPage.graphql';
import QUERY_dashboardUpcomingShiftsPage from 'queries/QUERY_dashboardUpcomingShiftsPage.graphql';
import QUERY_editCustomFieldFetchCustomFieldById from 'queries/QUERY_editCustomFieldFetchCustomFieldById.graphql';
import QUERY_fetchAgencyCommunities from 'queries/QUERY_fetchAgencyCommunities.graphql';
import QUERY_fetchCommunities from 'queries/QUERY_fetchCommunities.graphql';
import QUERY_fetchSecondaryCommunities from 'queries/QUERY_fetchSecondaryCommunities.graphql';
import QUERY_fetchCommunitiesPaginated from 'queries/QUERY_fetchCommunitiesPaginated.graphql';
import QUERY_locationNameLabel from 'queries/QUERY_locationNameLabel.graphql';
import QUERY_positionByDepartmentSelectInput from 'queries/QUERY_positionByDepartmentSelectInput.graphql';
import QUERY_positionNameLabel from 'queries/QUERY_positionNameLabel.graphql';
import QUERY_positionsByDepartment from 'queries/QUERY_positionsByDepartment.graphql';
import QUERY_positionsPriceRanges from 'queries/QUERY_positionsPriceRanges.graphql';
import QUERY_requestTeamMembers from 'queries/QUERY_requestTeamMembers.graphql';
import QUERY_requestTeamMembersCount from 'queries/QUERY_requestTeamMembersCount.graphql';
import QUERY_requestTeamMembersRecurring from 'queries/QUERY_requestTeamMembersRecurring.graphql';
import QUERY_schedulingPage from 'queries/QUERY_schedulingPage.graphql';
import QUERY_schedulingTabNavigationInput from 'queries/QUERY_schedulingTabNavigationInput.graphql';
import QUERY_searchTeamMembers from 'queries/QUERY_searchTeamMembers.graphql';
import QUERY_sendToAgenciesOrderDetails from 'queries/QUERY_sendToAgenciesOrderDetails.graphql';
import QUERY_sendToAgenciesPositionMinMax from 'queries/QUERY_sendToAgenciesPositionMinMax.graphql';
import QUERY_sendToAgenciesSummaryView from 'queries/QUERY_sendToAgenciesSummaryView.graphql';
import QUERY_settingsShiftDistributionPage from 'queries/QUERY_settingsShiftDistributionPage.graphql';
import QUERY_shiftCardFetchOrder from 'queries/QUERY_shiftCardFetchOrder.graphql';
import QUERY_shiftModalOrderDetails from 'queries/QUERY_shiftModalOrderDetails.graphql';
import QUERY_shiftModalTimeSheetDetails from 'queries/QUERY_shiftModalTimeSheetDetails.graphql';
import QUERY_shiftsCalendarPage from 'queries/QUERY_shiftsCalendarPage.graphql';
import QUERY_shiftsCalendarPageDaySelection from 'queries/QUERY_shiftsCalendarPageDaySelection.graphql';
import QUERY_shiftsListPage from 'queries/QUERY_shiftsListPage.graphql';
import QUERY_shiftSummaryViewTeamMembers from 'queries/QUERY_shiftSummaryViewTeamMembers.graphql';
import QUERY_teamMemberPage from 'queries/QUERY_teamMemberPage.graphql';
import QUERY_teamMembersCount from 'queries/QUERY_teamMembersCount.graphql';
import QUERY_timesheetCard from 'queries/QUERY_timesheetCard.graphql';
import QUERY_timesheetsPage from 'queries/QUERY_timesheetsPage.graphql';
import QUERY_shiftSummaryJaneRowFetchJane from 'queries/QUERY_shiftSummaryJaneRowFetchJane.graphql';
import QUERY_timeOffFetchJane from 'queries/QUERY_timeOffFetchJane.graphql';
import QUERY_fetchTimeOffCustomFields from 'queries/QUERY_fetchTimeOffCustomFields.graphql';
import QUERY_janeModalJaneDetails from 'queries/QUERY_janeModalJaneDetails.graphql';
import QUERY_timeOffModalFetchTimeOffById from 'queries/QUERY_timeOffModalFetchTimeOffById.graphql';
import QUERY_scheduleTimeOffs from 'queries/QUERY_scheduleTimeOffs.graphql';
import QUERY_locationDropdownData from 'queries/QUERY_locationDropdownData.graphql';
import QUERY_positionsSettingsPageFetchPositions from 'queries/QUERY_positionsSettingsPageFetchPositions.graphql';
import QUERY_positionsSettingsPageFetchCommunityById from 'queries/QUERY_positionsSettingsPageFetchCommunityById.graphql';
import QUERY_generalLocationSettingsPage from 'queries/QUERY_generalLocationSettingsPage.graphql';
import QUERY_generalAgencyLocationSettingsPage from 'queries/QUERY_generalAgencyLocationSettingsPage.graphql';
import QUERY_assignToAgencyTeamMemberFetchAgencyJanes from 'queries/QUERY_assignToAgencyTeamMemberFetchAgencyJanes.graphql';
import QUERY_assignToAgencyTeamMemberFetchAgencyJanesCount from 'queries/QUERY_assignToAgencyTeamMemberFetchAgencyJanesCount.graphql';
import QUERY_assignToAgencyTeamMemberFetchAgencyJaneById from 'queries/QUERY_assignToAgencyTeamMemberFetchAgencyJaneById.graphql';
import QUERY_assignToAgencyTeamMemberFetchAgencyJaneAvailabilityByJaneIdOrderId from 'queries/QUERY_assignToAgencyTeamMemberFetchAgencyJaneAvailabilityByJaneIdOrderId.graphql';
import QUERY_b2bDepartmentsNavigationFetchDepartments from 'queries/QUERY_b2bDepartmentsNavigationFetchDepartments.graphql';
import QUERY_b2bPositionsSettingsPageFetchPositionsByDepartmentId from 'queries/QUERY_b2bPositionsSettingsPageFetchPositionsByDepartmentId.graphql';
import QUERY_fetchOrderCanAssign from 'queries/QUERY_fetchOrderCanAssign.graphql';
import QUERY_sendToAgenciesRequestTeamMemberSelectedJanePriceRange from 'queries/QUERY_sendToAgenciesRequestTeamMemberSelectedJanePriceRange.graphql';
import QUERY_sendToAgenciesSummaryViewTeamMembersCount from 'queries/QUERY_sendToAgenciesSummaryViewTeamMembersCount.graphql';
import QUERY_sendToSelectedAgenciesFetchAgenciesByOrderId from 'queries/QUERY_sendToSelectedAgenciesFetchAgenciesByOrderId.graphql';
import QUERY_sendToSelectedAgenciesFetchAgenciesByOrderIdNonPaginated from 'queries/QUERY_sendToSelectedAgenciesFetchAgenciesByOrderIdNonPaginated.graphql';
import QUERY_shiftModalRequestSelectAgencies from 'queries/QUERY_shiftModalRequestSelectAgencies.graphql';
import QUERY_availableShiftsMapView from 'queries/QUERY_availableShiftsMapView.graphql';
import QUERY_dashboardMyUpcomingShiftsPage from 'queries/QUERY_dashboardMyUpcomingShiftsPage.graphql';
import QUERY_fetchTeamMemberById from 'queries/QUERY_fetchTeamMemberById.graphql';
import QUERY_fetchMe from 'queries/QUERY_fetchMe.graphql';
import QUERY_shiftDistributionRulesGroups from 'queries/QUERY_shiftDistributionRulesGroups.graphql';
import QUERY_shiftDistributionRulesTieringDetails from 'queries/QUERY_shiftDistributionRulesTieringDetails.graphql';
import QUERY_shiftDistributionRulesSharingDetails from 'queries/QUERY_shiftDistributionRulesSharingDetails.graphql';
import QUERY_shiftModalOrderDetailsCalloutList from 'queries/QUERY_shiftModalOrderDetailsCalloutList.graphql';
import QUERY_settingsGroups from 'queries/QUERY_settingsGroups.graphql';
import QUERY_settingsGroupDetails from 'queries/QUERY_settingsGroupDetails.graphql';
import QUERY_schedulingGroups from 'queries/QUERY_schedulingGroups.graphql';
import QUERY_communityGroups from 'queries/QUERY_communityGroups.graphql';
import QUERY_userProfilePage from 'queries/QUERY_userProfilePage.graphql';
import QUERY_fetchUserLocale from 'queries/QUERY_fetchUserLocale.graphql';
import QUERY_searchTeamMembersForGroup from 'queries/QUERY_searchTeamMembersForGroup.graphql';
import QUERY_teamMembersPageFetchJanes from 'queries/QUERY_teamMembersPageFetchJanes.graphql';
import QUERY_fetchDynamicGroupCriteriaEmploymentStatuses from 'queries/QUERY_fetchDynamicGroupCriteriaEmploymentStatuses.graphql';
import QUERY_timeFrameLocationSettingsPage from 'queries/QUERY_timeFrameLocationSettingsPage.graphql';
import QUERY_communityTimeFrames from 'queries/QUERY_communityTimeFrames.graphql';

import QUERY_fetchOrderBidders from 'queries/QUERY_fetchOrderBidders.graphql';
import QUERY_communityBudgetAllocationsForPosition from 'queries/QUERY_communityBudgetAllocationsForPosition.graphql';
export {
  QUERY_availableShiftsMapView,
  QUERY_agencyFilter,
  QUERY_agencyMemberPage,
  QUERY_availableTeamMembers,
  QUERY_companyProfilePage,
  QUERY_createShiftModalRequestTeamMember,
  QUERY_createShiftModalRequestTeamMemberRecurring,
  QUERY_dashboardPastShiftsPage,
  QUERY_fetchCommunityBiddingPeriod,
  QUERY_dashboardRecentlyCreatedShiftsPage,
  QUERY_dashboardUpcomingShiftsPage,
  QUERY_fetchAgencyCommunities,
  QUERY_fetchCommunities,
  QUERY_fetchCommunitiesPaginated,
  QUERY_fetchSecondaryCommunities,
  QUERY_locationNameLabel,
  QUERY_positionByDepartmentSelectInput,
  QUERY_positionNameLabel,
  QUERY_positionsByDepartment,
  QUERY_positionsPriceRanges,
  QUERY_requestTeamMembers,
  QUERY_requestTeamMembersCount,
  QUERY_requestTeamMembersRecurring,
  QUERY_schedulingPage,
  QUERY_searchTeamMembers,
  QUERY_sendToAgenciesOrderDetails,
  QUERY_sendToAgenciesPositionMinMax,
  QUERY_sendToAgenciesSummaryView,
  QUERY_shiftCardFetchOrder,
  QUERY_settingsShiftDistributionPage,
  QUERY_shiftModalOrderDetails,
  QUERY_shiftModalTimeSheetDetails,
  QUERY_shiftsCalendarPage,
  QUERY_shiftsCalendarPageDaySelection,
  QUERY_shiftsListPage,
  QUERY_shiftSummaryViewTeamMembers,
  QUERY_teamMemberPage,
  QUERY_teamMembersCount,
  QUERY_schedulingTabNavigationInput,
  QUERY_timesheetCard,
  QUERY_timesheetsPage,
  QUERY_shiftSummaryJaneRowFetchJane,
  QUERY_customFieldsSettingsFetchCustomFields,
  QUERY_customFieldsSettingsFetchFacilitiesCount,
  QUERY_communityCustomFields,
  QUERY_createCustomFieldFetchCommunities,
  QUERY_editCustomFieldFetchCustomFieldById,
  QUERY_timeOffFetchJane,
  QUERY_fetchTimeOffCustomFields,
  QUERY_janeModalJaneDetails,
  QUERY_timeOffModalFetchTimeOffById,
  QUERY_scheduleTimeOffs,
  QUERY_locationDropdownData,
  QUERY_positionsSettingsPageFetchPositions,
  QUERY_positionsSettingsPageFetchCommunityById,
  QUERY_generalLocationSettingsPage,
  QUERY_generalAgencyLocationSettingsPage,
  QUERY_assignToAgencyTeamMemberFetchAgencyJanes,
  QUERY_assignToAgencyTeamMemberFetchAgencyJanesCount,
  QUERY_assignToAgencyTeamMemberFetchAgencyJaneById,
  QUERY_assignToAgencyTeamMemberFetchAgencyJaneAvailabilityByJaneIdOrderId,
  QUERY_b2bDepartmentsNavigationFetchDepartments,
  QUERY_b2bPositionsSettingsPageFetchPositionsByDepartmentId,
  QUERY_fetchOrderCanAssign,
  QUERY_sendToAgenciesRequestTeamMemberSelectedJanePriceRange,
  QUERY_sendToAgenciesSummaryViewTeamMembersCount,
  QUERY_sendToSelectedAgenciesFetchAgenciesByOrderId,
  QUERY_sendToSelectedAgenciesFetchAgenciesByOrderIdNonPaginated,
  QUERY_shiftModalRequestSelectAgencies,
  QUERY_dashboardAvailableShiftsPage,
  QUERY_dashboardMyUpcomingShiftsPage,
  QUERY_fetchTeamMemberById,
  QUERY_fetchMe,
  QUERY_shiftDistributionRulesGroups,
  QUERY_shiftDistributionRulesTieringDetails,
  QUERY_shiftDistributionRulesSharingDetails,
  QUERY_shiftModalOrderDetailsCalloutList,
  QUERY_settingsGroups,
  QUERY_settingsGroupDetails,
  QUERY_schedulingGroups,
  QUERY_communityGroups,
  QUERY_userProfilePage,
  QUERY_fetchUserLocale,
  QUERY_searchTeamMembersForGroup,
  QUERY_teamMembersPageFetchJanes,
  QUERY_fetchDynamicGroupCriteriaEmploymentStatuses,
  QUERY_timeFrameLocationSettingsPage,
  QUERY_communityTimeFrames,
  QUERY_fetchOrderBidders,
  QUERY_communityBudgetAllocationsForPosition,
};
