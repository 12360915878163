import { ShiftAction } from 'components/RenderShiftActions/RenderShiftActions.types';
import { FEATURE_SELECTORS_MAP } from 'guards/FeatureGuard.constants';
import { useTranslation } from 'hooks/useTranslation';
import { SetStateAction, MouseEvent, useMemo } from 'react';
import { useSelector } from 'react-redux';
import schedulingReadAccessFacilityIdsSelector from 'store/selectors/scheduleSelectors/schedulingReadAccessFacilityIdsSelector';
import {
  OrderStatusEnum,
  shiftCardFetchOrder_fetchOrder,
  shiftCardFetchOrder_fetchOrder_review,
  shiftPageDaySelection_fetchOrders_edges_node,
} from 'types/graphql-types';
import { getShiftBiddingStatus } from 'utils/shiftStatus';

export type CallOutShiftStatus = 'IN_PROGRESS' | 'PAUSED' | 'COMPLETE';

type BuildActionsForShiftCardParams = {
  order:
    | shiftCardFetchOrder_fetchOrder
    | (shiftPageDaySelection_fetchOrders_edges_node & {
        review?: shiftCardFetchOrder_fetchOrder_review | null;
      });
  setIsContinueCallOutConfirmationOpen: (value: SetStateAction<boolean>) => void;
  setIsAllowOvertimeConfirmationOpen: (value: SetStateAction<boolean>) => void;
  goToAwardTeamMemberFlow: (e: MouseEvent) => void;
  openSendToAgenciesFlow: (e: MouseEvent) => void;
};

type BuildActionsForShiftCardResult = {
  shiftActions: ShiftAction[];
  isShiftInfoWrapperVisible: boolean;
  callOutStatus: CallOutShiftStatus;
};

function createShiftAction(label: string, action: (e: unknown) => void): ShiftAction {
  return {
    label,
    action,
  };
}

export function useBuildActionsForShiftCard({
  order,
  setIsContinueCallOutConfirmationOpen,
  setIsAllowOvertimeConfirmationOpen,
  goToAwardTeamMemberFlow,
  openSendToAgenciesFlow,
}: BuildActionsForShiftCardParams): BuildActionsForShiftCardResult {
  const {
    allowOvertime,
    canSendToAgency,
    community,
    currentlyActiveCalloutList,
    isReviewAllowed,
    recurrence,
    review,
    sentToAgencies,
    status,
    biddable,
    bidders,
    biddingTimeEndsAt,
  } = order;

  const { t } = useTranslation();

  const isReadOnly = useSelector(
    FEATURE_SELECTORS_MAP['FEATURE_TYPE_YMCA_READ_ONLY_SECONDARY_USER'],
  );

  const locationsWithScheduling = useSelector(schedulingReadAccessFacilityIdsSelector);
  const isSchedulingEnabled = useMemo(
    () => locationsWithScheduling.includes(Number(community.id)),
    [community.id, locationsWithScheduling],
  );

  const isCalloutPaused = useMemo(
    () =>
      currentlyActiveCalloutList?.waitingForManualApproval &&
      !currentlyActiveCalloutList?.isCompleted &&
      status.value === OrderStatusEnum.pending,
    [
      currentlyActiveCalloutList?.isCompleted,
      currentlyActiveCalloutList?.waitingForManualApproval,
      status.value,
    ],
  );

  const isCalloutComplete = useMemo(
    () => currentlyActiveCalloutList?.isCompleted && status.value === OrderStatusEnum.pending,
    [currentlyActiveCalloutList?.isCompleted, status.value],
  );

  const isAllowOvertimeMenuItem =
    !recurrence &&
    !allowOvertime &&
    !biddable &&
    status.value === OrderStatusEnum.pending &&
    isSchedulingEnabled;

  const biddingStatus = getShiftBiddingStatus({
    status: status.value,
    biddable: biddable,
    biddingTimeEndsAt: biddingTimeEndsAt,
    bidders: bidders,
  });

  const isShiftInfoWrapperVisible =
    !isReadOnly &&
    (!!isReviewAllowed ||
      !!canSendToAgency ||
      !!isCalloutPaused ||
      !!isAllowOvertimeMenuItem ||
      !!review?.rating ||
      biddingStatus === 'AWARDING');

  const actions: ShiftAction[] = [];
  if (!!isCalloutPaused)
    actions.push(
      createShiftAction(t('dashboard:CONTINUE_CALL_OUT'), () =>
        setIsContinueCallOutConfirmationOpen(true),
      ),
    );
  if (!!canSendToAgency && !sentToAgencies && !biddable)
    actions.push(
      createShiftAction(
        t('schedule:SEND_TO_AGENCY'),
        openSendToAgenciesFlow as (e: unknown) => void,
      ),
    );
  if (biddingStatus === 'AWARDING')
    actions.push(
      createShiftAction(
        t('dashboard:AWARD_SHIFT'),
        goToAwardTeamMemberFlow as (e: unknown) => void,
      ),
    );
  if (!!isAllowOvertimeMenuItem)
    actions.push(
      createShiftAction(t('dashboard:ALLOW_OVERTIME'), () =>
        setIsAllowOvertimeConfirmationOpen(true),
      ),
    );
  return {
    shiftActions: actions,
    isShiftInfoWrapperVisible,
    callOutStatus: isCalloutPaused ? 'PAUSED' : isCalloutComplete ? 'COMPLETE' : 'IN_PROGRESS',
  };
}
