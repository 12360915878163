import { BKJThemeColorType } from '@bookjane2/bookjane-design-library';
import { OrderStatusEnum } from 'types/graphql-types';

export type ShiftStatusCodeType = keyof typeof OrderStatusEnum;

export type ShiftPrettyStatusType =
  | 'Open'
  | 'Requested'
  | 'Accepted'
  | 'Assigned'
  | 'Cancelled'
  | 'Unfulfilled'
  | 'In Progress'
  | 'Completed'
  | 'Swapped';

// design library change required
export const shiftStatusMap: Record<keyof typeof OrderStatusEnum | 'open', BKJThemeColorType> = {
  accepted: 'ShiftAccepted',
  assigned: 'ShiftAssigned',
  cancelled: 'ShiftCancelled',
  completed: 'ShiftCompleted',
  in_progress: 'ShiftInProgress',
  open: 'ShiftOpen',
  pending: 'ShiftPending',
  rejected: 'ShiftRejected',
  requested: 'ShiftRequested',
  unfulfilled: 'ShiftUnfulfilledStroke',
  swapped: 'ShiftSwapped',
};
