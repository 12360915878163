import { useQueryFormState } from 'hooks/useQueryForm/useQueryForm.reducer';
import { IQueryFormResult, IQueryOptions } from 'hooks/useQueryForm/useQueryForm.types';
import { transform } from 'hooks/useQueryForm/useQueryForm.utils';

export function useQueryForm<IData, IVariables, ITransformedData, ITransformedPayload>(
  queryOptions: IQueryOptions<IData, IVariables, ITransformedData, ITransformedPayload>,
  initialVariables: IVariables,
): IQueryFormResult<IData, IVariables, ITransformedData, ITransformedPayload> {
  const {
    key,

    query,
    responseTransformer = transform,
    payloadTransformer = transform,
    pageSize = 10,
  } = queryOptions;

  const [state, actions] = useQueryFormState<
    IData,
    IVariables,
    ITransformedData,
    ITransformedPayload
  >({ key, query, payloadTransformer, responseTransformer, initialVariables, pageSize });

  const variables = state.variables;

  return {
    transformedData: responseTransformer(state.data as any),
    data: state.data,
    pageInfo: state.pageInfo,
    values: variables,
    isLoading: state.isLoading,
    __typename: 'GraphQLAPI',
    context: 'GraphQLAPI',
    onChange: actions.onChange,
    loadMore: actions.onNextPage,
    status: state.isLoading ? 'LOADING' : 'READY',
  };
}
