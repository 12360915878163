import { InMemoryCache } from '@apollo/client';
import { ChangeEventType } from '@bookjane2/bookjane-design-library/lib/common.types';
import { ILocationFilterProps } from 'components/LocationFilterInput/LocationFilterInput.types';
import { useGQLQuery } from 'hooks';
import { FormErrorsType } from 'hooks/useGroupedFormsBehaviors/useGroupedFormsBehaviors.types';
import {
  fieldsThatAffectRequestedTeamMembers,
  positionsPriceRangesQueryConfig,
  teamMembersQueryCountConfig,
} from 'pages/CreateShiftsPage/BulkCreateShiftView/CreateShiftCard/CreateShiftCard.constants';
import {
  ICreateShiftCardContext,
  PositionsPriceRangesType,
} from 'pages/CreateShiftsPage/BulkCreateShiftView/CreateShiftCard/CreateShiftCard.types';
import {
  doesPositionHaveDedicatedPriceRange,
  getPositionDetails,
} from 'pages/CreateShiftsPage/BulkCreateShiftView/CreateShiftCard/CreateShiftCard.utils';
import { CreateShiftModalViewType } from 'pages/CreateShiftsPage/BulkCreateShiftView/CreateShiftModal/CreateShiftModal.types';
import { CreateShiftsPageContext } from 'pages/CreateShiftsPage/CreateShiftsPage.context';
import { ICreateShift } from 'pages/CreateShiftsPage/CreateShiftsPage.types';
import { getInitialCreateShiftForm } from 'pages/CreateShiftsPage/CreateShiftsPage.utils';
import { createApolloClient } from 'providers/ApolloProvider';
import { createContext, FC, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IFieldErrors } from 'types/common.types';
import {
  positionsPriceRanges,
  positionsPriceRangesVariables,
  teamMemberCount,
  teamMemberCountVariables,
} from 'types/graphql-types';
import { getShiftStartTimeAndEndTime } from 'utils/time';
import { withMemo } from 'utils/withMemo';
import schedulingReadAccessFacilityIdsSelector from 'store/selectors/scheduleSelectors/schedulingReadAccessFacilityIdsSelector';
import { createShiftsPageCommunityCustomFields } from 'pages/CreateShiftsPage/CreateShiftsPage.constants';

const createShiftCardApolloClient = createApolloClient({
  cache: new InMemoryCache(),
});

export const CreateShiftCardContext = createContext<ICreateShiftCardContext>({
  modalView: null,
  goToModalView: () => null,
  createShift: getInitialCreateShiftForm({}),
  communityCustomFields: [],
  cancelAndCloseModal: () => null,
  saveAndCloseModal: () => null,
  fieldErrors: {},
  teamMembersCount: 0,
  formErrors: [],
  isSchedulingEnabled: false,
  location: undefined as unknown as ILocationFilterProps['value'],
  handleChange: () => null,
  isFetchTeamMembersCountLoading: false,
  hasDedicatedTeam: false,
  isEditShiftDetailsDialogOpen: false,
  handleEditShiftDialogAction: () => null,
  handleEditShiftDialogCancel: () => null,
});

export const CreateShiftCardProvider: FC<{
  createShift: ICreateShift;
  children: ReactNode;
  fieldErrors: IFieldErrors;
  formErrors: FormErrorsType;
}> = withMemo(
  ({
    createShift,
    children,
    fieldErrors,
    formErrors,
  }: {
    createShift: ICreateShift;
    children: ReactNode;
    fieldErrors: IFieldErrors;
    formErrors: FormErrorsType;
  }): JSX.Element => {
    const { groupedFormsBehaviors } = useContext(CreateShiftsPageContext);
    const groupValues = groupedFormsBehaviors.values;
    const { location } = groupValues;

    const locationValue: string = location.value;
    const [modalView, setModalView] = useState<CreateShiftModalViewType>(null);
    const [isEditShiftDetailsDialogOpen, setIsEditShiftDetailsDialogOpen] =
      useState<boolean>(false);
    const [pendingConfirmationEvent, setPendingConfirmationEvent] = useState<ChangeEventType>(null);
    const { onChange, values } = createShift;
    const {
      position_id,
      dedicated = false,
      startAt,
      endAt,
      startDate,
      allow_overtime = false,
    } = values;
    const [start_time, end_time] = getShiftStartTimeAndEndTime({
      startAt,
      endAt,
      startDate,
    });

    const goToModalView = (view: CreateShiftModalViewType) => {
      setModalView(view);
    };

    const locationsWithScheduling = useSelector(schedulingReadAccessFacilityIdsSelector);
    const isSchedulingEnabled = locationsWithScheduling.includes(Number(location.value));

    const {
      data: teamMembersCountData,
      fetch: fetchTeamMemberCount,
      isLoading: isFetchTeamMembersCountLoading,
    } = useGQLQuery<teamMemberCount, number, teamMemberCountVariables>({
      ...teamMembersQueryCountConfig({
        byFacilityId: Number.parseInt(locationValue, 10),
        byPositionId: position_id,
        isDedicated: dedicated,
        byEndTime: end_time,
        byStartTime: start_time,
        allowOvertime: allow_overtime,
        onSuccess: (teamMembersCountData) => {
          onChange([
            { target: { name: 'availableTeamMembersCount', value: teamMembersCountData } },
          ]);
        },
      }),
      client: createShiftCardApolloClient,
    });

    const { data: communityCustomFieldsData, fetch: fetchCommunityCustomFields } = useGQLQuery(
      createShiftsPageCommunityCustomFields({
        byFacilityId: Number.parseInt(locationValue),
      }),
    );

    const { data, fetch: fetchPositionsPriceRanges } = useGQLQuery<
      positionsPriceRanges,
      PositionsPriceRangesType,
      positionsPriceRangesVariables
    >({
      ...positionsPriceRangesQueryConfig({
        positionIds: position_id,
        communityId: parseInt(locationValue),
        onSuccess: (positionsPriceRanges) => {
          if (positionsPriceRanges) {
            const positionDetails = getPositionDetails({
              positionsPriceRanges,
              position_id,
              dedicated,
            });
            const eventsArray = [
              { target: { name: 'positionName', value: positionDetails?.name } },
              { target: { name: 'min_rate', value: positionDetails?.min_rate } },
              { target: { name: 'max_rate', value: positionDetails?.max_rate } },
            ];
            if (
              doesPositionHaveDedicatedPriceRange({
                positionsPriceRanges,
                position_id,
              }) &&
              dedicated === undefined
            ) {
              eventsArray.push({ target: { name: 'dedicated', value: true } });
              eventsArray.push({ target: { name: 'allow_overtime', value: true } });
            }
            onChange(eventsArray);
          }
        },
      }),
      client: createShiftCardApolloClient,
    });

    const fetchCardData = useCallback(
      async ({ position_id, dedicated, allow_overtime }) => {
        if (position_id.length === 0 || !locationValue) return void 0;
        await fetchPositionsPriceRanges(
          positionsPriceRangesQueryConfig({
            positionIds: position_id,
            communityId: parseInt(locationValue),
          }),
        );
        await fetchCommunityCustomFields();
        return await fetchTeamMemberCount(
          teamMembersQueryCountConfig({
            byFacilityId: Number.parseInt(locationValue, 10),
            byPositionId: position_id,
            isDedicated: dedicated,
            byEndTime: end_time,
            byStartTime: start_time,
            allowOvertime: allow_overtime,
          }),
        );
      },
      [
        end_time,
        fetchCommunityCustomFields,
        fetchPositionsPriceRanges,
        fetchTeamMemberCount,
        locationValue,
        start_time,
      ],
    );

    const _onChange = async (e: ChangeEventType) => {
      const { name, value } = e.target;
      switch (name) {
        case 'position_id': {
          fetchCardData({ position_id: value });
          onChange(e);
          break;
        }
        case 'dedicated': {
          fetchTeamMemberCount(
            teamMembersQueryCountConfig({
              byEndTime: end_time,
              byStartTime: start_time,
              byFacilityId: Number.parseInt(locationValue),
              byPositionId: position_id,
              isDedicated: value,
              allowOvertime: allow_overtime,
            }),
          );
          onChange(e);
          break;
        }
        case 'allow_overtime': {
          fetchTeamMemberCount(
            teamMembersQueryCountConfig({
              byEndTime: end_time,
              byStartTime: start_time,
              byFacilityId: Number.parseInt(locationValue),
              byPositionId: position_id,
              isDedicated: dedicated,
              allowOvertime: value,
            }),
          );
          onChange(e);
          break;
        }
        default: {
          onChange(e);
        }
      }
    };

    const clearRequestedJanes = () => {
      _onChange({
        target: {
          name: 'request_janes',
          value: '',
        },
      });
    };

    const handleEditShiftDialogCancel = () => {
      setPendingConfirmationEvent(null);
      return setIsEditShiftDetailsDialogOpen(false);
    };

    const handleEditShiftDialogAction = () => {
      if (pendingConfirmationEvent) {
        clearRequestedJanes();
        _onChange(pendingConfirmationEvent);
        setPendingConfirmationEvent(null);
        setIsEditShiftDetailsDialogOpen(false);
      }
    };

    const handleChange = (e: ChangeEventType) => {
      const { name } = e.target;
      const { request_janes } = values;
      if (fieldsThatAffectRequestedTeamMembers.includes(name) && request_janes?.length) {
        setPendingConfirmationEvent(e);
        setIsEditShiftDetailsDialogOpen(true);
      } else {
        _onChange(e);
      }
    };

    const cancelAndCloseModal = () => {
      goToModalView(null);
    };

    const saveAndCloseModal = (e: ChangeEventType | ChangeEventType[]) => {
      if (onChange) {
        onChange(e);
      }
      goToModalView(null);
    };

    useEffect(() => {
      if (position_id) {
        fetchCardData({ position_id, dedicated, allow_overtime });
      }
    }, [dedicated, fetchCardData, position_id, allow_overtime]);

    return (
      <CreateShiftCardContext.Provider
        value={{
          location,
          createShift,
          communityCustomFields: communityCustomFieldsData,
          fieldErrors,
          formErrors,
          modalView,
          goToModalView,
          cancelAndCloseModal,
          saveAndCloseModal,
          isSchedulingEnabled,
          handleChange,
          hasDedicatedTeam: doesPositionHaveDedicatedPriceRange({
            positionsPriceRanges: data,
            position_id,
          }),
          isFetchTeamMembersCountLoading,
          teamMembersCount: teamMembersCountData,
          isEditShiftDetailsDialogOpen,
          handleEditShiftDialogAction,
          handleEditShiftDialogCancel,
        }}
      >
        {children}
      </CreateShiftCardContext.Provider>
    );
  },
);
