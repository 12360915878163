import { BKJButton, BKJIconTooltip } from '@bookjane2/bookjane-design-library';
import { BKJCenteredDialog } from 'components/BKJCenteredDialog';
import { BKJSideModalFooter } from 'components/BKJSideModalFooter';
import { Flex } from 'components/Flex';
import { useGroupsModalFormContext } from 'components/GroupsModal/GroupsModal.form.context';
import { EmploymentStatusesDropdownOptionEnum } from 'components/GroupsModal/GroupsModal.form.types';
import { GroupDetailsHeaderBorderStyle, H3, H4 } from 'components/GroupsModal/GroupsModal.styled';
import {
  groupsModalRoutingService,
  useGroupsDetailsModalUIContext,
} from 'components/GroupsModal/GroupsModal.ui.context';
import {
  generateLabelFromRange,
  snakeToTitleCase,
  toNumber,
} from 'components/GroupsModal/views/dynamic/ViewGroup.constants';
import {
  HeaderSection,
  SubHeaderSection,
  CriteriaWrapper,
  ViewGroupWrapper,
  TextColumn,
  ViewGroupContainer,
  ViewCriteriaHeaderSection,
} from 'components/GroupsModal/views/dynamic/ViewGroup.styled';
import { useTranslation } from 'hooks/useTranslation';
import { FC, Fragment, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { isEnhancedDynamicGroupsFeatureEnabledSelector } from 'store/selectors/featureFlagSelectors';
import { css } from 'styled-components';
import { PositionTypeEnum } from 'types/graphql-types';

export const ViewDynamicGroup: FC = () => {
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false);
  const {
    isSubmitting,
    values: {
      name,
      employmentStatuses,
      positionTypes,

      minimumHoursPeriod,
      minimumMinsPeriod,
      maximumHoursPeriod,
      maximumMinsPeriod,

      minimumHoursDay,
      minimumMinsDay,
      maximumHoursDay,
      maximumMinsDay,
    },
    onDelete,
  } = useGroupsModalFormContext();

  const { t } = useTranslation();

  const labels = useMemo(
    () => ({
      min: t('groups:MIN_LABEL'),
      hour: t('groups:HR_LABEL'),
      lessThan: t('groups:LESS_THAN_LABEL'),
      moreThan: t('groups:MORE_THAN_LABEL'),
    }),
    [t],
  );

  const periodLabel = useMemo(
    () =>
      generateLabelFromRange({
        minimum: { hours: toNumber(minimumHoursPeriod), mins: toNumber(minimumMinsPeriod) },
        maximum: { hours: toNumber(maximumHoursPeriod), mins: toNumber(maximumMinsPeriod) },
        labels,
      }),
    [labels, maximumHoursPeriod, maximumMinsPeriod, minimumHoursPeriod, minimumMinsPeriod],
  );
  const dayLabel = useMemo(
    () =>
      generateLabelFromRange({
        minimum: { hours: toNumber(minimumHoursDay), mins: toNumber(minimumMinsDay) },
        maximum: { hours: toNumber(maximumHoursDay), mins: toNumber(maximumMinsDay) },
        labels,
      }),
    [labels, maximumHoursDay, maximumMinsDay, minimumHoursDay, minimumMinsDay],
  );

  const { groupId } = useGroupsDetailsModalUIContext();

  const { pathname } = useLocation();
  const location_id = Number.parseInt(pathname.split('/')[3]);
  const isEnhancedDynamicGroupsFeatureEnabled = useSelector(
    isEnhancedDynamicGroupsFeatureEnabledSelector(location_id),
  );

  return (
    <Fragment>
      <ViewGroupContainer borderTop={GroupDetailsHeaderBorderStyle}>
        <ViewGroupWrapper>
          <H3>{name}</H3>
          <Flex flexDirection="row">
            <H4>{t('groups:DYNAMIC_GROUP')}&nbsp;</H4>
            <BKJIconTooltip iconName="Info" placement="top">
              <Flex width="258px">
                {t('groups:DYNAMIC_GROUP_TOOLTIP_1')}
                <br />
                <br />
                {t('groups:DYNAMIC_GROUP_TOOLTIP_2')}
              </Flex>
            </BKJIconTooltip>
          </Flex>
        </ViewGroupWrapper>
      </ViewGroupContainer>
      <ViewGroupContainer borderTop={GroupDetailsHeaderBorderStyle}>
        <CriteriaWrapper>
          <ViewCriteriaHeaderSection>{t('groups:CRITERIA_FOR_MEMBER')}</ViewCriteriaHeaderSection>
          <Flex flexDirection="row">
            <SubHeaderSection>{t('groups:EMPLOYMENT_STATUS')}</SubHeaderSection>
            <SubHeaderSection>{t('groups:POSITION_TYPE')}</SubHeaderSection>
          </Flex>
          <Flex flexDirection="row">
            <TextColumn>
              {employmentStatuses.length > 0 ? (
                employmentStatuses.map((status) => {
                  switch (status) {
                    case EmploymentStatusesDropdownOptionEnum.casual:
                      return <div>{t('groups:CASUAL')}</div>;
                    case EmploymentStatusesDropdownOptionEnum.full_time:
                      return <div>{t('groups:FULL_TIME')}</div>;
                    case EmploymentStatusesDropdownOptionEnum.part_time:
                      return <div>{t('groups:PART_TIME')}</div>;
                    default:
                      return <div>{snakeToTitleCase(status)}</div>;
                  }
                })
              ) : (
                <div>-</div>
              )}
            </TextColumn>

            <TextColumn>
              {positionTypes.length > 0 ? (
                positionTypes.map((type) => {
                  switch (type) {
                    case PositionTypeEnum.additional:
                      return <div>{t('groups:SECONDARY')}</div>;
                    case PositionTypeEnum.primary:
                      return <div>{t('groups:PRIMARY')}</div>;
                    default:
                      return <div>{snakeToTitleCase(type)}</div>;
                  }
                })
              ) : (
                <div>-</div>
              )}
            </TextColumn>
          </Flex>
        </CriteriaWrapper>

        {isEnhancedDynamicGroupsFeatureEnabled && (
          <CriteriaWrapper>
            <HeaderSection>{t('groups:CRITERIA_HOURS_TITLE')}</HeaderSection>
            <Flex flexDirection="row">
              <SubHeaderSection>{t('groups:WITHIN_OVERTIME_PERIOD')}</SubHeaderSection>
              <SubHeaderSection>{t('groups:WITHIN_ONE_DAY')}</SubHeaderSection>
            </Flex>
            <Flex flexDirection="row">
              <TextColumn>{periodLabel}</TextColumn>
              <TextColumn>{dayLabel}</TextColumn>
            </Flex>
          </CriteriaWrapper>
        )}
      </ViewGroupContainer>
      <Flex height="100%"></Flex>
      <BKJCenteredDialog
        isOpen={isConfirmDeleteOpen}
        variant="Danger"
        title={t('groups:DELETE_GROUP_TITLE')}
        text={t('groups:DELETE_GROUP_DESCRIPTION')}
        cancelLabel={t('auth:CANCEL')}
        actionLabel={t('auth:DELETE')}
        onAction={onDelete}
        onCancel={() => setIsConfirmDeleteOpen(false)}
        onClose={() => setIsConfirmDeleteOpen(false)}
      />
      <BKJSideModalFooter justifyContent="space-between" flexDirection="row">
        <BKJButton
          variant="RedText"
          onClick={() => setIsConfirmDeleteOpen(true)}
          type="button"
          isLoading={isSubmitting}
          disabled={isSubmitting}
          css={css`
            text-decoration: none;
          `}
        >
          {t('groups:DELETE')}
        </BKJButton>
        <BKJButton
          type="button"
          variant="PurpleOutlined"
          width="180px"
          onClick={() => {
            if (groupId) groupsModalRoutingService.goToView('EditGroup', { groupId: groupId });
          }}
        >
          {t('schedule:EDIT')}
        </BKJButton>
      </BKJSideModalFooter>
    </Fragment>
  );
};
