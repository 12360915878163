import {
  PositionTypeEnum,
  fetchGroupDetails_node_StaticGroup_groupJanes_nodes_jane,
} from 'types/graphql-types';
import { ChangeEventType } from '@bookjane2/bookjane-design-library/lib/common.types';
import { GroupType } from 'components/GroupsModal/GroupsModal.ui.context';

export interface ISelectCriteriaFormValidation {
  minPeriodValidation: boolean;
  minDayValidation: boolean;
}

export enum EmploymentStatusesDropdownOptionEnum {
  casual = 'casual',
  full_time = 'full_time',
  part_time = 'part_time',
}

export interface IGroupInfo {
  groupDisplayId: number;
}

export interface GroupsModalForm {
  groupType: GroupType;
  name: string;
  memberSearch: string;
  memberFilter: string;

  employmentStatuses: EmploymentStatusesDropdownOptionEnum[];
  positionTypes: PositionTypeEnum[];

  minimumHoursPeriod: string[];
  minimumMinsPeriod: string[];
  maximumHoursPeriod: string[];
  maximumMinsPeriod: string[];

  minimumHoursDay: string[];
  minimumMinsDay: string[];
  maximumHoursDay: string[];
  maximumMinsDay: string[];
}

export interface IGroupModalFormActions {
  onDelete: () => void;
  formMembers: fetchGroupDetails_node_StaticGroup_groupJanes_nodes_jane[];
  deletedMemberIds: Set<string>;
  toggleMember: (e: ChangeEventType) => void;
  deleteMember: (memberId: string) => void;
}
