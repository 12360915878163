import { Flex } from '@bookjane2/bookjane-design-library';
import {
  BKJAvatarStyled,
  CancellationReason,
  ColorDot,
  EditedFields,
  InlineBKJButton,
  TableRowCell,
  TableRowViewDetailsCell,
  AuditTrailActivityTabButton,
  AwardedShift,
} from 'components/ActivityTab/ActivityTab.styled';
import { getTranslatedResponse } from 'utils/getTranslatedResponse';
import { useTranslation } from 'hooks/useTranslation';
import { useActivityTabRowPropsContext } from 'components/ActivityTab/ActivityTabRow.props.context';
import { AuditTrailActivityCell } from 'components/AuditTrailActivityTab/AuditTrailActivityTab.styled';
import { shiftModalRoutingService } from 'components/ShiftModal/ShiftModal.ui.context';
import { FC, Fragment, useCallback } from 'react';
import { MountTransition } from 'routes';

enum ActivityTypeEnum {
  CALLOUT,
  REGULAR,
  AUTOMATED,
  BIDDING,
}

type ExtractActivityTypeParams = {
  eventName: string;
  userFirstName: string | null;
  userLastName: string | null;
};

const extractActivityType = ({
  eventName,
  userFirstName,
  userLastName,
}: ExtractActivityTypeParams): ActivityTypeEnum => {
  if (
    eventName &&
    (eventName === 'callout_list_created' || eventName === 'new_callout_list_created')
  ) {
    return ActivityTypeEnum.CALLOUT;
  }
  if (eventName && eventName === 'placed_bid') {
    return ActivityTypeEnum.BIDDING;
  }
  if (userFirstName && userLastName) {
    return ActivityTypeEnum.REGULAR;
  }
  return ActivityTypeEnum.AUTOMATED;
};

export const DesktopAuditTrailActivityTabRow: FC = () => {
  const { t, i18n } = useTranslation();
  const {
    activityDate,
    calloutListId,
    dotColor,
    eventName,
    eventType,
    hasEdits,
    hasCancellationReason,
    sentToNrOfAgencies,
    showHideDetails,
    cancellationReason,
    userDetails,
    editDetailList,
    detailsShown,
    hasDetails,
    isRowGrey,
    awardedTo,
    hasReasonCategory,
    reasonCategory,
  } = useActivityTabRowPropsContext();
  const goToAuditTrailView = useCallback(
    () =>
      shiftModalRoutingService.goToView('AuditTrailView', {
        calloutListId,
      }),
    [calloutListId],
  );

  const activityType = extractActivityType({
    eventName,
    userFirstName: userDetails?.firstName,
    userLastName: userDetails?.lastName,
  });

  const GetActivityViewComponent = () => {
    switch (activityType) {
      case ActivityTypeEnum.CALLOUT:
        return (
          <AuditTrailActivityTabButton onClick={goToAuditTrailView} variant="PurpleOutlined">
            {t('schedule:VIEW')}
          </AuditTrailActivityTabButton>
        );
      case ActivityTypeEnum.REGULAR:
        return (
          <Flex alignItems="center" gap="8px">
            <Fragment>
              <BKJAvatarStyled
                width="30px"
                height="30px"
                fallbackAvatarColor="TextDarkGrey"
                src={userDetails?.avatarUrls?.origin}
                showPendingSignUp={userDetails?.isMobile}
              />
              <Flex flexDirection="column">
                <div>{`${userDetails.firstName} ${userDetails.lastName}`}</div>
                <div>{userDetails?.title}</div>
              </Flex>
            </Fragment>
          </Flex>
        );
      case ActivityTypeEnum.BIDDING:
        return (
          <Flex alignItems="center" gap="8px">
            <Fragment>
              <BKJAvatarStyled
                width="30px"
                height="30px"
                fallbackAvatarColor="TextDarkGrey"
                src={userDetails?.avatarUrls?.origin}
                showPendingSignUp={userDetails?.isMobile}
              />
              <Flex flexDirection="column">
                <div>{`${userDetails?.firstName} ${userDetails?.lastName}`}</div>
                <div>{userDetails?.communityName}</div>
              </Flex>
            </Fragment>
          </Flex>
        );
      case ActivityTypeEnum.AUTOMATED:
        return t('status:AUTOMATED');
    }
  };
  return (
    <Fragment>
      <TableRowCell isGrey={isRowGrey} spanTwoRows={detailsShown} hasDetails={hasDetails}>
        {activityDate}
      </TableRowCell>
      <AuditTrailActivityCell
        isGrey={isRowGrey}
        detailsShown={detailsShown}
        hasDetails={hasDetails}
      >
        <Flex alignItems="flex-start" gap="8px" width="100%">
          <ColorDot color={dotColor}>•</ColorDot>
          <Flex flexDirection="column" justifyContent="center" width="100%">
            <div>{getTranslatedResponse(eventType, 'status', i18n, t)}</div>
            {eventName === 'awarded_shift' && <AwardedShift>{awardedTo}</AwardedShift>}
            {sentToNrOfAgencies > 0 && (
              <EditedFields>
                {sentToNrOfAgencies} {t('common:AGENCY_MEMBERS')}
              </EditedFields>
            )}
            {hasEdits && (
              <Fragment>
                <EditedFields>{`${editDetailList.length} ${t('dashboard:CHANGES')}`}</EditedFields>
                <InlineBKJButton variant="PurpleText" onClick={showHideDetails}>
                  {detailsShown ? t('dashboard:HIDE_DETAILS') : t('common:VIEW_DETAILS')}
                </InlineBKJButton>
              </Fragment>
            )}
          </Flex>
        </Flex>
      </AuditTrailActivityCell>
      <TableRowCell isGrey={isRowGrey} detailsShown={detailsShown} hasDetails={hasDetails}>
        {GetActivityViewComponent()}
      </TableRowCell>
      {hasCancellationReason ? (
        <TableRowViewDetailsCell isGrey={isRowGrey}>
          <CancellationReason>
            {t('dashboard:REASON')}: {reasonCategory ? reasonCategory : cancellationReason}
          </CancellationReason>
        </TableRowViewDetailsCell>
      ) : null}
      {hasEdits && detailsShown ? (
        <TableRowViewDetailsCell isGrey={isRowGrey}>
          <MountTransition>
            <div>{!hasCancellationReason && !!cancellationReason && cancellationReason}</div>
            <div>{hasReasonCategory && reasonCategory}</div>
            {editDetailList?.map((edit) => (
              <div style={{ wordBreak: 'break-word' }}>{edit}</div>
            ))}
          </MountTransition>
        </TableRowViewDetailsCell>
      ) : null}
    </Fragment>
  );
};
