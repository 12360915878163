import {
  ShiftDistributionRulesGroupsComponentMap,
  ShiftDistributionRulesGroupsModalViewType,
} from 'components/ShiftDistributionRulesGroupsModal/ShiftDistributionRulesGroupsModal.constants';
import { useURLParameters } from 'hooks';
import { createContext, FC, useCallback, useContext, useEffect } from 'react';
import { ModalRoutingService } from 'services';

export const shiftDistributionRulesGroupsModalRoutingService = new ModalRoutingService(
  'shiftDistributionRulesGroups',
  'GroupsRules',
  ShiftDistributionRulesGroupsComponentMap,
  {},
  ['GroupsRules'],
);

export const POSITION_SETTINGS_VIEWS = ['EditPosition', 'ViewDetails', 'EditRule'];

export interface IShiftDistributionRulesGroupslUI {
  isOpen: boolean;
  onClose: () => void;
  goToAddPositionView: () => void;
  goToEditRuleView: ({ positionSettingsId }: { positionSettingsId?: string }) => void;
  goToViewDetailsView: ({ positionSettingsId }: { positionSettingsId?: string }) => void;
  shiftDistributionRulesGroupsActiveModalView: ShiftDistributionRulesGroupsModalViewType;
}

export const ShiftDistributionRulesGroupsModalUIContext =
  createContext<IShiftDistributionRulesGroupslUI>({} as IShiftDistributionRulesGroupslUI);

export const ShiftDistributionRulesGroupsModalUIProvider: FC = ({ children }) => {
  const [{ shiftDistributionRulesGroupsActiveModalView }, { removeParam }] =
    useURLParameters<{ activeModalView: ShiftDistributionRulesGroupsModalViewType }>();

  const handleClose = useCallback(() => {
    shiftDistributionRulesGroupsModalRoutingService.close();
  }, []);

  const goToAddPositionView = useCallback(() => {
    shiftDistributionRulesGroupsModalRoutingService.goToView('AddPositionRule');
  }, []);

  const goToEditRuleView = useCallback((params) => {
    shiftDistributionRulesGroupsModalRoutingService.goToView('EditRule', params);
  }, []);

  const goToViewDetailsView = useCallback((params) => {
    shiftDistributionRulesGroupsModalRoutingService.goToView('ViewDetails', params);
  }, []);

  useEffect(() => {
    if (
      !Object.keys(ShiftDistributionRulesGroupsComponentMap).includes(
        shiftDistributionRulesGroupsActiveModalView,
      )
    ) {
      removeParam(['shiftDistributionRulesGroupsActiveModalView']);
    }
    if (!POSITION_SETTINGS_VIEWS.includes(shiftDistributionRulesGroupsActiveModalView)) {
      removeParam(['positionSettingsId']);
    }
  }, [shiftDistributionRulesGroupsActiveModalView, removeParam]);

  return (
    <ShiftDistributionRulesGroupsModalUIContext.Provider
      value={{
        isOpen: !!shiftDistributionRulesGroupsActiveModalView,
        onClose: handleClose,
        goToAddPositionView,
        goToViewDetailsView,
        goToEditRuleView,
        shiftDistributionRulesGroupsActiveModalView:
          shiftDistributionRulesGroupsActiveModalView as ShiftDistributionRulesGroupsModalViewType,
      }}
    >
      {children}
    </ShiftDistributionRulesGroupsModalUIContext.Provider>
  );
};

export const useShiftDistributionRulesGroupsModalUIContext = () =>
  useContext<IShiftDistributionRulesGroupslUI>(ShiftDistributionRulesGroupsModalUIContext);
