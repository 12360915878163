import { BKJCheckbox } from '@bookjane2/bookjane-design-library';
import { ChangeFunctionType } from '@bookjane2/bookjane-design-library/lib/common.types';
import { useFetchCommunitiesDataContext } from 'components/CustomFieldModal/Contexts/FetchCommunities.data.context';
import { CUSTOM_FIELDS_MODAL_ALL_FACILITIES } from 'components/CustomFieldModal/CustomFieldModal.constants';
import {
  FacilitiesSelectionTableBody,
  FacilitiesSelectionAllTableRow,
  FacilitiesSelectionTableLabel,
  FacilitiesSelectionTableDescription,
  FacilitiesSelectionTableRow,
} from 'components/CustomFieldModal/CustomFieldModal.styled';
import { ApplyFacilitiesFormSchema } from 'components/CustomFieldModal/CustomFieldModal.types';
import { InfiniteScrollLoading } from 'components/InfiniteScrollLoading';
import { FC } from 'react';
import { useTranslation } from 'hooks/useTranslation';

export const FacilitiesSelectionTableContent: FC<{
  values: ApplyFacilitiesFormSchema;
  handleApplyFacilitiesChange: ChangeFunctionType;
  handleApplyAllFacilitiesChange: ChangeFunctionType;
}> = (props) => {
  const {
    data: fetchCommunities,
    loadMore,
    values: fetchCommunitiesValues,
  } = useFetchCommunitiesDataContext();
  const { values, handleApplyFacilitiesChange, handleApplyAllFacilitiesChange } = props;
  const { t } = useTranslation();

  return (
    <FacilitiesSelectionTableBody flexDirection="column">
      {fetchCommunitiesValues?.byName === '' ? (
        <FacilitiesSelectionAllTableRow justifyContent="space-between" alignItems="center">
          <FacilitiesSelectionTableLabel>
            <FacilitiesSelectionTableLabel>
              {t('settings:ALL_LOCATIONS')}
            </FacilitiesSelectionTableLabel>
            <FacilitiesSelectionTableDescription>
              {t(CUSTOM_FIELDS_MODAL_ALL_FACILITIES)}
            </FacilitiesSelectionTableDescription>
          </FacilitiesSelectionTableLabel>
          <BKJCheckbox
            variant="GreenSolid"
            name="allFacilities"
            onChange={handleApplyAllFacilitiesChange}
            value={values.allFacilities}
          />
        </FacilitiesSelectionAllTableRow>
      ) : null}
      {fetchCommunities?.fetchCommunities.nodes.map((community) => (
        <FacilitiesSelectionTableRow
          justifyContent="space-between"
          alignItems="center"
          key={community.id}
        >
          <FacilitiesSelectionTableLabel>{community.name}</FacilitiesSelectionTableLabel>
          <BKJCheckbox
            value={values.allFacilities ? true : values.communityIds.includes(`${community.id}`)}
            name="communityIds"
            variant="GreenSolid"
            data-testid={`${community.id}-${community.name}`}
            onChange={() =>
              handleApplyFacilitiesChange({
                target: {
                  name: 'communityIds',
                  value: `${community.id}`,
                },
              })
            }
          />
        </FacilitiesSelectionTableRow>
      ))}
      {fetchCommunities?.fetchCommunities.pageInfo.hasNextPage ? (
        <InfiniteScrollLoading onViewportEnter={loadMore!} />
      ) : null}
    </FacilitiesSelectionTableBody>
  );
};
