import { BKJSideModal } from 'components/BKJSideModal';
import { ShiftDistributionComponentMap } from 'components/ShiftDistributionRulesTieringModal/ShiftDistributionRulesTieringModal.constants';
import {
  tieringDetailsModalRoutingService,
  useTieringDetailsModalUIContext,
} from 'components/ShiftDistributionRulesTieringModal/ShiftDistributionRulesTieringModal.ui.context';
import { useTranslation } from 'hooks/useTranslation';
import { FC, useMemo } from 'react';

export const TieringDetailsModalModalComponent: FC = () => {
  const { isOpen, tieringDetailsActiveModalView } = useTieringDetailsModalUIContext();

  const { t } = useTranslation();

  const Component = useMemo(
    () => ShiftDistributionComponentMap[tieringDetailsActiveModalView],
    [tieringDetailsActiveModalView],
  );

  const modalHeader = {
    TieringDetails: t('location_settings:TIERING_DETAILS'),
    SharingDetails: t('location_settings:SHARING_DETAILS'),
  };
  return (
    <BKJSideModal
      isOpen={isOpen}
      onClose={tieringDetailsModalRoutingService.close}
      headerComponent={modalHeader[tieringDetailsActiveModalView]}
    >
      {isOpen && <Component />}
    </BKJSideModal>
  );
};
