import { BKJButton } from '@bookjane2/bookjane-design-library';
import { shiftModalRoutingService } from 'components/ShiftModal/ShiftModal.ui.context';
import { useDashboardPageContext } from 'pages/DashboardPage/DashboardPage.context';
import { useDashboardPageUnmountContext } from 'pages/DashboardPage/DashboardPageUnmount.context';
import { MouseEvent, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { MountTransition } from 'routes';
import { queryRefetchService } from 'services/QueryRefetchService';
import {
  isMultiLocationFeatureEnabledSelector,
  isUserAgencyAndAgencyLocationFilterForcedDisabledSelector,
} from 'store/selectors/featureFlagSelectors';
import { getShiftBiddingStatus } from 'utils/shiftStatus';
import { withMemo } from 'utils/withMemo';

import {
  IProps,
  IShiftCardProps,
  IShiftCardSkeletonProps,
} from 'components/ShiftCardSkeleton/ShiftCardSkeleton.types';

import { isAuditTrailFeatureEnabledForCommunitySelector } from 'store/selectors/featureFlagSelectors';
import { RenderShiftActionsProps } from 'components/RenderShiftActions/RenderShiftActions.types';
import { useBuildActionsForShiftCard } from 'components/RenderShiftActions/RenderShiftActions.constants';
import { useResponsive } from 'providers/ResponsiveProvider/Responsive';
import { MobileShiftCardSkeleton } from 'components/ShiftCardSkeleton/MobileShiftCardSkeleton';
import { WebShiftCardSkeleton } from 'components/ShiftCardSkeleton/WebShiftCardSkeleton';
import { getCurrentlySelectedLocation } from 'utils/getCurrentlySelectedLocation';
import { OrderStatusEnum } from 'types/graphql-types';

const ShiftActionComponent: RenderShiftActionsProps['SingleItemComponent'] = ({
  onClick,
  children,
}) => (
  <BKJButton variant="PurpleSolid" width="192px" onClick={onClick}>
    {children}
  </BKJButton>
);

export const ShiftCardSkeleton = withMemo(function (props: IProps): JSX.Element | null {
  const {
    id,
    bidders,
    biddable,
    biddingTimeEndsAt,
    status,
    community,
    canAssign,
    auditTrailVersion,
    mainJane,
  } = props.order;

  const biddingStatus = getShiftBiddingStatus({
    status: status.value,
    biddable: biddable,
    biddingTimeEndsAt: biddingTimeEndsAt,
    bidders: bidders,
  });

  const isUserAgencyAndAgencyLocationFilterForcedDisabled = useSelector(
    isUserAgencyAndAgencyLocationFilterForcedDisabledSelector,
  );

  const { values } = useDashboardPageContext();
  const { unmountDashboard } = useDashboardPageUnmountContext();

  useEffect(() => {
    queryRefetchService.addDataQuery('DASHBOARD_PAGE', `${id}`, unmountDashboard);
    queryRefetchService.addDataQuery('SHIFT_CARD', `${id}`, props.fetch);
    return () => {
      queryRefetchService.removeDataQuery('SHIFT_CARD', `${id}`);
      queryRefetchService.removeDataQuery('DASHBOARD_PAGE', `${id}`);
    };
  }, []); // eslint-disable-line

  function handleCardClick() {
    shiftModalRoutingService.open(
      canAssign && !isUserAgencyAndAgencyLocationFilterForcedDisabled
        ? {
            shiftModalShiftId: id,
            agencyCommunityId: values.location.value,
          }
        : {
            shiftModalShiftId: id,
          },
      'DetailView',
    );
  }

  function openRatingFlow(e: React.MouseEvent) {
    e.stopPropagation();
    shiftModalRoutingService.open({ shiftModalShiftId: id }, 'RateShiftView');
  }

  const openSendToAgenciesFlow = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      shiftModalRoutingService.open({ shiftModalShiftId: id }, 'PreRequestOptionsView');
    },
    [id],
  );
  const goToAwardTeamMemberFlow = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      shiftModalRoutingService.open({ shiftModalShiftId: id }, 'AwardTeamMemberView');
    },
    [id],
  );

  const [isContinueCallOutConfirmationOpen, setIsContinueCallOutConfirmationOpen] = useState(false);
  const [isAllowOvertimeConfirmationOpen, setIsAllowOvertimeConfirmationOpen] = useState(false);

  const isAuditTrailEnabled = useSelector(
    isAuditTrailFeatureEnabledForCommunitySelector({
      communityId: community.id,
      auditTrailVersion,
    }),
  );

  const currentlySelectedLocation = getCurrentlySelectedLocation();

  const isMultiLocationFeatureEnabled = useSelector(
    isMultiLocationFeatureEnabledSelector(Number(currentlySelectedLocation?.value)),
  );

  const isSharedJanePrimaryCommunityDifferentFromSelectedLocation =
    isMultiLocationFeatureEnabled &&
    status.value !== OrderStatusEnum.accepted &&
    !!mainJane &&
    !mainJane?.agencyCommunity &&
    mainJane?.primaryCommunity?.id !== currentlySelectedLocation?.value?.toString();

  const { shiftActions, isShiftInfoWrapperVisible, callOutStatus } = useBuildActionsForShiftCard({
    order: props.order,
    setIsContinueCallOutConfirmationOpen,
    setIsAllowOvertimeConfirmationOpen,
    goToAwardTeamMemberFlow: (e: MouseEvent) => {
      // We shouldn't have to do this... Consider re-writing BKJMenuDropdown to have `isMenuOpen` and`closeMenu()` props
      setTimeout(() => {
        goToAwardTeamMemberFlow(e);
      }, 250);
    },
    openSendToAgenciesFlow: (e: MouseEvent) => {
      // We shouldn't have to do this... Consider re-writing BKJMenuDropdown to have `isMenuOpen` and`closeMenu()` props
      setTimeout(() => {
        openSendToAgenciesFlow(e);
      }, 250);
    },
  });

  const otherProps: IShiftCardProps = {
    shiftActions,
    isShiftInfoWrapperVisible,
    isSharedJanePrimaryCommunityDifferentFromSelectedLocation,
    callOutStatus,
    isAuditTrailEnabled,
    ShiftActionComponent,
    biddingStatus,
    handleCardClick,
    openRatingFlow,
    isContinueCallOutConfirmationOpen,
    isAllowOvertimeConfirmationOpen,
    setIsContinueCallOutConfirmationOpen,
    setIsAllowOvertimeConfirmationOpen,
  };

  const shiftCardSkeletonProps: IShiftCardSkeletonProps = {
    ...props,
    ...otherProps,
  };

  const { isMobile } = useResponsive();
  const ShiftCardComponent = isMobile ? MobileShiftCardSkeleton : WebShiftCardSkeleton;

  return (
    <MountTransition>
      <ShiftCardComponent {...shiftCardSkeletonProps} />
    </MountTransition>
  );
});
