import { default as IPusher } from 'pusher-js';
import { SessionService } from 'services/SessionService';
import { SocketProviders } from 'services/SocketService/SocketService.constants';

export type PusherEventType =
  | 'pusher:connection_established'
  | 'pusher:subscription_error'
  | 'pusher_internal:subscription_succeeded'
  | 'pusher:subscribe';
export type PusherMessageActionType =
  | 'NOTIFICATION_SUCCESS'
  | 'NOTIFICATION_FAILED'
  | 'NOTIFICATION_WARN'
  | 'NOTIFICATION_INFO';
export type PusherEventDataType = 'AuthError';

export type J360PushNotificationEvent = 'ADP_SYNC_EMPLOYEES';

export type CustomDataType = {
  action: PusherMessageActionType;
  message: string;
  path: string;
  refresh: boolean;
  event: J360PushNotificationEvent;
};

export type PusherEventData = {
  type: PusherEventDataType;
  error?: string;
  status: number;
  data?: string | {};
  custom_data?: CustomDataType;
};

export type EventListenerFn = (data: CustomDataType) => void;

export interface PusherClient extends IPusher {
  addEventListener: (event: J360PushNotificationEvent, cb: EventListenerFn) => void;
}

export function pusherConnect(
  handleEvent: (event: J360PushNotificationEvent, data: CustomDataType) => void,
) {
  let error: boolean = false;
  const userId = SessionService.getUserId();
  if (!userId) throw new Error('No active user session!');
  const privateChannelId = `private-${userId}`;
  const pusher = SocketProviders['Pusher']();
  const privateChannel = pusher.subscribe(privateChannelId);

  pusher.connection.bind('error', (errorMsg: { _error: { data: { code: number } } }) => {
    if (errorMsg && errorMsg._error && errorMsg._error.data && errorMsg._error.data.code) {
      const {
        _error: {
          data: { code },
        },
      } = errorMsg;

      switch (code) {
        case 4004: // Connection is over the limit
        case 1006: // Connection closed unexpectedly
          error = true;
          pusher.disconnect();
          break;
      }
    }
  });

  privateChannel.bind_global((_event: PusherEventType, _data: PusherEventData) => {
    if (_event === 'pusher:subscription_error') {
      error = true;
      pusher.unsubscribe(privateChannelId);
      pusher.disconnect();
      return;
    }
    if (!_data.custom_data) return void 0;
    if (_data.custom_data) {
      const wsMessage = _data['custom_data'];
      handleEvent(wsMessage.event, wsMessage);
    }
  });

  if (error) return undefined;

  return pusher as PusherClient;
}
