import { Flex, BKJCheckboxListItem, BKJIcon } from '@bookjane2/bookjane-design-library';
import { defaultAgencyProfileImg } from 'assets';
import {
  AgencyGridCardWrapper,
  AgencyGridCardContentWrapper,
  AgencyGridAvatarWrapper,
  AgencyName,
  AgencyCount,
  AgencyLabel,
} from 'components/AgencyGridSelect/AgencyGridSelect.styled';
import { BKJRadioInput } from 'components/BKJRadioInput/BKJRadioInput';
import { FC } from 'react';
import { AgencyNode } from 'components/AgencyGridSelect/AgencyGridSelect.types';
import { useAgencyGridSelectPropsContext } from 'components/AgencyGridSelect/AgencyGridSelect.props.context';
import { useTranslation } from 'hooks/useTranslation';
import { FormatCurrency } from 'components/FormatCurrency';

export const AgencyGridSelectCardComponent: FC<{ agency: AgencyNode }> = ({ agency }) => {
  const { t } = useTranslation();
  const { selected, onChangeForm, name, type } = useAgencyGridSelectPropsContext();

  return (
    <AgencyGridCardWrapper key={agency.id}>
      <AgencyGridCardContentWrapper>
        <AgencyGridAvatarWrapper
          alt="AgencyProfilePic"
          src={
            !agency?.agency?.avatarUrls?.origin
              ? defaultAgencyProfileImg
              : (agency?.agency?.avatarUrls?.origin as unknown as string)
          }
        />
        <Flex flexDirection="column" padding="8px" justifyContent="space-between" height="100%">
          <Flex flexDirection="column" gap="3px">
            <AgencyName>{agency?.name}</AgencyName>
            <AgencyCount>{`${agency.availableJanes.count} ${t(
              'common:AVAILABLE',
            ).toLowerCase()}`}</AgencyCount>
            {agency.averageRating.value && agency.averageRating.count > 0 && (
              <Flex gap="3px" alignItems="center" alignContent="center">
                <BKJIcon iconName="Star" />
                <AgencyLabel>{`${agency.averageRating.value}`}</AgencyLabel>
                <AgencyCount>{`(${agency.averageRating.count})`}</AgencyCount>
              </Flex>
            )}
          </Flex>

          {agency.agencyPositionDetails[0]?.openRate && (
            <AgencyLabel>
              <FormatCurrency amount={agency.agencyPositionDetails[0]?.openRate.replace('$', '')} />
              /hr
            </AgencyLabel>
          )}
        </Flex>
      </AgencyGridCardContentWrapper>

      <Flex
        alignItems="center"
        height="55px"
        minHeight="55px"
        maxHeight="44px"
        width="100%"
        minWidth="100%"
      >
        {type === 'Multiple' ? (
          <BKJCheckboxListItem
            label={t('common:SELECT')}
            value={agency.id}
            name={name}
            selected={selected}
            onChange={onChangeForm}
          />
        ) : (
          <BKJRadioInput
            label={t('common:SELECT')}
            name={name}
            value={`${agency.id}`}
            checked={selected.includes(agency.id)}
            onChange={onChangeForm}
          />
        )}
      </Flex>
    </AgencyGridCardWrapper>
  );
};
