import { FC, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { BKJAvatar } from '@bookjane2/bookjane-design-library';
import {
  TableCell,
  TableRow,
  TeamMemberHeader,
  TeamMemberAvatarWrapper,
  TeamMemberTableCell,
} from '../ShiftSummaryView.styled';
import { ICreateShift } from 'pages/CreateShiftsPage/CreateShiftsPage.types';
import {
  prettyShiftDate,
  prettyStartTimeAndEndTime,
  prettyUnpaidBreakTime,
} from 'pages/CreateShiftsPage/ShiftSummaryView/ShiftSummaryView.utils';
import { transformObjectValuesArrayToSingle } from 'utils';
import {
  calculateShiftDurationInMinutes,
  formatInterval,
  getShiftStartTimeAndEndTime,
} from 'utils/time';
import { useTranslation } from 'hooks/useTranslation';
import { UserGuard } from 'guards/UserGuard';
import { Flex } from 'components/Flex';
import { WebShiftSummaryJaneRow } from 'pages/CreateShiftsPage/ShiftSummaryView/ShiftSummaryJaneRow';
import { isShiftBiddingFeatureEnabledSelector } from 'store/selectors/featureFlagSelectors';
import { useCreateShiftsPageContext } from 'pages/CreateShiftsPage/CreateShiftsPage.context';
import { getBiddingPeriod } from 'pages/CreateShiftsPage/CreateShiftsPage.constants';

export const WebShiftSummaryDetailRow: FC<{ shift: ICreateShift }> = ({ shift }) => {
  const { t, i18n } = useTranslation();
  const { startDate, startAt, endAt, unpaidBreakTime, request_janes, quantity, position_id } =
    transformObjectValuesArrayToSingle(shift.values);

  const quantityInt = parseInt(quantity);

  const request_janes_split: string[] =
    request_janes?.split(',').filter((id: string) => (id as string) !== '') || [];

  const {
    groupedFormsBehaviors: {
      values: {
        location: { value: locationId },
      },
    },
    biddingPeriods,
  } = useCreateShiftsPageContext();
  const [start_time] = getShiftStartTimeAndEndTime({
    startAt,
    endAt,
    startDate,
  });
  const calculatedBiddingPeriod = getBiddingPeriod(start_time, biddingPeriods);

  const isShiftBiddingFeatureEnabled = useSelector(
    isShiftBiddingFeatureEnabledSelector(locationId),
  );
  const ShiftDetailCells = () => (
    <Fragment>
      <TableCell>{prettyStartTimeAndEndTime([startAt, endAt])}</TableCell>
      <TableCell>{prettyUnpaidBreakTime(unpaidBreakTime)}</TableCell>
      <TableCell>
        {formatInterval(
          calculateShiftDurationInMinutes({
            startDate,
            startAt,
            endAt,
            unpaidBreakTime,
            isPaidDuration: true,
          }),
        )}
      </TableCell>
    </Fragment>
  );

  const RequestedJanesShiftRows = request_janes_split.map((janeId, index) => (
    <TableRow key={janeId}>
      {index === 0 && (
        <TableCell isBold rowSpan={quantityInt}>
          {prettyShiftDate(startDate, i18n.language)}
        </TableCell>
      )}
      <ShiftDetailCells />
      <WebShiftSummaryJaneRow positionId={position_id} janeId={janeId} />
    </TableRow>
  ));

  const OpenShiftRows = [...Array(quantityInt - request_janes_split.length)].map((_, index) => (
    <TableRow key={index}>
      {index === 0 && !request_janes_split.length && (
        <TableCell isBold rowSpan={quantityInt}>
          {prettyShiftDate(startDate, i18n.language)}
        </TableCell>
      )}
      <ShiftDetailCells />
      <TeamMemberTableCell>
        <Flex alignItems="center" gap="13px">
          <TeamMemberAvatarWrapper>
            <BKJAvatar width="30px" height="30px" fallbackAvatarColor="SecondaryPendingDefault" />
          </TeamMemberAvatarWrapper>
          <TeamMemberHeader>{t('schedule:OPEN_SHIFT')}</TeamMemberHeader>
        </Flex>
      </TeamMemberTableCell>
      {isShiftBiddingFeatureEnabled && <TableCell>{`${calculatedBiddingPeriod} mins`}</TableCell>}
      <UserGuard userType="USER_TYPE_ESSENTIAL">
        <TableCell>-</TableCell>
      </UserGuard>
    </TableRow>
  ));

  return (
    <Fragment>
      {RequestedJanesShiftRows}
      {OpenShiftRows}
    </Fragment>
  );
};
