import { createSelector } from '@reduxjs/toolkit';
import { isAvailabilityFeatureEnabledSelector } from '../isAvailabilityFeatureEnabledSelector';
import { isPayrollFeatureEnabledSelector } from '../isPayrollFeatureEnabledSelector';
import { isRecurringFeatureEnabledSelector } from '../isRecurringFeatureEnabledSelector';
import { isSchedulingFeatureEnabledSelector } from '../isSchedulingFeatureEnabledSelector';
import { isTieringFeatureEnabledSelector } from '../isTieringFeatureEnabledSelector';
import { isAllFacilitiesFilterFeatureEnabledSelector } from '../isAllFacilitiesFilterFeatureEnabledSelector';
import { isAccushieldSyncingFeatureEnabledSelector } from '../isAccushieldSyncingFeatureEnabledSelector';
import { useSelector } from 'react-redux';
import { isShiftBiddingFeatureEnabledSelector } from '../isShiftBiddingFeatureEnabledSelector';
import { isShiftSwapFeatureEnabledSelector } from '../isShiftSwapFeatureEnabledSelector';
import { isCapacityPlanningFeatureEnabledSelector } from '../isCapacityPlanningFeatureEnabledSelector';
import { isMultiLocationFeatureEnabledSelector } from 'store/selectors/featureFlagSelectors/isMultiLocationFeatureEnabledSelector';

export const allFeatureFlagsSelector = createSelector(
  [
    isAvailabilityFeatureEnabledSelector,
    isPayrollFeatureEnabledSelector,
    isRecurringFeatureEnabledSelector,
    isSchedulingFeatureEnabledSelector,
    isTieringFeatureEnabledSelector,
    isAllFacilitiesFilterFeatureEnabledSelector,
    isAccushieldSyncingFeatureEnabledSelector,
    isShiftBiddingFeatureEnabledSelector,
    isMultiLocationFeatureEnabledSelector,
    isShiftSwapFeatureEnabledSelector,
    isCapacityPlanningFeatureEnabledSelector,
  ],
  (
    isAvailabilityFeatureEnabled,
    isPayrollFeatureEnabledSelector,
    isRecurringFeatureEnabled,
    isSchedulingFeatureEnabled,
    isTieringFeatureEnabled,
    isAllFacilitiesFilterFeatureEnabled,
    isAccushieldSyncingFeatureEnabledSelector,
    isShiftBiddingFeatureEnabled,
    isMultiLocationFeatureEnabled,
    isShiftSwapFeatureEnabled,
    isCapacityPlanningFeatureEnabled,
  ) => ({
    isAvailabilityFeatureEnabled,
    isPayrollFeatureEnabledSelector,
    isRecurringFeatureEnabled,
    isSchedulingFeatureEnabled,
    isTieringFeatureEnabled,
    isAllFacilitiesFilterFeatureEnabled,
    isAccushieldSyncingFeatureEnabledSelector,
    isShiftBiddingFeatureEnabled,
    isMultiLocationFeatureEnabled,
    isShiftSwapFeatureEnabled,
    isCapacityPlanningFeatureEnabled,
  }),
);

export const useAllFeatureFlagsSelector = () => useSelector(allFeatureFlagsSelector);
