import { FC, Fragment, useCallback, useContext, useState } from 'react';
import { Flex } from 'components/Flex';
import { BKJSideModalFooter } from 'components/BKJSideModalFooter';
import { BKJBadge, BKJButton } from '@bookjane2/bookjane-design-library';
import { TimesheetsTabContext } from 'components/ShiftModal/src/views/DetailView/TimesheetsTab/TimesheetsTabForm.context';
import { useTranslation } from 'hooks/useTranslation';
import { LabelText, ValueText, Subtitle, Divider, ActualTimeDuration } from '../DetailView.styled';
import { DetailsWrapper, NoShowLabel } from './TimesheetsTab.styled';
import { formatUnpaidBreakTime, formatTime } from './TimesheetsTab.utils';
import { useTimeSheetsTabDataContext } from './TimesheetsTabData.context';
import { TimesheetsTabPendingView } from './TimesheetsTabPendingView';
import { UserGuard } from 'guards/UserGuard';
import { convertTimeToHours } from 'utils/time';
import { USER_TYPE_CAN_APPROVE_TIMESHEETS } from 'components/TimesheetsCard/TimesheetsCard.constants';
import { AutoMargin } from 'components/AutoMargin';
import { BKJCenteredDialog } from 'components/BKJCenteredDialog';
import { useShiftModalDataContext } from 'components/ShiftModal/ShiftModalProvider.data.context';
import {
  ClockInStatusEnum,
  ClockOutStatusEnum,
  OrderStatusEnum,
  shiftModalTimeSheetDetails_fetchTimeSheet_timeSheet,
} from 'types/graphql-types';
import { getLocalizedDateFormat } from 'utils/getLocalizedDateFormat';
import { generateDialogData } from 'components/TimesheetsCard/TimesheetsCard.utils';
import { DialogStateType, DialogType } from 'components/TimesheetsCard/TimesheetsCard.types';
import { DateTime } from 'luxon';
import { getCurrentlySelectedLocation } from 'utils/getCurrentlySelectedLocation';
import { FormatCurrency } from 'components/FormatCurrency';

const marginLeft = 16;

const ActualTimeTeamDurationComponent: FC<{
  timeSheet: shiftModalTimeSheetDetails_fetchTimeSheet_timeSheet | null;
  timeZone: string | undefined;
}> = ({ timeSheet, timeZone }) => {
  const { t } = useTranslation();
  if (timeSheet?.clockInStatus?.value === ClockInStatusEnum.not_clocked_in) {
    return <BKJBadge variant="Red">{t('shift_modal:NOT_CLOCKED_IN')}</BKJBadge>;
  } else {
    return (
      <Flex>
        {DateTime.fromISO(timeSheet?.clockIn).setZone(timeZone).toFormat('h:mm a')}&nbsp;-&nbsp;
        {DateTime.fromISO(timeSheet?.clockOut).setZone(timeZone).toFormat('h:mm a')}&nbsp;&nbsp;
        {timeSheet?.clockInStatus?.value === ClockInStatusEnum.late_clock_in && (
          <BKJBadge variant="Orange">{t('shift_modal:LATE')}</BKJBadge>
        )}
      </Flex>
    );
  }
};

const CICODistanceText: FC<{ distance: number }> = ({ distance }: { distance: number }) => {
  return (
    <Fragment>
      {distance < 1 ? `${Math.round(distance * 1000)} meters ` : `${Math.round(distance)} km `}
    </Fragment>
  );
};

const ApproveTimeSection: FC = () => {
  const { t } = useTranslation();
  const { data } = useTimeSheetsTabDataContext();
  const { timeZone } = getCurrentlySelectedLocation();
  const { dayOfWeek, month } = getLocalizedDateFormat(data.fetchTimeSheet.timeSheet?.approvedAt);
  switch (data.fetchTimeSheet.timeSheet?.status.value) {
    case 'approved':
    case 'no_show':
      return (
        <Fragment>
          <Divider />
          <Subtitle>{t('shift_modal:APPROVED_TIME')}</Subtitle>
          <Flex flexDirection="column" childMarginBottom="32px">
            <Flex alignItems="center">
              {data.fetchTimeSheet.timeSheet?.autoApproved ? (
                <Fragment>
                  <LabelText>{t('shift_modal:AUTO-APPROVED')}: </LabelText>
                  <ValueText marginLeft={marginLeft}>
                    {`${dayOfWeek} ${month} ${DateTime.fromISO(
                      data.fetchTimeSheet.timeSheet?.approvedAt,
                    )
                      .setZone(timeZone)
                      .toFormat(`d '${t('shift_modal:AT')}' h:mm a`)}`}
                  </ValueText>
                </Fragment>
              ) : (
                <Fragment>
                  <LabelText>{t('shift_modal:APPROVED_BY')}: </LabelText>
                  <ValueText marginLeft={marginLeft}>
                    {data.fetchTimeSheet.timeSheet?.approvedBy},&nbsp;
                    {`${dayOfWeek} ${month} ${DateTime.fromISO(
                      data.fetchTimeSheet.timeSheet?.approvedAt,
                    )
                      .setZone(timeZone)
                      .toFormat(`d '${t('shift_modal:AT')}' h:mm a`)}`}
                  </ValueText>
                </Fragment>
              )}
            </Flex>
            <Flex>
              <Flex flexDirection="column" width="240px">
                <LabelText>{t('shift_modal:APPROVED_DURATION')}</LabelText>
                <ValueText marginLeft={marginLeft}>
                  {data.fetchTimeSheet.timeSheet?.status.value === 'no_show' ? (
                    <NoShowLabel>{t('shift_modal:NO_SHOW')}</NoShowLabel>
                  ) : (
                    <>
                      {DateTime.fromISO(data.fetchTimeSheet.timeSheet?.approvedStartTime)
                        .setZone(timeZone)
                        .toFormat('h:mm a')}
                      &nbsp;-&nbsp;
                      {DateTime.fromISO(data.fetchTimeSheet.timeSheet?.approvedEndTime)
                        .setZone(timeZone)
                        .toFormat('h:mm a')}
                    </>
                  )}
                </ValueText>
              </Flex>
              {data.fetchTimeSheet.timeSheet?.approvedUnpaidBreakTime && (
                <Flex>
                  <Flex flexDirection="column">
                    <LabelText>{t('shift_modal:UNPAID_BREAK_TIME')}</LabelText>
                    <ValueText marginLeft={marginLeft}>
                      {formatUnpaidBreakTime(
                        data.fetchTimeSheet.timeSheet?.approvedUnpaidBreakTime,
                      )}
                    </ValueText>
                  </Flex>
                </Flex>
              )}
            </Flex>
            {data.fetchTimeSheet.timeSheet?.approvedTotalTime && (
              <Flex>
                <Flex flexDirection="column" width="240px">
                  <LabelText>{t('shift_modal:TOTAL_DURATION')}</LabelText>
                  <ValueText marginLeft={marginLeft}>
                    {formatTime(
                      (data.fetchTimeSheet.timeSheet?.approvedTotalTime?.rawTime || 0) +
                        convertTimeToHours(data.fetchTimeSheet.timeSheet?.approvedUnpaidBreakTime),
                    )}
                  </ValueText>
                </Flex>
                <Flex>
                  <Flex flexDirection="column">
                    <LabelText>{t('shift_modal:TOTAL_PAID_DURATION')}</LabelText>
                    <ValueText marginLeft={marginLeft}>
                      {data.fetchTimeSheet.timeSheet?.approvedTotalTime?.time}
                    </ValueText>
                  </Flex>
                </Flex>
              </Flex>
            )}
            <Flex>
              {data.fetchTimeSheet.timeSheet?.acceptedHourlyRate && (
                <Flex flexDirection="column" width="240px">
                  <LabelText>{t('shift_modal:HOURLY_PAY_RATE')}</LabelText>
                  <ValueText marginLeft={marginLeft}>
                    ${data.fetchTimeSheet.timeSheet?.acceptedHourlyRate?.rawCurrency?.toFixed(2)}
                  </ValueText>
                </Flex>
              )}
              {data.fetchTimeSheet.timeSheet?.approvedCustomerCost && (
                <Flex>
                  <Flex flexDirection="column">
                    <LabelText>{t('shift_modal:TOTAL_PAY')}</LabelText>
                    <ValueText marginLeft={marginLeft}>
                      <FormatCurrency
                        amount={`${
                          data?.fetchTimeSheet.timeSheet?.approvedCustomerCost?.rawCurrency ?? 0
                        }`}
                      />
                    </ValueText>
                  </Flex>
                </Flex>
              )}
            </Flex>
          </Flex>
        </Fragment>
      );

    case 'pending':
      return (
        <UserGuard userType={USER_TYPE_CAN_APPROVE_TIMESHEETS}>
          <Divider />
          <Subtitle>{t('shift_modal:APPROVED_TIME')}</Subtitle>
          <TimesheetsTabPendingView />
        </UserGuard>
      );
    default:
      return <Fragment />;
  }
};

export const TimesheetsTabComponent: FC = () => {
  const { t, i18n } = useTranslation();
  const { data } = useTimeSheetsTabDataContext();
  const { data: shiftData } = useShiftModalDataContext();
  const { onApprovalSubmit, onMarkAsNoShow, isPending } = useContext(TimesheetsTabContext);
  const [dialogState, setDialogState] = useState<DialogStateType>({
    title: '',
    text: '',
    onAction: onApprovalSubmit,
    variant: 'Danger',
    isOpen: false,
  });

  const { timeZone } = getCurrentlySelectedLocation();

  const handleDialogOpen = useCallback(
    (type: DialogType) => {
      const dialogData = generateDialogData(
        type,
        t,
        type === 'no_show' ? onMarkAsNoShow : onApprovalSubmit,
      );
      return setDialogState(dialogData);
    },
    [onMarkAsNoShow, onApprovalSubmit, t],
  );

  return (
    <Flex width="100%" height="100%" flexDirection="column" justifyContent="space-between">
      <DetailsWrapper>
        <Flex flexDirection="column">
          <Subtitle>{t('shift_modal:SCHEDULED_TIME')}</Subtitle>
          <Flex>
            <Flex flexDirection="column" width="240px">
              <LabelText>{t('shift_modal:SCHEDULED_DURATION')}</LabelText>
              <ValueText marginLeft={marginLeft}>
                {DateTime.fromISO(data.fetchTimeSheet.startTime)
                  .setZone(timeZone)
                  .toFormat('h:mm a')}
                &nbsp;-&nbsp;
                {DateTime.fromISO(data.fetchTimeSheet.endTime).setZone(timeZone).toFormat('h:mm a')}
              </ValueText>
            </Flex>
            <Flex>
              <Flex flexDirection="column">
                <LabelText>{t('shift_modal:UNPAID_BREAK_TIME')}</LabelText>
                <ValueText marginLeft={marginLeft}>
                  {formatUnpaidBreakTime(data.fetchTimeSheet.breakTime)}
                </ValueText>
              </Flex>
            </Flex>
          </Flex>
          <Divider />
          <Subtitle>{t('shift_modal:ACTUAL_TIME')}</Subtitle>
          <ActualTimeDuration>
            <Flex>
              <Flex flexDirection="column" width="240px">
                <LabelText>{t('shift_modal:TEAM_MEMBER_DURATION')}</LabelText>
                <ValueText marginLeft={marginLeft}>
                  <ActualTimeTeamDurationComponent
                    timeZone={timeZone}
                    timeSheet={data?.fetchTimeSheet?.timeSheet}
                  />
                </ValueText>
              </Flex>
              <Flex>
                <Flex flexDirection="column">
                  <LabelText>{t('shift_modal:UNPAID_BREAK_TIME')}</LabelText>
                  <ValueText marginLeft={marginLeft}>
                    {formatUnpaidBreakTime(
                      data?.fetchTimeSheet?.timeSheet?.unpaidBreakTime as string,
                    )}
                  </ValueText>
                </Flex>
              </Flex>
            </Flex>
          </ActualTimeDuration>
          <Flex>
            <Flex flexDirection="column" width="480px">
              {(data?.fetchTimeSheet?.timeSheet?.clockInStatus?.value ===
                ClockInStatusEnum.clocked_in ||
                data?.fetchTimeSheet?.timeSheet?.clockInStatus?.value ===
                  ClockInStatusEnum.late_clock_in) && (
                <Fragment>
                  <LabelText>{t('shift_modal:LOCATION_TRACKING')}</LabelText>
                  <ValueText marginLeft={marginLeft} padding={'2px 0 2px'}>
                    <Flex>
                      {!data?.fetchTimeSheet?.timeSheet?.clockInOffSite && (
                        <Fragment>{t('shift_modal:CLOCKED_IN_ON_SITE')}&nbsp;&nbsp;</Fragment>
                      )}
                      {data?.fetchTimeSheet?.timeSheet?.clockInOffSite &&
                        data?.fetchTimeSheet?.timeSheet?.clockInDistance !== null && (
                          <Fragment>
                            {t('shift_modal:CLOCKED_IN')} ~
                            <CICODistanceText
                              distance={data?.fetchTimeSheet?.timeSheet.clockInDistance}
                            />
                            {t('shift_modal:AWAY')}&nbsp;&nbsp;
                            <BKJBadge variant="Red">{t('shift_modal:OFFSITE')}</BKJBadge>
                          </Fragment>
                        )}
                    </Flex>
                  </ValueText>
                </Fragment>
              )}
              <Flex>
                <ValueText marginLeft={marginLeft}>
                  {data?.fetchTimeSheet?.timeSheet?.clockOutStatus?.value ===
                    ClockOutStatusEnum.clocked_out && (
                    <Flex>
                      {!data?.fetchTimeSheet?.timeSheet?.clockOutOffSite && (
                        <Fragment>{t('timesheets:CLOCKED_OUT_ON_SITE')}&nbsp;&nbsp;</Fragment>
                      )}
                      {data?.fetchTimeSheet?.timeSheet?.clockOutOffSite &&
                        data?.fetchTimeSheet?.timeSheet?.clockOutDistance !== null && (
                          <Fragment>
                            {t('timesheets:CLOCKED_OUT')} ~
                            <CICODistanceText
                              distance={data?.fetchTimeSheet?.timeSheet.clockOutDistance}
                            />
                            {t('common:AWAY')}&nbsp;&nbsp;
                            <BKJBadge variant="Red">{t('shift_modal:OFFSITE')}</BKJBadge>
                          </Fragment>
                        )}
                    </Flex>
                  )}
                </ValueText>
              </Flex>
            </Flex>
          </Flex>
          <ApproveTimeSection />
        </Flex>
      </DetailsWrapper>
      {shiftData.fetchOrder.status.value === OrderStatusEnum.completed &&
      data.fetchTimeSheet.timeSheet?.status.value === 'pending' ? (
        <UserGuard userType={USER_TYPE_CAN_APPROVE_TIMESHEETS}>
          <BKJSideModalFooter>
            <AutoMargin />
            <BKJButton
              width="fit-content"
              variant="PurpleOutlined"
              isLoading={isPending}
              disabled={isPending}
              onClick={() => {
                handleDialogOpen('no_show');
              }}
            >
              {t('shift_modal:MARK_AS_NO_SHOW')}
            </BKJButton>
            &nbsp;&nbsp;&nbsp;
            <BKJButton
              width={i18n.language === 'en' ? '180px' : 'fit-content'}
              variant="PurpleSolid"
              isLoading={isPending}
              disabled={isPending}
              onClick={() => handleDialogOpen('approve')}
            >
              {t('shift_modal:APPROVE')}
            </BKJButton>
          </BKJSideModalFooter>

          <BKJCenteredDialog
            isOpen={dialogState.isOpen}
            variant={dialogState.variant}
            title={dialogState.title}
            text={dialogState.text}
            cancelLabel={t('shift_modal:CANCEL')}
            actionLabel={t('shift_modal:CONFIRM')}
            onAction={dialogState.onAction}
            onCancel={() => setDialogState((prevState) => ({ ...prevState, isOpen: false }))}
            onClose={() => setDialogState((prevState) => ({ ...prevState, isOpen: false }))}
          />
        </UserGuard>
      ) : null}
    </Flex>
  );
};
