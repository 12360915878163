import { ShiftModalContextType } from 'components/ShiftModal/ShiftModal.ui.context';
import { DEFAULT_BASE_URL, BASE_AUTH_URL, UK_BASE_URL } from 'config';
import { SessionService } from 'services';
import { J360ApiEndpointURL } from 'types/common.types';

export const GOOGLE_PLACE_TEXTSEARCH: J360ApiEndpointURL =
  process.env.NODE_ENV === 'production'
    ? 'https://maps.googleapis.com/maps/api/place/textsearch/json'
    : '/places-textsearch-api';

export const GOOGLE_PLACE_DETAILS: J360ApiEndpointURL =
  process.env.NODE_ENV === 'production'
    ? 'https://maps.googleapis.com/maps/api/place/details/json'
    : '/places-details-api';

// Auth
export const FORGOT_PASSWORD: J360ApiEndpointURL = `${BASE_AUTH_URL}/api/v3/passwords/request_reset_password`;
export const AUTH_LOGIN: J360ApiEndpointURL = `${BASE_AUTH_URL}/auth/login/jwt`;
export const AUTH_SIGNUP = (country_code: string): J360ApiEndpointURL =>
  country_code === 'GB' ? `${UK_BASE_URL}/api/v3/sign_up` : `${DEFAULT_BASE_URL}/api/v3/sign_up`;

// Base API URL
export const BASE_URL = () => {
  return SessionService.getBaseUrl();
};

// Pusher
export const PUSHER_AUTH_URL = (): J360ApiEndpointURL => `${BASE_URL()}/api/v3/pushers`;

// Registration Progress
export const REGISTRATION_PROGRESS: J360ApiEndpointURL = `${BASE_URL()}/agencies/v1/agency_user_detail`;
export const INDUSTRIES: J360ApiEndpointURL = `${BASE_URL()}/public/v1/industries`;

// Agency
export const AGENCY_PATH: J360ApiEndpointURL = `${BASE_URL()}/agencies/v1/agencies`;
export const AGENCY_HOURLY_RATE: J360ApiEndpointURL = `${BASE_URL()}/b2b/v3/agencies/hourly_rate`;

// Locations
export const AGENCY_COMMUNITY_V1: J360ApiEndpointURL = `${BASE_URL()}/agencies/v1/agency_communities`;
export const AGENCY_COMMUNITY_V2: J360ApiEndpointURL = `${BASE_URL()}/agencies/v2/agency_communities`;
export const UPDATE_COMMUNITY_SETTINGS = (communityId: number): J360ApiEndpointURL => {
  return `${BASE_URL()}/b2b/v2/communities/${communityId}`;
};
export const CUSTOMER_SHIFT_SWAP = (community_id: string): J360ApiEndpointURL =>
  `${BASE_URL()}/b2b/v2/communities/${community_id}`;
export const UPDATE_SHIFT_SWAP = (community_id: string): J360ApiEndpointURL =>
  `${BASE_URL()}/b2b/v2/communities/${community_id}/toggle_shift_swap`;
export const CUSTOMER_COMMUNITIES = `${BASE_URL()}/b2b/v2/communities`;

// Groups
export const GROUPS: J360ApiEndpointURL = `${BASE_URL()}/b2b/v3/groups`;

// Shift Distribution Rules
export const SHIFT_DISTRIBUTION_RULES: J360ApiEndpointURL = `${BASE_URL()}/b2b/v3/shift_distribution_rules`;
export const SHIFT_DISTRIBUTION_GROUP_RULES_BATCH = (
  shiftDistributionRulesId: number,
): J360ApiEndpointURL =>
  `${BASE_URL()}/b2b/v3/shift_distribution_rules/${shiftDistributionRulesId}/group_rules/batch`;

// Positions
export const AGENCY_COMMUNITY_POSITIONS_PATH = (communityId: number): J360ApiEndpointURL =>
  `${BASE_URL()}/agencies/v1/agency_community_positions/${communityId}`;
export const AGENCY_POSITIONS: J360ApiEndpointURL = `${BASE_URL()}/agencies/v1/positions`;
export const LIST_OF_POSITIONS_PATH: J360ApiEndpointURL = `${BASE_URL()}/master/positions`;

// Liability insurance
export const LIABILITY_INSURANCE_PATH: J360ApiEndpointURL = `${BASE_URL()}/agencies/v1/liability_insurance`;

// WSIB
export const WSIB_PATH: J360ApiEndpointURL = `${BASE_URL()}/agencies/v1/wsibs`;

// Direct Deposit
export const DIRECT_DEPOSIT_PATH: J360ApiEndpointURL = `${BASE_URL()}/agencies/v1/direct_deposits`;

// Agency Users
export const AGENCY_USERS: J360ApiEndpointURL = `${BASE_URL()}/agencies/v1/agency_users`;

/**
 * Dashboard
 * 1. total shifts by status
 * 2. trends
 * 3. upcoming shifts
 */
export const UPCOMING_SHIFTS_STATS: J360ApiEndpointURL = `${BASE_URL()}/agencies/v1/shifts/upcoming_shifts_stats`;
export const TRENDS: J360ApiEndpointURL = `${BASE_URL()}/agencies/v1/shifts/trends`;
export const UPCOMING_SHIFTS: J360ApiEndpointURL = `${BASE_URL()}/agencies/v1/shifts/upcoming_shifts`;

// Timesheets

export const TIMESHEETS: J360ApiEndpointURL = `${BASE_URL()}/agencies/v1/time_sheets`;
export const CUSTOMER_TIMESHEETS: J360ApiEndpointURL = `${BASE_URL()}/b2b/v2/time_sheets`;

// Service Workers
export const AGENCY_INVITE_SERVICE_WORKERS: J360ApiEndpointURL = `${BASE_URL()}/agencies/v1/invite_agency_janes`;
export const CUSTOMER_INVITE_SERVICE_WORKERS: J360ApiEndpointURL = `${BASE_URL()}/b2b/v2/invite_team_member`;

export const RESEND_INVITE: (janeId: string) => J360ApiEndpointURL = (janeId: string) =>
  `${BASE_URL()}/api/v3/invitations//${janeId}/resend_invite`;
export const RESEND_TEAM_MEMBERS_INVITES: (community_id: number) => J360ApiEndpointURL = (
  community_id: number,
) => `${BASE_URL()}/api/v3/invitations/${community_id}/resend_invites`;
export const INVITE_BY: J360ApiEndpointURL = `${BASE_URL()}/api/v3/invitations/invite_users`;
export const INVITE_BY_SPREADSHEET: J360ApiEndpointURL = `${BASE_URL()}/api/v3/invitations/upload_invitations`;
export const DOWNLOAD_INVITE_SPREADSHEET: J360ApiEndpointURL = `${BASE_URL()}/api/v3/invitations/spreadsheet_template`;

// Admins
export const INVITE_SECONDARY_USERS: J360ApiEndpointURL = `${BASE_URL()}/b2b/v2/secondary_users/invite`;
export const AGENCY_SECONDARY_USERS: J360ApiEndpointURL = `${BASE_URL()}/agencies/v1/agency_user_invitations`;
export const CUSTOMER_SECONDARY_USERS: J360ApiEndpointURL = `${BASE_URL()}/b2b/v2/secondary_users`;

// Team members
export const AGENCY_TEAMS: J360ApiEndpointURL = `${BASE_URL()}/agencies/v3/janes`;
export const DEDICATED_POOL: J360ApiEndpointURL = `${BASE_URL()}/b2b/v2/pools`;
export const AGENCY_JANES: (janeId: string) => J360ApiEndpointURL = (janeId) =>
  `${BASE_URL()}/agencies/v3/janes/${janeId}`;
export const B2B_JANES: (janeId: string) => J360ApiEndpointURL = (janeId) =>
  `${BASE_URL()}/b2b/v3/janes/${janeId}`;
export const CUSTOMER_JANE_AGENCIES: J360ApiEndpointURL = `${BASE_URL()}/b2b/v3/external_janes_agency_communities`;
export const INVITE_TEAM_MEMBER: J360ApiEndpointURL = `${BASE_URL()}/api/v3/invitations/invite_team_member`;

// Clients
export const CLIENTS_PATH: J360ApiEndpointURL = `${BASE_URL()}/agencies/v1/clients`;

// Shift
export const VIEW_ORDER: J360ApiEndpointURL = `${BASE_URL()}/agencies/v1/orders`;
export const SHIFTS_CALENDAR: J360ApiEndpointURL = `${BASE_URL()}/api/v2/shifts_calendar`;
export const SHIFTS_CALENDAR_AGENCY: J360ApiEndpointURL = `${BASE_URL()}/agencies/v3/shifts`;

// Call Out list
export const CALLOUT_LIST_CSV: J360ApiEndpointURL = `${BASE_URL()}/b2b/v3/callout_lists/export`;

// Skills
export const GET_SKILLS: J360ApiEndpointURL = `${BASE_URL()}/agencies/v1/skills`;

// Preferences
export const GET_PREFERENCES: J360ApiEndpointURL = `${BASE_URL()}/agencies/v1/user_skills`;

// Order
export const CREATE_ORDER: J360ApiEndpointURL = `${BASE_URL()}/agencies/v1/orders`;

export const UPCOMING_SHIFT_HOURS: J360ApiEndpointURL = `${BASE_URL()}/agencies/v1/shifts/upcoming_shifts_hours`;

export const ADD_SHIFT_REVIEW = (order_id: ShiftModalContextType['shift_id']): J360ApiEndpointURL =>
  `${BASE_URL()}/b2b/v2/orders/${order_id}/reviews`;
export const ADD_SHIFT_AWARD = (order_id: ShiftModalContextType['shift_id']): J360ApiEndpointURL =>
  `${BASE_URL()}/b2b/v2/orders/${order_id}/award`;
export const ORDER_SEND_TO_AGENCIES = (
  order_id: ShiftModalContextType['shift_id'],
): J360ApiEndpointURL => `${BASE_URL()}/b2b/v3/orders/${order_id}/send_to_agencies`;

// Custom Fields
export const B2B_CREATE_CUSTOM_FIELDS: J360ApiEndpointURL = `${BASE_URL()}/b2b/v3/custom_fields`;
export const B2B_EDIT_CUSTOM_FIELDS = (customFieldId: number): J360ApiEndpointURL =>
  `${BASE_URL()}/b2b/v3/custom_fields/${customFieldId}`;
export const B2B_DELETE_CUSTOM_FIELDS = (customFieldId: string): J360ApiEndpointURL =>
  `${BASE_URL()}/b2b/v3/custom_fields/${customFieldId}`;

// Time Off
export const B2B_CREATE_TIME_OFF: J360ApiEndpointURL = `${BASE_URL()}/b2b/v3/time_offs`;
export const B2B_UPDATE_TIME_OFF = (timeOffId: number): J360ApiEndpointURL =>
  `${BASE_URL()}/b2b/v3/time_offs/${timeOffId}`;
export const B2B_EXPORT_CSV_TIME_OFF: J360ApiEndpointURL = `${BASE_URL()}/b2b/v3/time_offs/export`;
export const B2B_VALIDATE_TIME_OFF: J360ApiEndpointURL = `${BASE_URL()}/b2b/v3/time_offs/validate`;

// . TODO: WEB-2525 - Pending API
export const B2B_DELETE_TIME_OFF_BY_ID = (timeOffId: string): J360ApiEndpointURL =>
  `${BASE_URL()}/b2b/v3/time_offs/${timeOffId}`;

// Customer
export const CUSTOMER_COMMUNITIESV2: J360ApiEndpointURL = `${BASE_URL()}/b2b/v2/communities`;
export const CUSTOMER_COMMUNITIESV3: J360ApiEndpointURL = `${BASE_URL()}/b2b/v3/communities`;
export const CUSTOMER_AVAILABILITY = (community_id: string): J360ApiEndpointURL =>
  `${BASE_URL()}/b2b/v3/communities/${community_id}/availabilities`;
export const CUSTOMER_NOTIFICATIONS: J360ApiEndpointURL = `${BASE_URL()}/b2b/v2/users/send_notifications`;
export const CUSTOMER_COMMUNITY_SKILLS: J360ApiEndpointURL = `${BASE_URL()}/master/skills`;
export const CUSTOMER_POSITIONS: J360ApiEndpointURL = `${BASE_URL()}/b2b/v2/positions`;
export const CUSTOMER_ORDER: J360ApiEndpointURL = `${BASE_URL()}/b2b/v2/orders`;
export const CUSTOMER_USERS: J360ApiEndpointURL = `${BASE_URL()}/b2b/v2/users`;
export const CUSTOMER_TEAMS: J360ApiEndpointURL = `${BASE_URL()}/b2b/v3/janes`;

// Departments
export const CUSTOMER_DEPARTMENTS: J360ApiEndpointURL = `${BASE_URL()}/b2b/v2/departments`;
export const CUSTOMER_DEPARTMENTS_EDIT = (departmentId: number): J360ApiEndpointURL =>
  `${BASE_URL()}/b2b/v2/departments/${departmentId}`;
export const CUSTOMER_FORGOT_PASSWORD: J360ApiEndpointURL = `${BASE_URL()}/b2b/v2/passwords/restore`;

// Communication
export const USER_LIST: J360ApiEndpointURL = `${BASE_URL()}/api/v2/users`;
export const CREATE_CONVERSATION: J360ApiEndpointURL = `${BASE_URL()}/b2b/conversations`;
export const INDIVIDUAL_CONVERSATION: J360ApiEndpointURL = `${BASE_URL()}/api/v2/individual_communications`;
export const RECENT_CONVERSATION: J360ApiEndpointURL = `${BASE_URL()}/api/v2/recent_conversations`;
export const ARCHIVE_CONVERSATION: J360ApiEndpointURL = `${BASE_URL()}/api/v2/archive_conversations`;
export const DELETE_CONVERSATION: J360ApiEndpointURL = `${BASE_URL()}/api/v2/delete_conversations`;
export const MEMBERS: J360ApiEndpointURL = `${BASE_URL()}/api/v2/admins`;
export const CREATE_BROADCAST: J360ApiEndpointURL = `${BASE_URL()}/api/v2/communication_broadcasts`;
export const CREATE_GROUP_CHAT: J360ApiEndpointURL = `${BASE_URL()}/api/v2/communication_groups`;
export const GROUP_CHAT_LIST: J360ApiEndpointURL = `${BASE_URL()}/api/v2/communication_group`;

// Twilio API
export const TWILIO_CALL: J360ApiEndpointURL = `${BASE_URL()}/api/v2/communication_room_archives`;

// ADP API
export const ADP_USER_INFO: J360ApiEndpointURL = `${BASE_URL()}/api/adp/user_infos`;
export const ADP_LOCATION: J360ApiEndpointURL = `${BASE_URL()}/api/adp/locations`;
export const ADP_EMPLOYEES: J360ApiEndpointURL = `${BASE_URL()}/api/adp/employees`;
export const ADP_UPLOAD_HISTORY: J360ApiEndpointURL = `${BASE_URL()}/api/adp/schedule_logs`;
export const ADP_JANES: J360ApiEndpointURL = `${BASE_URL()}/api/adp/janes`;
export const ADP_EMPLOYEES_INFO: J360ApiEndpointURL = `${BASE_URL()}/api/adp/adp_employees_info`;
export const ADP_CREDENTIALS: J360ApiEndpointURL = `${BASE_URL()}/api/adp/adp_credentials`;
export const ADP_SUBSCRIPTION: J360ApiEndpointURL = `${BASE_URL()}/api/adp/subscription_details`;
export const ADP_SYNC: J360ApiEndpointURL = `${BASE_URL()}/api/adp/sync_employees`;
export const ADP_EMPLOYER_INFO: J360ApiEndpointURL = `${BASE_URL()}/api/adp/employer_info`;

// Schedule
export const SCHEDULING_ENDPOINT: J360ApiEndpointURL = `${BASE_URL()}/b2b/v3/scheduling`;
export const SCHEDULING_HOURS: J360ApiEndpointURL = `${SCHEDULING_ENDPOINT}/hours`;
export const SCHEDULING_CAPACITY_PLANNING: J360ApiEndpointURL = `${SCHEDULING_ENDPOINT}/capacity_planning/assessment`;
export const SCHEDULING_SUMMARY: J360ApiEndpointURL = `${SCHEDULING_ENDPOINT}/draft_shifts/summary`;
export const SCHEDULING_SETTINGS = (facility_id: string): J360ApiEndpointURL =>
  `${SCHEDULING_ENDPOINT}/${facility_id}/settings`;
export const SCHEDULING_POSITION_SETTINGS = (facility_id: string): J360ApiEndpointURL =>
  `${SCHEDULING_ENDPOINT}/${facility_id}/position_settings`;
export const SCHEDULING_JANES: J360ApiEndpointURL = `${SCHEDULING_ENDPOINT}/janes`;
export const SCHEDULING_DRAFTS_ASSIGNED: J360ApiEndpointURL = `${SCHEDULING_ENDPOINT}/draft_shifts/assigned`;
export const SCHEDULING_DRAFTS_UNASSIGNED: J360ApiEndpointURL = `${SCHEDULING_ENDPOINT}/draft_shifts/unassigned`;
export const SCHEDULING_DRAFT_SHIFTS: J360ApiEndpointURL = `${SCHEDULING_ENDPOINT}/draft_shifts`;
export const SCHEDULING_DRAFT_SHIFTS_W_SHIFT_ID = (shift_id: string): J360ApiEndpointURL =>
  `${SCHEDULING_ENDPOINT}/draft_shifts/${shift_id}`;
export const SCHEDULING_DRAFT_SHIFTS_CLEAR: J360ApiEndpointURL = `${SCHEDULING_ENDPOINT}/draft_shifts/clear`;
export const SCHEDULING_PUBLISHED_SHIFTS: J360ApiEndpointURL = `${SCHEDULING_ENDPOINT}/published_shifts`;
export const SCHEDULING_PUBLISHED_SHIFT_AVAILABILITY = (shift_id: string): J360ApiEndpointURL =>
  `${BASE_URL()}/b2b/v2/orders/${shift_id}/availabilities`;
export const EXPORT_CSV_AGENCY_SHIFT_AVAILABILITY = (shift_id: string): J360ApiEndpointURL =>
  `${BASE_URL()}/agencies/v1/orders/${shift_id}/availabilities`;
export const SCHEDULING_PUBLISHED_SHIFT_DETAILS = (shift_id: string): J360ApiEndpointURL =>
  `${SCHEDULING_ENDPOINT}/published_shifts/${shift_id}`;
export const SCHEDULING_RELEASE_PUBLISH_SHIFT = (shift_id: number): J360ApiEndpointURL =>
  `${SCHEDULING_ENDPOINT}/published_shifts/${shift_id}/release`;
export const RELEASE_AGENCY_SHIFT = (shift_id: number): J360ApiEndpointURL =>
  `${BASE_URL()}/agencies/v1/orders/${shift_id}/release`;
export const SCHEDULING_CANCEL_PUBLISH_SHIFT = (shift_id: string): J360ApiEndpointURL =>
  `${SCHEDULING_ENDPOINT}/published_shifts/${shift_id}/cancel`;
export const SCHEDULING_PUBLISH_SHIFTS: J360ApiEndpointURL = `${SCHEDULING_ENDPOINT}/draft_shifts/publish`;
export const SCHEDULING_TEMPLATES: J360ApiEndpointURL = `${SCHEDULING_ENDPOINT}/templates`;
export const SCHEDULING_TEMPLATES_W_TEMPLATE_ID = (template_id: string): J360ApiEndpointURL =>
  `${SCHEDULING_ENDPOINT}/templates/${template_id}`;
export const SCHEDULING_TEMPLATES_W_TEMPLATE_ID_APPLY = (template_id: string): J360ApiEndpointURL =>
  `${SCHEDULING_ENDPOINT}/templates/${template_id}/apply`;
export const SCHEDULING_TEMPLATES_CHECK_NAME: J360ApiEndpointURL = `${SCHEDULING_ENDPOINT}/templates/check_name`;
export const SCHEDULING_TEMPLATES_APPLIED: J360ApiEndpointURL = `${SCHEDULING_ENDPOINT}/templates/applied`;
export const SCHEDULING_ACTIONABLE_JANES: J360ApiEndpointURL = `${SCHEDULING_ENDPOINT}/actionable_janes`;
export const SCHEDULING_META: J360ApiEndpointURL = `${SCHEDULING_ENDPOINT}/metadata`;
export const SEND_TO_AGENCIES = (id: string): J360ApiEndpointURL =>
  `${SCHEDULING_ENDPOINT}/published_shifts/${id}/send_to_agencies`;
export const EXPORT_STATE_AUDIT_REPORT: J360ApiEndpointURL = `${BASE_URL()}/b2b/v2/orders/state_audit/reports`;

// Budget Allocations
export const BUDGET_ALLOCATIONS = (positionId: number): J360ApiEndpointURL =>
  `${BASE_URL()}/b2b/v3/positions/${positionId}/budget_allocations`;

export const BUDGET_ALLOCATIONS_UPDATE = (
  positionId: number,
  budgetAllocationId: string,
): J360ApiEndpointURL => `${BUDGET_ALLOCATIONS(positionId)}/${budgetAllocationId}`;

// User Permissions
export const USER_PERMISSIONS = ({ baseUrl }: { baseUrl: string }): J360ApiEndpointURL =>
  `${baseUrl}/api/v3/user_permissions`;
