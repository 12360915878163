import { FC, useContext, useEffect, useMemo } from 'react';
import { createGQLDataContext } from 'utils/createDataContext';
import { useGQLQuery } from 'hooks';
import { GQLAPIContextType } from 'types/common.types';
import { fetchTieringDetailsConfig } from 'components/ShiftDistributionRulesTieringModal/ShiftDistributionRulesTieringModal.constants';
import { TieringData } from 'components/ShiftDistributionRulesTieringModal/ShiftDistributionRulesTieringModal.types';
import { fetchTieringDetails } from 'types/graphql-types';
import { useLocation } from 'react-router-dom';

export const TieringDetailsDataContext = createGQLDataContext<GQLAPIContextType<TieringData>>();

export const TieringDetailsDataProvider: FC = ({ children }) => {
  const { pathname } = useLocation();
  const locationId = pathname.split('/')[3];

  const tieringDetailsData = useGQLQuery<fetchTieringDetails, TieringData, unknown>(
    fetchTieringDetailsConfig(Number.parseInt(locationId)),
  );

  useEffect(() => {
    tieringDetailsData.fetch();
  }, []); // eslint-disable-line

  const contextValues = useMemo(() => {
    return {
      ...tieringDetailsData,
    };
  }, [tieringDetailsData]);

  return (
    <TieringDetailsDataContext.Provider value={contextValues}>
      {children}
    </TieringDetailsDataContext.Provider>
  );
};

export const useTieringDetailsDataContext = (): GQLAPIContextType<TieringData> =>
  useContext(TieringDetailsDataContext);
