import { Flex } from '@bookjane2/bookjane-design-library';
import {
  ColorDot,
  TableRowViewDetailsCellAuditTrailV1,
} from 'components/ActivityTab/ActivityTab.styled';
import {
  TableRowCell,
  InlineBKJButton,
  BKJAvatarStyled,
  EditedFields,
  CancellationReason,
} from 'components/ActivityTab/ActivityTab.styled';
import { FC, Fragment } from 'react';
import { getTranslatedResponse } from 'utils/getTranslatedResponse';
import { useTranslation } from 'hooks/useTranslation';
import { MountTransition } from 'routes';
import { useActivityTabRowPropsContext } from 'components/ActivityTab/ActivityTabRow.props.context';

export const ActivityTabRow: FC = () => {
  const { t, i18n } = useTranslation();
  const {
    activityDate,
    dotColor,
    eventType,
    hasEdits,
    hasCancellationReason,
    showHideDetails,
    cancellationReason,
    userDetails,
    editDetailList,
    detailsShown,
    hasDetails,
    isRowGrey,
    eventName,
    hasReasonCategory,
    reasonCategory,
  } = useActivityTabRowPropsContext();

  return (
    <Fragment>
      <TableRowCell isGrey={isRowGrey} spanTwoRows={detailsShown} hasDetails={hasDetails}>
        {activityDate}
      </TableRowCell>
      <TableRowCell isGrey={isRowGrey} detailsShown={detailsShown} hasDetails={hasDetails}>
        <Flex alignItems="flex-start" gap="8px" width="100%">
          <ColorDot color={dotColor}>•</ColorDot>
          <Flex flexDirection="column" justifyContent="center" width="100%">
            <div>{getTranslatedResponse(eventType, 'status', i18n, t)}</div>
            {hasEdits ? (
              <Fragment>
                <EditedFields>{`${editDetailList.length} ${t('dashboard:CHANGES')}`}</EditedFields>
                <InlineBKJButton variant="PurpleText" onClick={showHideDetails}>
                  {detailsShown ? t('dashboard:HIDE_DETAILS') : t('common:VIEW_DETAILS')}
                </InlineBKJButton>
              </Fragment>
            ) : null}
          </Flex>
        </Flex>
      </TableRowCell>
      <TableRowCell isGrey={isRowGrey} detailsShown={detailsShown} hasDetails={hasDetails}>
        <Flex alignItems="center" gap="8px">
          {!!userDetails?.firstName && !!userDetails?.lastName ? (
            <Fragment>
              <BKJAvatarStyled
                width="30px"
                height="30px"
                fallbackAvatarColor="TextDarkGrey"
                src={userDetails?.avatarUrls?.origin}
                showPendingSignUp={userDetails?.isMobile}
              />
              <Flex flexDirection="column">
                <div>{`${userDetails.firstName} ${userDetails.lastName}`}</div>
                <div>{userDetails?.title}</div>
              </Flex>
            </Fragment>
          ) : (
            'Automated'
          )}
        </Flex>
      </TableRowCell>
      {hasCancellationReason ? (
        <TableRowViewDetailsCellAuditTrailV1 isGrey={isRowGrey}>
          {eventName === 'award_shift' ? (
            cancellationReason
          ) : (
            <CancellationReason>
              {t('dashboard:REASON')}: {reasonCategory ? reasonCategory : cancellationReason}
            </CancellationReason>
          )}
        </TableRowViewDetailsCellAuditTrailV1>
      ) : null}
      {hasEdits && detailsShown ? (
        <TableRowViewDetailsCellAuditTrailV1 isGrey={isRowGrey}>
          <MountTransition>
            <div>{!hasCancellationReason && !!cancellationReason && cancellationReason}</div>
            <div>{hasReasonCategory && reasonCategory}</div>
            {editDetailList?.map((edit) => (
              <div style={{ wordBreak: 'break-word' }}>{edit}</div>
            ))}
          </MountTransition>
        </TableRowViewDetailsCellAuditTrailV1>
      ) : null}
    </Fragment>
  );
};
