import { Flex } from 'components/Flex';
import {
  GreyText,
  InnerHeaderWrapper,
  MainTitle,
  NavigationButton,
  NavigationButtonsWrapper,
  AgencyAccept,
  Span,
  ShiftStatusText,
  ShiftStatusTextWrapper,
  Text,
  NoShowText,
  CommunityName,
} from 'components/ShiftModal/src/views/DetailView/DetailView.styled';
import { ShiftJaneSection } from 'components/ShiftModal/src/views/DetailView/DetailTabView/DetailTabView.styled';
import { ErrorBoundary } from '@bookjane2/bookjane-design-library';
import {
  DetailViewActiveTabType,
  DetailViewComponentMap,
  IDetailViewComponentProps,
} from 'components/ShiftModal/src/views/DetailView/DetailView.types';
import { FC, Fragment, useState } from 'react';
import { css } from 'styled-components';
import { useTranslation } from 'hooks/useTranslation';
import { Tags } from 'components/Tags';
import { PATH_DETAIL_VIEW_TAB_MAP } from '../DetailView.constants';
import { UserGuard } from 'guards/UserGuard';
import {
  OrderStatusEnum,
  shiftModalOrderDetails,
  shiftModalOrderDetails_fetchOrder_status,
} from 'types/graphql-types';
import {
  getShiftModalShiftDate,
  getShiftModalShiftTimeRange,
  getShiftModalShiftTotalDuration,
} from 'mappers/shiftModalOrderDetailsMapper';
import { useSelector } from 'react-redux';
import { getTranslatedResponse } from 'utils/getTranslatedResponse';
import isUserAgencySelector from 'store/selectors/userSelectors/isUserAgencySelector';
import isUserPremiumSelector from 'store/selectors/userSelectors/isUserPremiumSelector';
import { SessionService } from 'services';
import { usePathname } from 'hooks/usePathname';
import { ConfirmAvailabilityModal } from 'components/ShiftModal/src/views/DetailView/ConfirmAvailabilityModal/ConfirmAvailabilityModal';
import { WhiteLabelAccessor } from 'utils/whiteLabelAccessor';
import { BKJSideModalFooter } from 'components/BKJSideModalFooter';
import { DetailViewFooter } from 'components/ShiftModal/src/views/DetailView/DetailViewFooter';
import { getCurrentlySelectedLocation } from 'utils/getCurrentlySelectedLocation';

export function DetailViewInnerHeader({ data }: { data: shiftModalOrderDetails }) {
  const { t, i18n } = useTranslation();
  const isAcceptedByAgency = !!data.fetchOrder.orderSchedule.mainOrderJane?.assignedBy?.displayId;

  return (
    <InnerHeaderWrapper>
      <MainTitle>{data.fetchOrder!.position.name}</MainTitle>
      <UserGuard userType="USER_TYPE_AGENCY">
        <CommunityName>{data.fetchOrder!.community.name}</CommunityName>
      </UserGuard>
      <ShiftStatusTextWrapper>
        <Span>
          <ShiftStatusText type={data.fetchOrder!.status.name}>
            {getTranslatedResponse(data.fetchOrder?.status.name, 'status', i18n, t)}
            {!!isAcceptedByAgency && <AgencyAccept>&nbsp;(by Agency Admin)</AgencyAccept>}
          </ShiftStatusText>
        </Span>
      </ShiftStatusTextWrapper>
      {!!data.fetchOrder?.mainJane && <ShiftJaneSection data={data} />}
      <Flex>
        <Text>{getShiftModalShiftDate(data)}</Text>
      </Flex>
      <Flex>
        <Text>{getShiftModalShiftTimeRange(data)}</Text>
        &nbsp;
        <GreyText>({getShiftModalShiftTotalDuration(data)})</GreyText>
      </Flex>
      <Flex margin={`0 0 ${data.fetchOrder!.noShow && '12px'} 0`}>
        <Tags
          id={data.fetchOrder!.orderSchedule.id}
          status={data.fetchOrder!.status.value}
          isRecurring={!!data.fetchOrder!.recurrence}
          isDedicated={!!data.fetchOrder!.dedicated}
          isSentToAgencies={data.fetchOrder!.sentToAgencies}
          hasJane={!!data.fetchOrder!.mainJane}
          janePrimaryCommunity={data?.fetchOrder?.mainJane?.primaryCommunity}
          isAgencyJane={!!data.fetchOrder?.mainJane?.agencyCommunity || false}
          isAcceptedBySharedTeamMember={data.fetchOrder!.acceptedBySharedTeamMember!}
          biddable={data.fetchOrder?.biddable}
          biddingTimeEndsAt={data.fetchOrder?.biddingTimeEndsAt}
          bidders={data.fetchOrder?.bidders}
        />
      </Flex>
      {data.fetchOrder!.noShow && (
        <Flex>
          <NoShowText>{t('shift_modal:ZERO_HOURS_WORKED')}</NoShowText>
        </Flex>
      )}
    </InnerHeaderWrapper>
  );
}

const tabs: [string, DetailViewActiveTabType][] = [
  ['General', 'GeneralTab'],
  ['Activity', 'ActivityTab'],
  ['Available Members', 'AvailableMembersTab'],
  ['Timesheets', 'TimesheetsTab'],
];

const orderStatusesForAgencyTeamMembersTab = [
  OrderStatusEnum.accepted,
  OrderStatusEnum.completed,
  OrderStatusEnum.pending,
  OrderStatusEnum.unfulfilled,
];

const orderStatusesForB2BTeamMembersTab = [
  OrderStatusEnum.accepted,
  OrderStatusEnum.cancelled,
  OrderStatusEnum.completed,
  OrderStatusEnum.in_progress,
  OrderStatusEnum.pending,
  OrderStatusEnum.requested,
  OrderStatusEnum.unfulfilled,
];

const shouldShowAvailableMembers = (
  isUserAgency: boolean,
  orderStatus: shiftModalOrderDetails_fetchOrder_status,
) => {
  if (isUserAgency) return orderStatusesForAgencyTeamMembersTab.includes(orderStatus.value);

  return orderStatusesForB2BTeamMembersTab.includes(orderStatus.value);
};

export const DetailTabView: FC<IDetailViewComponentProps> = ({ data }) => {
  const pathname = usePathname();
  const [activeTab, setActiveTab] = useState<DetailViewActiveTabType>(
    PATH_DETAIL_VIEW_TAB_MAP(pathname),
  );
  const { t, i18n } = useTranslation();
  const ActiveTabComponent = DetailViewComponentMap[activeTab];
  const isUserAgency = useSelector(isUserAgencySelector);
  const isUserPremium = useSelector(isUserPremiumSelector);

  const { timeZone } = getCurrentlySelectedLocation();
  return (
    <Fragment>
      <ConfirmAvailabilityModal />
      <ErrorBoundary componentName="DetailTabView" showLogo={!WhiteLabelAccessor.isWhiteLabelled()}>
        <Flex
          flexDirection="column"
          height="fit-content"
          minHeight="fit-content"
          css={css`
            position: sticky;
            background-color: white;
            z-index: 1;
            top: 0;
          `}
        >
          <NavigationButtonsWrapper>
            {tabs
              .filter(([, key]) => {
                if (key === 'AvailableMembersTab') {
                  return shouldShowAvailableMembers(isUserAgency, data.fetchOrder!.status);
                } else if (key === 'TimesheetsTab') {
                  switch (data.fetchOrder!.status.value) {
                    case OrderStatusEnum.completed:
                      // For Premium users, show timesheets tab only if shift was done by agency jane
                      if (isUserPremium) {
                        return (
                          !!data.fetchOrder!.mainJane!.agencyCommunity &&
                          data.fetchOrder!.sentToAgencies
                        );
                      }
                      return true;
                    default:
                      return false;
                  }
                }
                return true;
              })
              .map(([label, _enum]) => {
                return (
                  <NavigationButton
                    key={label}
                    isDisabled={!!data.fetchOrder!.isAway && label === 'Activity'}
                    onClick={() => activeTab !== _enum && setActiveTab(_enum)}
                    isActive={activeTab === _enum}
                  >
                    {SessionService.assertUserRole('Premium') && _enum === 'AvailableMembersTab'
                      ? t('shift_modal:AUDIT_TRAIL')
                      : getTranslatedResponse(label, 'shift_modal', i18n, t)}
                  </NavigationButton>
                );
              })}
          </NavigationButtonsWrapper>
        </Flex>

        <ActiveTabComponent
          timeZone={timeZone}
          activities={data?.fetchOrder.activities}
          data={data}
        />

        {activeTab !== 'TimesheetsTab' && (
          <BKJSideModalFooter>
            <DetailViewFooter />
          </BKJSideModalFooter>
        )}
      </ErrorBoundary>
    </Fragment>
  );
};
