import { useLazyQuery } from '@apollo/client';
import { UseGQLQuery, UseGQLQueryConfig } from 'hooks/useGQLQuery/useGQLQuery.types';
import { defaultApolloClient } from 'providers/ApolloProvider';
import { useMemo, useRef, useState } from 'react';
import { identity } from 'utils';
import { captureException } from 'utils/captureException';
import { getRenderingState } from 'utils/getRenderingState';

export function useGQLQuery<
  TData,
  TransformedData = TData,
  TVariables = undefined,
  TransformedVariables = TVariables,
>(
  config: UseGQLQueryConfig<TData, TransformedData, TVariables, TransformedVariables>,
): UseGQLQuery<TData, TransformedData, TVariables, TransformedVariables> {
  const {
    key,
    paginationType = 'PagedPagination',
    query,
    responseTransformer = identity,
    variables,
    client = defaultApolloClient,
    fetchPolicy = 'cache-and-network',
    nextFetchPolicy = 'cache-and-network',
    initialValues,
    pageSize,
    debounceTimeout,
    type,
    onSuccess,
    onError,
    ...restOpts
  } = config;

  const mountedRef = useRef<boolean>(['cache-first'].includes(fetchPolicy));
  const isMounted = mountedRef.current;
  const [, forceUpdate] = useState({});

  const [fetch, { loading: isLoading, data, error, called, refetch, ...options }] = useLazyQuery<
    TransformedData,
    TransformedVariables
  >(query, {
    ...restOpts,
    client,
    variables,
    notifyOnNetworkStatusChange: false,
    fetchPolicy,
    nextFetchPolicy,
    onCompleted: (data) => {
      if (onSuccess) onSuccess(responseTransformer(data as unknown as TData));
      if (!mountedRef.current) mountedRef.current = true;
      forceUpdate({});
    },
    onError: (error) => {
      captureException(error, {
        tags: {
          type: 'GraphQLAPIErrorResponse',
          file: 'useGQLQuery.ts',
        },
        extra: {
          config: {
            query,
            variables,
          },
        },
      });
      if (onError) onError(error);
      if (!mountedRef.current) mountedRef.current = true;
      forceUpdate({});
    },
  });

  const __isLoading = !isMounted ? true : isLoading;

  const status = getRenderingState(paginationType, __isLoading, error, data);

  const output = useMemo(() => {
    return {
      ...options,
      key,
      error,
      __typename: 'GraphQLAPI',
      isLoading: __isLoading,
      data: responseTransformer(data as unknown as TData),
      status,
      variables,
      called,
      refetch,
      fetch,
    };
  }, [
    __isLoading,
    called,
    data,
    error,
    fetch,
    key,
    options,
    refetch,
    responseTransformer,
    status,
    variables,
  ]) as UseGQLQuery<TData, TransformedData, TVariables, TransformedVariables>;

  return output;
}
