import { FC, useContext, useEffect, useMemo } from 'react';
import { createGQLDataContext } from 'utils/createDataContext';
import { useGQLQuery } from 'hooks';
import { GQLAPIContextType } from 'types/common.types';
import { fetchTieringDetails, fetchTieringDetailsVariables } from 'types/graphql-types';
import { useLocation } from 'react-router-dom';
import { UseGQLQueryConfig } from 'hooks/useGQLQuery/useGQLQuery.types';
import { QUERY_shiftDistributionRulesTieringDetails } from 'queries';

export const fetchShiftDistributionLocationConfig = (
  locationId: number,
): UseGQLQueryConfig<
  fetchTieringDetails,
  { locationName: string },
  fetchTieringDetailsVariables,
  fetchTieringDetailsVariables
> => {
  return {
    key: 'SHIFT_DISTRIBUTION_RULES_MODAL_LOCATION_QUERY',
    query: QUERY_shiftDistributionRulesTieringDetails,
    variables: {
      id: locationId,
    },
    responseTransformer: (data: fetchTieringDetails): { locationName: string } => {
      return {
        locationName: data && data.fetchCommunity ? data.fetchCommunity.name : '',
      };
    },
  };
};

export const ShiftDistributionLocationDataContext =
  createGQLDataContext<GQLAPIContextType<{ locationName: string }>>();

export const ShiftDistributionLocationDataProvider: FC = ({ children }) => {
  const { pathname } = useLocation();
  const locationId = pathname.split('/')[3];

  const { data, fetch } = useGQLQuery<fetchTieringDetails, { locationName: string }, unknown>(
    fetchShiftDistributionLocationConfig(Number.parseInt(locationId)),
  );

  useEffect(() => {
    if (locationId) {
      fetch();
    }
  }, []); // eslint-disable-line

  const contextValues = useMemo(() => {
    return {
      locationName: data?.locationName,
    };
  }, [data]);

  return (
    <ShiftDistributionLocationDataContext.Provider value={contextValues}>
      {children}
    </ShiftDistributionLocationDataContext.Provider>
  );
};

export const useShiftDistributionLocationDataContext = (): GQLAPIContextType<{
  locationName: string;
}> => useContext(ShiftDistributionLocationDataContext);
