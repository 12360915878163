import { J360UserType } from 'guards/UserGuard.types';
import jwt_decode from 'jwt-decode';
import LogRocket from 'logrocket';
import { globalStore } from 'reduxStore';
import { localPersistenceService } from 'services/localPersistenceService';
import { pusherService } from 'services/PusherService';
import { j360ApiHeartbeat } from 'store/reducers/heartbeat/heartbeatSlice';
import { userPermissions, verifyToken } from 'store/reducers/auth/authReducer.actions';
import {
  AgencyLoginResponseUserObject,
  B2BLoginResponseUserObject,
} from 'store/reducers/auth/authReducer.types';
import { BKJAuthTokenType } from 'types/common.types';
import { getWebVersion } from 'utils/getWebVersion';
import { DEFAULT_BASE_URL } from 'config';

const POLLING_RATE: number = 3600000; // Poll JWT token every hour
const BKJ_USER_KEY: string = 'bjk-user';
const BKJ_POSITIONS_KEY: string = 'bjk-positions';
const BKJ_AGENCY_KEY: string = 'bjk-agency';
const BKJ_BASE_URL_KEY: string = 'bjk-base-url';

export class SessionService {
  token_interval_id?: number;
  j360ApiHeartbeat_interval_id?: number;

  constructor() {
    this.token_interval_id = undefined;
    this.j360ApiHeartbeat_interval_id = undefined;
  }

  public onAppLaunch = () => {
    const sessionToken = SessionService.getToken();
    this.startPollingJ360ApiHeartbeat();
    this.identifyLogRocketUser();
    if (sessionToken) {
      this.startPollingTokenVerification();
      if (SessionService.assertUserType('B2B')) pusherService.connect();
    }
    return void 0;
  };

  public onUserLoggedOut = (shouldReload = true): void => {
    SessionService.deleteToken();
    SessionService.clearUserCache();
    localPersistenceService.removeItem(SessionService.getApolloCacheKey());
    SessionService.clearSessionStorage();
    SessionService.clearLocalStorage();

    if (SessionService.assertUserType('B2B')) pusherService.disconnect();

    this.startPollingJ360ApiHeartbeat();
    this.stopPollingTokenVerification();

    /* -------------------------------------------------------------------------- */
    /*               DELETE THIS AFTER PHOENIX SETTINGS IS REPLACED               */
    /* -------------------------------------------------------------------------- */
    if (shouldReload) {
      window.history.pushState({}, 'BookJane', '/');
      window.setTimeout(() => {
        window.location.reload();
      }, 1);
    }
    return void 0;
  };

  public onUserLoggedIn = ({
    user,
    token,
    baseUrl,
  }: {
    user: B2BLoginResponseUserObject | AgencyLoginResponseUserObject;
    token: BKJAuthTokenType;
    baseUrl: string;
  }): void => {
    SessionService.setToken(token);
    SessionService.setUser(user);
    SessionService.setBaseUrl(baseUrl);

    globalStore.dispatch(userPermissions());

    this.startPollingTokenVerification();
    this.stopPollingJ360ApiHeartbeat();
    this.identifyLogRocketUser();

    if (SessionService.assertUserStatus('Pending')) {
      window.history.pushState({}, 'BookJane', '/pending');
    }
    if (SessionService.assertUserType('B2B')) pusherService.connect();

    return void 0;
  };

  public onTokenVerified(): BKJAuthTokenType {
    const token = SessionService.getToken();
    let userObject = SessionService.getUser();
    const sessionUser = window.sessionStorage.getItem(BKJ_USER_KEY);
    if (token) {
      SessionService.setToken(token);
      if (!sessionUser && !!userObject) userObject = SessionService.decodeToken(token).user;
      if (userObject) SessionService.setUser(userObject);
    }

    return token as BKJAuthTokenType;
  }

  public static getBaseUrl = (): string => {
    return localPersistenceService.getItem(BKJ_BASE_URL_KEY) || DEFAULT_BASE_URL;
  };

  public static getToken = (): Nullable<BKJAuthTokenType> => {
    return localPersistenceService.getItem('sessionToken') || null;
  };

  public static isPendingB2B = () =>
    SessionService.assertUserType('B2B') && SessionService.assertUserStatus('Pending');

  private identifyLogRocketUser = () => {
    const user = SessionService.getUser();
    if (user) {
      const { id, first_name, last_name, email } = user;
      LogRocket.identify(`${id}`, {
        name: `${first_name} ${last_name}`,
        email: `${email}`,
        user_type: `${SessionService.getUserCategoryEnum()?.toLowerCase()}`,
      });
    }
  };

  public static setToken = (token: BKJAuthTokenType): void => {
    localPersistenceService.setItem('sessionToken', token);
    return void 0;
  };

  private static deleteToken = (): void => {
    localPersistenceService.removeItem('sessionToken');
    return void 0;
  };

  private static clearUserCache = (): void => {
    localPersistenceService.removeItem(BKJ_USER_KEY);
    localPersistenceService.removeItem(BKJ_POSITIONS_KEY);
    localPersistenceService.removeItem(BKJ_AGENCY_KEY);
    return void 0;
  };

  private static setUser = (
    user: B2BLoginResponseUserObject | AgencyLoginResponseUserObject,
  ): void => {
    const localStorageUserObj = window.localStorage.getItem(BKJ_USER_KEY);
    if (!localStorageUserObj) window.localStorage.setItem(BKJ_USER_KEY, JSON.stringify(user));

    if (!localStorageUserObj) localPersistenceService.setItem(BKJ_USER_KEY, JSON.stringify(user));
    return void 0;
  };

  private static setBaseUrl = (baseUrl: string) => {
    localPersistenceService.setItem(BKJ_BASE_URL_KEY, baseUrl);
    return void 0;
  };

  public static getUser = (): Nullable<B2BLoginResponseUserObject> => {
    const user = localPersistenceService.getItem(BKJ_USER_KEY);
    if (!user) return null;
    return JSON.parse(user);
  };

  public static getUserId = (): Nullable<B2BLoginResponseUserObject['id']> => {
    const user = localPersistenceService.getItem(BKJ_USER_KEY);
    if (!user) return null;
    return JSON.parse(user).id;
  };

  public static getUserAgencyId = (): Nullable<B2BLoginResponseUserObject['agency_id']> => {
    const user = localPersistenceService.getItem(BKJ_USER_KEY);
    if (!user) return null;
    return JSON.parse(user).agency_id;
  };

  public static getApolloCacheKey = (): string => {
    const userId = SessionService.getUserId();
    if (!userId) return `nope`;
    return `${userId}_apollo-cache-persist_${getWebVersion()}`;
  };

  public static assertUserStatus = (userStatus: 'Pending' | 'Approved') => {
    const user = SessionService.getUser();
    if (!user) return false;
    switch (userStatus) {
      case 'Pending':
        return user.access_status === 'not_approved';
      case 'Approved':
        return user.access_status === 'approved';
    }
  };

  public static getUserCategoryEnum = () => {
    if (SessionService.assertUserType('Agency')) return 'Agency';
    if (SessionService.assertUserRole('Essential')) return 'Essential';
    if (SessionService.assertUserRole('Premium')) return 'Premium';

    return void 0;
  };

  public static assertUserType = (userType: J360UserType) => {
    const user = SessionService.getUser();
    if (!user) return false;
    switch (userType) {
      case 'Agency':
        return user.type === 'AgencyManager' || user.type === 'AgencyOwner';
      case 'B2B':
        return user.user_role === 'essential' || user.user_role === 'premium';
    }
  };

  public static assertUserRole = (userRole: 'Essential' | 'Premium') => {
    const user = SessionService.getUser();
    if (!user) return false;
    switch (userRole) {
      case 'Essential':
        return user.user_role === 'essential';
      case 'Premium':
        return user.user_role === 'premium';
    }
  };

  public static decodeToken = (token: BKJAuthTokenType): { user: B2BLoginResponseUserObject } =>
    jwt_decode(token);

  private startPollingTokenVerification = (): void => {
    globalStore.dispatch(verifyToken());

    if (!this.token_interval_id)
      this.token_interval_id = window.setInterval(() => {
        globalStore.dispatch(verifyToken());
      }, POLLING_RATE);
    return void 0;
  };

  private stopPollingTokenVerification = (): void => {
    window.clearInterval(this.token_interval_id);
    this.token_interval_id = undefined;
    return void 0;
  };

  private startPollingJ360ApiHeartbeat = (): void => {
    globalStore.dispatch(j360ApiHeartbeat());
    if (!this.j360ApiHeartbeat_interval_id)
      this.j360ApiHeartbeat_interval_id = window.setInterval(() => {
        globalStore.dispatch(j360ApiHeartbeat());
      }, POLLING_RATE);
    return void 0;
  };

  private stopPollingJ360ApiHeartbeat = (): void => {
    window.clearInterval(this.j360ApiHeartbeat_interval_id);
    this.j360ApiHeartbeat_interval_id = undefined;
    return void 0;
  };

  private static clearSessionStorage = (): void => {
    window.sessionStorage.clear();
  };
  private static clearLocalStorage = (): void => {
    window.localStorage.clear();
  };
}

export const sessionService = new SessionService();
