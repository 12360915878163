import { transformObjectValuesArrayToSingle } from 'utils';
import { DateTime } from 'luxon';
import i18next from 'i18next';
import { roundToTwoDecimals } from 'utils/roundToTwoDecimals';

export const range = (start, end) => {
  if (start === end) return [start];
  return [start, ...range(start + 1, end)];
};

export const getSeconds = (_date, startOfDay) => {
  let compareDate;
  if (startOfDay) {
    compareDate = DateTime.fromISO(_date).startOf('day');
  } else {
    compareDate = DateTime.now();
  }
  const date = _date ? DateTime.fromISO(_date) : DateTime.now();
  return Math.abs(date.diff(compareDate, 'second').seconds);
};

export const getSecondsForWholeDay = () => {
  const startOfDay = DateTime.now().startOf('day');
  const endOfDay = DateTime.now().endOf('day');
  return endOfDay.diff(startOfDay, 'second').seconds;
};

export const recurringMessage = (interval, frequency) => {
  switch (frequency) {
    case 'day':
      if (interval === 1) return 'Every day';
      return `Every ${interval} days`;
    case 'week':
      if (interval === 1) return 'Every week';
      return `Every ${interval} weeks`;
    case 'month':
      if (interval === 1) return 'Every month';
      return `Every ${interval} months`;
    default:
      return '';
  }
};

export const breakTimeToWords = (breaktime) => {
  if (breaktime == null) {
    return '';
  }

  const hour = breaktime.split(':')[0];
  const min = breaktime.split(':')[1];
  if (hour === '01') {
    return '1 hour';
  }
  return `${Number(min)} mins`;
};

export const formatColonToWords = (time) => {
  if (!time) return '-';

  // Changes time format from 1:30 to 1 hr and 30 mins
  const hours = time.split(':')[0];
  const mins = time.split(':')[1];

  let formattedTime = '';
  if (hours === '1') {
    formattedTime = '1 hr ';
  } else if (hours !== '0' && hours !== '1') {
    formattedTime += `${hours} hrs `;
  }

  if (mins !== 0) {
    formattedTime += `${mins.toString().substring(0, 2)} mins`;
  }

  return formattedTime !== '' ? formattedTime : '-';
};

export const formatDotToWords = (time) => {
  if (!time) return '-';

  // Changes time format from 1.5 to 1 hr and 30 mins
  const hours = time.split('.')[0];
  const mins = (time.split('.')[1] / 10) * 60;

  let formattedTime = '';
  if (hours === '1') {
    formattedTime = '1 hr ';
  } else if (hours !== '0' && hours !== '1') {
    formattedTime += `${hours} hrs `;
  }

  if (mins !== 0) {
    formattedTime += `${mins.toString().substring(0, 2)} mins`;
  }

  return formattedTime !== '' ? formattedTime : '-';
};

export const formatDotToFullWords = (time) => {
  // Changes time format from 1.5 to 1 hr and 30 mins
  const hours = time.split('.')[0];
  const mins = ((time.split('.')[1] || 0) / 10) * 60;

  let formattedTime = '';
  if (hours === '1') {
    formattedTime = '1 hour ';
  } else if (hours !== '0' && hours !== '1') {
    formattedTime += `${hours} hours `;
  }

  if (mins !== 0) {
    formattedTime += `${mins.toString().substring(0, 2)} mins`;
  } else {
    formattedTime += '00 mins';
  }

  return formattedTime !== '' ? formattedTime : '-';
};

export function convertTimeToHours(time) {
  const [hours, minutes] = time.split(':');
  const convertMinutes = parseInt((minutes / 6) * 10, 10);

  return parseFloat(`${parseInt(hours, 10)}.${convertMinutes < 10 ? '0' : ''}${convertMinutes}`);
}

export const getPluralizedTimeUnitString = (value, unit, returnZeroValue = false) => {
  if (!returnZeroValue && value === '0') {
    return '';
  }
  return `${value} ${unit}${value === '1' ? '' : 's'}`;
};

export function formatInterval(minutes) {
  if (minutes === 0) {
    return `0 mins`;
  }

  const interval = [
    roundToTwoDecimals(Math.floor(minutes / 60)).toString(),
    roundToTwoDecimals(minutes % 60)
      .toString()
      .padStart(2, '0'),
  ];

  if ((minutes % 60).toString() === '0') return getPluralizedTimeUnitString(interval[0], 'hr');

  return `${getPluralizedTimeUnitString(interval[0], 'hr')} ${getPluralizedTimeUnitString(
    interval[1],
    'min',
    true,
  )}`;
}

export function convertTimeToString(hour) {
  return DateTime.now().set({ hour: 0, minute: 0 }).plus(hour, 'hour').toFormat('HH:mm');
}

export const calculateDuration = (
  startTime,
  endTime,
  unpaidBreak,
  format = 'pretty',
  withUnpaid = true,
) => {
  const unPaidToMinutes = (withUnpaid ? unpaidBreak : 0) * 60;
  const [hoursA, minutesA] = startTime.split(':');
  const [hoursB, minutesB] = endTime.split(':');
  const timeA = DateTime.now().set({ hour: hoursA, minute: minutesA });
  const timeB = DateTime.now().set({ hour: hoursB, minute: minutesB });
  const interval = timeB.diff(timeA, 'minute').minutes;
  let formula = interval - unPaidToMinutes;
  if (interval < 0) {
    formula = 24 * 60 + timeB.diff(timeA, 'minutes').minutes - unPaidToMinutes;
  }
  if (formula < 0) formula = 0;

  return format === 'pretty' ? formatInterval(formula) : roundToTwoDecimals(formula);
};

// TODO: refactor but leverage work protik did to get this done
export const getHourMinuteSecond = (hourAndMinString) => {
  return hourAndMinString.split(':').map((str) => parseInt(str));
};

export const getShiftStartTimeAndEndTime = (args) => {
  const { startDate, startAt, endAt } = transformObjectValuesArrayToSingle(args);
  const _startDate = startDate ? DateTime.fromISO(startDate) : DateTime.now();
  const [startHour, startMinute] = getHourMinuteSecond(startAt);
  const [endHour, endMinute] = getHourMinuteSecond(endAt);
  const start_time = _startDate.set({
    hour: startHour,
    minute: startMinute,
    second: 0,
    millisecond: 0,
  });

  let end_time = DateTime.fromISO(_startDate).set({
    hour: endHour,
    minute: endMinute,
    second: 0,
    millisecond: 0,
  });

  if (start_time > end_time) {
    end_time = end_time.plus({ day: 1 });
  }

  return [start_time.toString(), end_time.toString()];
};

export const calculateShiftDurationInMinutes = (args) => {
  const { startDate, startAt, endAt, unpaidBreakTime, isPaidDuration } =
    transformObjectValuesArrayToSingle(args);
  const [start_time, end_time] = getShiftStartTimeAndEndTime({ startDate, startAt, endAt });
  let duration = DateTime.fromISO(end_time).diff(DateTime.fromISO(start_time), 'minute').minutes;

  if (isPaidDuration) {
    const unpaidBreakHours = unpaidBreakTime ? convertTimeToHours(unpaidBreakTime) : 0;
    duration = duration - unpaidBreakHours * 60;
  }

  if (duration < 0) {
    duration = 0;
  }

  return duration;
};
// TODO: refactor but leverage work protik did to get this done

// duration calculation
export const durationInHours = (t1, t2, breaktime, duration) => {
  calculateDuration(t1, t2, convertTimeToHours(breaktime));
  const b = convertTimeToHours(breaktime);
  const x = DateTime.fromISO(t1);
  let y = DateTime.fromISO(t2);
  if (x > y) {
    y = y.plus({ day: 1 });
  }
  // subtract break time in minutes
  return (y.diff(x).as(duration)['duration'] - b) / 60;
};

export const totalHoursFromDate = (date) => {
  const Now = DateTime.now();
  const selectedDate = DateTime.fromISO(date);

  // Calculate the duration in hours till now
  const hours = selectedDate.diff(Now, 'hour').hours;
  const minutes = selectedDate.diff(Now, 'minutes').minutes % 60;
  return hours + minutes / 60;
};

export const combineDateTime = (date, time) => {
  const parsedDate = DateTime.fromISO(date);
  // Extract the date part and combine it with newTime
  const combinedDateTime = parsedDate.format('yyyy-LL-dd') + 'T' + time;
  // Retain timeZone
  return combinedDateTime + parsedDate.format('Z');
};

export const getQuarterHoursInDay = () => {
  const intervals = [];

  intervals.push(
    {
      key: '12:00 am',
      value: '00:00',
      text: '12:00 am',
    },
    {
      key: '12:15 am',
      value: '00:15',
      text: '12:15 am',
    },
    {
      key: '12:30 am',
      value: '00:30',
      text: '12:30 am',
    },
    {
      key: '12:45 am',
      value: '00:45',
      text: '12:45 am',
    },
  );

  for (let i = 1; i < 12; i += 1) {
    for (let quarterHour = 0; quarterHour < 1; quarterHour += 0.25) {
      let formattedQuarterHour;
      switch (quarterHour) {
        case 0.25:
          formattedQuarterHour = `${i}:15`;
          break;
        case 0.5:
          formattedQuarterHour = `${i}:30`;
          break;
        case 0.75:
          formattedQuarterHour = `${i}:45`;
          break;
        default:
          formattedQuarterHour = `${i}:00`;
          break;
      }
      intervals.push({
        key: `${formattedQuarterHour} am`,
        value: formattedQuarterHour,
        text: `${formattedQuarterHour} am`,
      });
    }
  }

  intervals.push(
    {
      key: '12:00 pm',
      value: '12:00',
      text: '12:00 pm',
    },
    {
      key: '12:15 pm',
      value: '12:15',
      text: '12:15 pm',
    },
    {
      key: '12:30 pm',
      value: '12:30',
      text: '12:30 pm',
    },
    {
      key: '12:45 pm',
      value: '12:45',
      text: '12:45 pm',
    },
  );
  for (let i = 1; i < 12; i += 1) {
    for (let quarterHour = 0; quarterHour < 1; quarterHour += 0.25) {
      let formattedQuarterHour;
      let valueQuarterHour;
      switch (quarterHour) {
        case 0.25:
          formattedQuarterHour = `${i}:15`;
          valueQuarterHour = `${i + 12}:15`;
          break;
        case 0.5:
          formattedQuarterHour = `${i}:30`;
          valueQuarterHour = `${i + 12}:30`;
          break;
        case 0.75:
          formattedQuarterHour = `${i}:45`;
          valueQuarterHour = `${i + 12}:45`;
          break;
        default:
          formattedQuarterHour = `${i}:00`;
          valueQuarterHour = `${i + 12}:00`;
          break;
      }
      intervals.push({
        key: `${formattedQuarterHour} pm`,
        value: valueQuarterHour,
        text: `${formattedQuarterHour} pm`,
      });
    }
  }

  return intervals;
};

export const durationLongerThanHour = (duration) => {
  // -0.25 is 24 hour edge case (start-end-breaktime).
  return duration > 1 || duration === -0.25;
};

export const formatTimePickerValue = (time) => {
  return time.length < 5 ? `0${time}` : time;
};

export const calculateTime = (start, end, breaktime, price) => {
  let minutes = durationInHours(start, end, breaktime, 'minutes');

  // handle 24 hour edge case
  if (minutes < 0 && minutes !== 0) {
    minutes += 24;
  }

  // return minutes in 10.00 format
  const calculatedduration = parseFloat(minutes).toFixed(2);

  if (minutes === 0) {
    return {
      cost: (24 * price).toFixed(2),
      time: formatDotToWords((24.0).toString()),
    };
  }
  return {
    cost: (calculatedduration * price).toFixed(2),
    time: formatDotToWords(calculatedduration),
  };
};

export function getHoursAndMinutesFromMins(mins) {
  if (mins) {
    const hours = mins / 60;
    const rmins = Math.floor(hours) - mins;
    return `${hours ? `${hours} hours` : ''} ${rmins ? `${rmins} mins` : ''}`;
  }

  return '0 mins';
}

export const getHoursAndMinsFromDate = (date, time_zone) => {
  return DateTime.fromISO(date).setZone(time_zone).toFormat('HH:mm');
};

export function getDaysHoursAndMinutesFromMin(mins) {
  const hours = Math.floor(mins / 60);
  const rminutes = mins % 60;
  const days = Math.floor(hours / 24);
  const rhours = hours % 24;
  if (days || rhours || rminutes) {
    return `${days ? `${days} ${days === 1 ? 'day' : 'days'}` : ''}  ${
      rhours ? `${rhours} ${rhours === 1 ? 'hr' : 'hrs'}` : ''
    }  ${rminutes ? `${rminutes} mins` : ''} `.trim();
  }
  return `0 mins`;
}

export function getHoursAndMinutesFromHoursFloat(hours, type = 'mins') {
  const rhours = Math.floor(hours);
  const minutes = (hours - rhours) * 60;
  const rminutes = Math.round(minutes);
  if (rhours || rminutes) {
    return `${rhours ? `${rhours} ${rhours === 1 ? 'hr' : 'hrs'}` : ''}  ${
      rminutes ? `${rminutes} mins` : ''
    } `.trim();
  }
  return `0 ${type}`;
}
export function getWeeks(weeks, type = 'week') {
  if (weeks) {
    return `${
      weeks ? `${weeks} ${weeks === 1 ? i18next.t('WEEK') : i18next.t('WEEKS')}` : ''
    }`.trim();
  }
  return `1 ${type}`;
}
export const mapArrayForDropDownWeek = (weeksArray, type) =>
  weeksArray.map((option) => ({
    key: option,
    value: option,
    text: getWeeks(option, type),
  }));
export const mapArrayForDropDownTimes = (timesArray, type) =>
  timesArray.map((option) => ({
    key: option,
    value: option,
    text: getHoursAndMinutesFromHoursFloat(option, type),
  }));

export const mapArrayForDropDownTimesString = (timesArray, type) => {
  return timesArray.map((option) => {
    const day = DateTime.now().set({ hour: 0, minute: 0, second: 0 });
    return {
      key: option,
      value: day.plus({ hour: option }).toFormat('HH:mm'),
      text: getHoursAndMinutesFromHoursFloat(option, type),
    };
  });
};

export const mapArrayForBKJDropDownTimes = (timesArray, type) =>
  timesArray.map((option) => ({
    key: option,
    value: option,
    label: getHoursAndMinutesFromHoursFloat(option, type),
  }));

export const generateTime = (hour) => {
  let value = 0;
  const minsArray = [];
  const totalMinutes = hour * 60;
  while (value !== totalMinutes) {
    minsArray.push(value / 60.0).toFixed(2);
    value += 15;
  }
  return minsArray;
};

export const generateMinsArray = (hours, withMinutes = false, minuteStopper = 1) => {
  if (withMinutes) {
    return [...generateTime(minuteStopper || hours), ...range(minuteStopper || hours, hours)];
  }
  return [...range(minuteStopper, hours)];
};

export const timeDropdown = () => {
  const hourIncrement = generateMinsArray(24, true, 24);
  const day = DateTime.now().set({ hour: 0, minute: 0, second: 0 });
  hourIncrement.pop();
  return hourIncrement.map((hour) => {
    return {
      key: day.plus({ hour }).toFormat('HH:mm'),
      value: day.plus({ hour }).toFormat('HH:mm'),
      label: day.plus({ hour }).toFormat('h:mm a'),
    };
  });
};

export function getTimeZone() {
  return DateTime.now().zoneName;
}

export const getHoursMinsSecFromDate = (date) => {
  return DateTime.fromISO(date).toFormat('HH:mm:ss');
};

export const isDateBeforeNow = (date) => {
  const [hours, minutes] = getHoursMinsSecFromDate(date).split(':');
  return (
    DateTime.fromISO(date).set({ hour: hours, minute: minutes }) < DateTime.now().startOf('day')
  );
};
export const getStartDateAndEndDate = (startTime, endTime, date) => {
  const [hour, minutes] = startTime.split(':');
  const start_time = DateTime.fromISO(date).set({ hour: hour, minute: minutes });
  const duration = calculateDuration(startTime, endTime, 0, 'raw', false);

  const end_time = start_time.plus({ minutes: duration });

  return [start_time.toString(), end_time.toString()];
};

export const dayIsBeforeToday = (day) => {
  return DateTime.fromISO(day).startOf('day') < DateTime.now().startOf('day');
};

export const dayIsToday = (day) => {
  return DateTime.fromISO(day).startOf('day').hasSame(DateTime.now().startOf('day'), 'day');
};

export const isDateTimeBeforeNow = (date) => {
  const now = DateTime.now();
  return DateTime.fromISO(date) < now;
};

export const isDateTimeAfterNow = (date) => {
  const now = DateTime.now();
  return DateTime.fromISO(date) > now;
};

export const isDateTimeBeforeToday = (date) => {
  const now = DateTime.now().startOf('day');
  return DateTime.fromISO(date) < now;
};
