import { createSelector } from '@reduxjs/toolkit';
import { IGlobalReduxStore } from 'store';

const getUserPermissions = (state: IGlobalReduxStore) => state.auth.permissions;

export const isShiftBiddingFeatureEnabledSelector = (communityId: number) =>
  createSelector(getUserPermissions, (userPermissions) => {
    if (userPermissions?.SHIFT_BIDDING?.access)
      return (
        userPermissions.SHIFT_BIDDING.access.includes('read') &&
        !!userPermissions.SHIFT_BIDDING.communities &&
        !!userPermissions.SHIFT_BIDDING.communities[communityId] &&
        !!userPermissions.SHIFT_BIDDING.communities[communityId].includes('read')
      );
    else return false;
  });
