import { Flex } from 'components/Flex';
import { BKJButton } from '@bookjane2/bookjane-design-library';
import { FC } from 'react';
import { BKJSideModalFooter } from 'components/BKJSideModalFooter';
import { useTieringDetailsModalUIContext } from 'components/ShiftDistributionRulesTieringModal/ShiftDistributionRulesTieringModal.ui.context';
import {
  H3,
  SharingHeader,
  TieringDetailsTableContentCell,
  SharingDetailsWrapper,
  TieringRulesTableHeaderDivider,
} from 'components/ShiftDistributionRulesTieringModal/ShiftDistributionRulesTieringModal.styled';
import { fetchSharingDetails_fetchCommunities_nodes } from 'types/graphql-types';
import {
  ShiftDistributionLocationDataProvider,
  useShiftDistributionLocationDataContext,
} from 'components/ShiftDistributionRulesTieringModal/views/ShiftDistributionLocation.data.context';
import {
  SharingDetailsDataProvider,
  useSharingDetailsDataContext,
} from 'components/ShiftDistributionRulesTieringModal/views/SharingDetails.data.context';
import { useTranslation } from 'hooks/useTranslation';

const SharingDetailsViewComponent: FC = () => {
  const { onClose } = useTieringDetailsModalUIContext();
  const { sharingCommunities } = useSharingDetailsDataContext();

  const { locationName } = useShiftDistributionLocationDataContext();

  const { t } = useTranslation();

  const nrOfSharingCommunities = sharingCommunities ? sharingCommunities.length : 0;

  return (
    <Flex
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      width="100%"
      flexGrow={1}
    >
      <SharingDetailsWrapper>
        <H3>{locationName}</H3>
        <Flex flexDirection="column" width="100%">
          <SharingHeader>{`${t(
            'location_settings:LOCATIONS_IN_SHARING',
          )} (${nrOfSharingCommunities})`}</SharingHeader>
          <TieringRulesTableHeaderDivider />
          {sharingCommunities &&
            sharingCommunities.map(
              (community: fetchSharingDetails_fetchCommunities_nodes, i: number) => {
                const isGrey = i % 2;
                return (
                  <TieringDetailsTableContentCell isGrey={!!isGrey} key={i}>
                    {community.name}
                  </TieringDetailsTableContentCell>
                );
              },
            )}
        </Flex>
      </SharingDetailsWrapper>
      <BKJSideModalFooter justifyContent="space-between" flexDirection="row-reverse">
        <BKJButton variant="PurpleSolid" width="180px" onClick={onClose}>
          {t('common:CLOSE')}
        </BKJButton>
      </BKJSideModalFooter>
    </Flex>
  );
};

export const SharingDetails: FC = () => {
  return (
    <SharingDetailsDataProvider>
      <ShiftDistributionLocationDataProvider>
        <SharingDetailsViewComponent />
      </ShiftDistributionLocationDataProvider>
    </SharingDetailsDataProvider>
  );
};
