import { Flex } from 'components/Flex';
import { BKJButton, BKJTextInput } from '@bookjane2/bookjane-design-library';
import { FC, MouseEventHandler, useCallback, useMemo, useState } from 'react';
import { BKJSideModalFooter } from 'components/BKJSideModalFooter';
import {
  GroupDetailsHeaderBorderStyle,
  GroupsDetailsWrapper,
  H3,
} from 'components/GroupsModal/GroupsModal.styled';
import {
  GroupTypeDescription,
  GroupTypeSelectionRow,
  GroupTypeSelectionWrapper,
  GroupTypeSubHeader,
} from 'components/GroupsModal/views/NewGroup.styled';
import { useGroupsDetailsModalUIContext } from 'components/GroupsModal/GroupsModal.ui.context';
import { isString } from 'utils/isString';
import {
  NewGroupDataProvider,
  useNewGroupDataContext,
} from 'components/GroupsModal/views/NewGroup.data.context';
import { GroupType } from 'components/GroupsModal/GroupsModal.ui.context';
import { BKJRadioInput } from 'components/BKJRadioInput/BKJRadioInput';
import { useGroupsModalFormContext } from 'components/GroupsModal/GroupsModal.form.context';
import { ChangeEventType } from '@bookjane2/bookjane-design-library/lib/common.types';
import { useTranslation } from 'hooks/useTranslation';
import { useSelector } from 'react-redux';
import { isDynamicGroupsFeatureEnabledSelector } from 'store/selectors/featureFlagSelectors';
import { useLocation } from 'react-router-dom';

const NewGroupComponent: FC = () => {
  const { data, onChange: filterChange } = useNewGroupDataContext();
  const { goToNewGroupView } = useGroupsDetailsModalUIContext();
  const {
    values: { name },
    onChange,
  } = useGroupsModalFormContext();

  const { pathname } = useLocation();
  const location_id = Number.parseInt(pathname.split('/')[3]);
  const isDynamicGroupsFeatureEnabled = useSelector(
    isDynamicGroupsFeatureEnabledSelector(location_id),
  );

  const [groupType, setGroupType] = useState<GroupType>(
    isDynamicGroupsFeatureEnabled ? 'DynamicGroup' : 'StaticGroup',
  );
  const onChangeGroup = useCallback((e) => {
    setGroupType(e.target.value as GroupType);
  }, []);

  const { t } = useTranslation();

  const nameFieldErrors = useMemo(() => {
    const errorMsgs = [];

    if (isString(name)) {
      if (name.trim().length > 50) errorMsgs.push(t('groups:EXCEEDED_MAX_LENGTH_CHARS'));
      if (data && !!data?.fetchCommunity?.groups?.nodes?.some((group) => group.name === name))
        errorMsgs.push(t('groups:GROUP_NAME_EXISTS'));
    }
    return [...new Set(errorMsgs)];
  }, [data, name, t]);

  return (
    <Flex
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      width="100%"
      flexGrow={1}
      borderTop={GroupDetailsHeaderBorderStyle}
    >
      <GroupsDetailsWrapper>
        <H3 paddingBottom="28px">{t('groups:CREATE_NEW_GROUP')}</H3>
        <GroupTypeSelectionWrapper $isGrey={!isDynamicGroupsFeatureEnabled}>
          <GroupTypeSelectionRow>
            <BKJRadioInput
              label=""
              name="groupType"
              value={'DynamicGroup'}
              disabled={!isDynamicGroupsFeatureEnabled}
              checked={groupType === 'DynamicGroup'}
              onChange={onChangeGroup}
            />
            <GroupTypeSubHeader>{t('groups:BASED_ON_CRITERIA')}</GroupTypeSubHeader>
          </GroupTypeSelectionRow>
          <GroupTypeSelectionRow>
            <GroupTypeDescription>{t('groups:BASED_ON_CRITERIA_DESCRIPTION')}</GroupTypeDescription>
          </GroupTypeSelectionRow>
        </GroupTypeSelectionWrapper>
        <GroupTypeSelectionWrapper>
          <GroupTypeSelectionRow>
            <BKJRadioInput
              label=""
              name="groupType"
              value={'StaticGroup'}
              checked={groupType === 'StaticGroup'}
              onChange={onChangeGroup}
            />
            <GroupTypeSubHeader>{t('groups:BY_MANUAL_SELECTION')}</GroupTypeSubHeader>
          </GroupTypeSelectionRow>
          <GroupTypeSelectionRow>
            <GroupTypeDescription>
              {t('groups:BY_MANUAL_SELECTION_DESCRIPTION')}
            </GroupTypeDescription>
          </GroupTypeSelectionRow>
        </GroupTypeSelectionWrapper>
        <Flex padding="10px 0 0 0">
          <BKJTextInput
            label={t('groups:GROUP_NAME_LABEL')}
            type="text"
            placeholder={t('groups:GROUP_NAME_PLACEHOLDER')}
            value={name}
            name="name"
            error={nameFieldErrors}
            onChange={(e: ChangeEventType) => {
              filterChange({
                target: {
                  name: 'withName',
                  value: e.target.value,
                },
              });
              return onChange(e);
            }}
          />
        </Flex>
      </GroupsDetailsWrapper>
      <Flex height="100%"></Flex>
      <BKJSideModalFooter justifyContent="space-between" flexDirection="row-reverse">
        <BKJButton
          variant="PurpleSolid"
          width="180px"
          onClick={() =>
            goToNewGroupView({ groupType }) as unknown as MouseEventHandler<HTMLButtonElement>
          }
          type="button"
          disabled={!name || !!nameFieldErrors.length}
        >
          {t('groups:NEXT')}
        </BKJButton>
      </BKJSideModalFooter>
    </Flex>
  );
};

export const NewGroup: FC = () => {
  return (
    <NewGroupDataProvider>
      <NewGroupComponent />
    </NewGroupDataProvider>
  );
};
