import { useFormBehaviors } from 'hooks';
import { createContext, FC, useCallback, useContext, useMemo, useState } from 'react';
import { UseFormBehaviors } from 'hooks/useFormBehaviors/useFormBehaviors.types';
import { useAPIActions } from 'store/reducers/api/apiSlice';
import { useLocation } from 'react-router-dom';
import { API_POST_createStaticGroup } from 'requests/POST_createStaticGroup';
import { toastService } from 'services';
import { useGroupsDetailsModalUIContext } from 'components/GroupsModal/GroupsModal.ui.context';
import { useGroupsModalDataContext } from 'components/GroupsModal/GroupsModal.data.context';
import { API_DELETE_group } from 'requests/DELETE_group';
import {
  fetchGroupDetails_node_DynamicGroup,
  fetchGroupDetails_node_StaticGroup,
  fetchGroupDetails_node_StaticGroup_groupJanes_nodes_jane,
  searchTeamMembersForGroup_fetchJanes_edges_node,
} from 'types/graphql-types';
import { API_PATCH_updateStaticGroup } from 'requests/PATCH_updateStaticGroup';
import { API_PATCH_updateDynamicGroup } from 'requests/PATCH_updateDynamicGroup';
import {
  criteriaTimeValidation,
  dropdownValueToNumber,
  timeCriteriaToHoursMins,
  generateGroupPatch,
  generateMemberInitialState,
  staticGroupInitialFormValues,
  convertCriteriaDropdownsToRequestPayload,
} from 'components/GroupsModal/GroupsModal.form.constants';
import {
  GroupsModalForm,
  IGroupInfo,
  IGroupModalFormActions,
  ISelectCriteriaFormValidation,
} from 'components/GroupsModal/GroupsModal.form.types';
import { useTranslation } from 'hooks/useTranslation';
import { GroupsDetailsModalViewType } from 'components/GroupsModal/GroupsModal.constants';
import { API_POST_createDynamicGroup } from 'requests/POST_createDynamicGroup';
import { useSelector } from 'react-redux';
import { isEnhancedDynamicGroupsFeatureEnabledSelector } from 'store/selectors/featureFlagSelectors';

export const GroupsModalFormContext = createContext(
  {} as UseFormBehaviors<GroupsModalForm> &
    IGroupModalFormActions &
    ISelectCriteriaFormValidation &
    IGroupInfo,
);

export const GroupsModalFormProvider: FC = ({ children }) => {
  const { apiRequest } = useAPIActions();
  const { pathname } = useLocation();
  const location_id = Number.parseInt(pathname.split('/')[3]);
  const [deletedMemberIds, setDeletedMemberIds] = useState<Set<string>>(new Set<string>());
  const { groupsActiveModalView, onClose } = useGroupsDetailsModalUIContext();
  const isEnhancedDynamicGroupsFeatureEnabled = useSelector(
    isEnhancedDynamicGroupsFeatureEnabledSelector(location_id),
  );
  const { data } = useGroupsModalDataContext();
  const [members, setMembers] = useState<
    fetchGroupDetails_node_StaticGroup_groupJanes_nodes_jane[]
  >(generateMemberInitialState(data));
  const { t } = useTranslation();

  const addMember = useCallback(
    async (node: searchTeamMembersForGroup_fetchJanes_edges_node) => {
      const jane_ids = [...new Set(members.map((member) => member.id))];
      if (!jane_ids.includes(node.id)) {
        setMembers([
          ...members,
          node as unknown as fetchGroupDetails_node_StaticGroup_groupJanes_nodes_jane,
        ]);
      }
    },
    [members],
  );

  const handleSuccess = useCallback(async () => {
    toastService.success(t('success:GROUP_SAVED'));
    onClose();
  }, [onClose, t]);

  const handleCreationError = useCallback(
    () => (e: { data: { name: string[] } }) => {
      if (e && e.data && e.data.name && e.data.name.length > 0)
        toastService.error(`${e.data.name.join(', ')}`);
      else toastService.error(t('groups:GROUPS_CREATION_ERROR_MSG'));
    },
    [t],
  );
  const handleUpdateError = useCallback(
    (e: { data: { name: string[] } }) => {
      if (e && e.data && e.data.name && e.data.name.length > 0)
        toastService.error(`${e.data.name.join(', ')}`);
      else toastService.error(t('groups:GROUPS_UPDATE_ERROR_MSG'));
    },
    [t],
  );

  const handleStaticCreate = useCallback(
    async ({ name }) => {
      const jane_ids = members.map((member) => member.id);

      return await apiRequest(
        API_POST_createStaticGroup({
          community_id: location_id,
          name,
          members: jane_ids,
        }),
        {
          onSuccess: handleSuccess,
          onError: handleCreationError,
        },
      );
    },
    [members, apiRequest, location_id, handleSuccess, handleCreationError],
  );

  const handleDynamicCreate = useCallback(
    async ({
      name,
      employmentStatuses,
      positionTypes,
      minimumHoursPeriod,
      minimumMinsPeriod,
      maximumHoursPeriod,
      maximumMinsPeriod,

      minimumHoursDay,
      minimumMinsDay,
      maximumHoursDay,
      maximumMinsDay,
    }) => {
      return await apiRequest(
        API_POST_createDynamicGroup({
          community_id: location_id,
          name,
          employment_statuses: employmentStatuses,
          position_types: positionTypes,
          ...(isEnhancedDynamicGroupsFeatureEnabled &&
            convertCriteriaDropdownsToRequestPayload({
              minimumHoursPeriod,
              minimumMinsPeriod,
              maximumHoursPeriod,
              maximumMinsPeriod,

              minimumHoursDay,
              minimumMinsDay,
              maximumHoursDay,
              maximumMinsDay,
            })),
        }),
        {
          onSuccess: handleSuccess,
          onError: handleCreationError,
        },
      );
    },
    [
      apiRequest,
      location_id,
      isEnhancedDynamicGroupsFeatureEnabled,
      handleSuccess,
      handleCreationError,
    ],
  );

  const handleStaticUpdate = useCallback(
    async ({ name }) => {
      return await apiRequest(
        API_PATCH_updateStaticGroup({
          name,
          groupId: data?.displayId,
          ...generateGroupPatch(data as fetchGroupDetails_node_StaticGroup, members),
        }),
        {
          onSuccess: handleSuccess,
          onError: handleUpdateError,
        },
      );
    },
    [apiRequest, data, members, handleSuccess, handleUpdateError],
  );
  const handleDynamicUpdate = useCallback(
    async ({
      name,
      employmentStatuses,
      positionTypes,

      minimumHoursPeriod,
      minimumMinsPeriod,
      maximumHoursPeriod,
      maximumMinsPeriod,

      minimumHoursDay,
      minimumMinsDay,
      maximumHoursDay,
      maximumMinsDay,
    }) => {
      return await apiRequest(
        API_PATCH_updateDynamicGroup({
          name,
          groupId: data.displayId,
          employment_statuses: employmentStatuses,
          position_types: positionTypes,
          ...(isEnhancedDynamicGroupsFeatureEnabled &&
            convertCriteriaDropdownsToRequestPayload({
              minimumHoursPeriod,
              minimumMinsPeriod,
              maximumHoursPeriod,
              maximumMinsPeriod,

              minimumHoursDay,
              minimumMinsDay,
              maximumHoursDay,
              maximumMinsDay,
            })),
        }),
        {
          onSuccess: handleSuccess,
          onError: handleUpdateError,
        },
      );
    },
    [
      apiRequest,
      data?.displayId,
      handleSuccess,
      handleUpdateError,
      isEnhancedDynamicGroupsFeatureEnabled,
    ],
  );

  const handleDeleteGroup = useCallback(async () => {
    return await apiRequest(API_DELETE_group(data.displayId), {
      onSuccess: handleSuccess,
      onError: () => {
        toastService.error(t('groups:GROUPS_UPDATE_ERROR_MSG'));
      },
    });
  }, [apiRequest, data, handleSuccess, t]);

  const handleDeleteMember = useCallback(
    (memberId: string) => {
      const deletedMember = members.filter((member) => member.id === memberId);
      if (deletedMember.length) {
        setDeletedMemberIds(new Set([...deletedMemberIds, deletedMember[0].id]));
        setMembers(members.filter((member) => member.id !== memberId));
      }
    },
    [deletedMemberIds, members],
  );

  const routeSubmit = useCallback(
    (groupsActiveModalView: GroupsDetailsModalViewType) => {
      switch (groupsActiveModalView) {
        case 'AddMembers':
          return handleStaticCreate;
        case 'EditMembers':
          return handleStaticUpdate;
        case 'SelectCriteria':
          return handleDynamicCreate;
        case 'EditCriteria':
          return handleDynamicUpdate;
      }
    },
    [handleDynamicCreate, handleStaticCreate, handleDynamicUpdate, handleStaticUpdate],
  );

  const dynamicGroupData = data as fetchGroupDetails_node_DynamicGroup;

  const enhancedDynamicGroupInitialFormValues = useMemo(() => {
    const period = {
      minimum: timeCriteriaToHoursMins(dynamicGroupData?.criteria?.minimumPerPeriodMinutes),
      maximum: timeCriteriaToHoursMins(dynamicGroupData?.criteria?.maximumPerPeriodMinutes),
    };

    const day = {
      minimum: timeCriteriaToHoursMins(dynamicGroupData?.criteria?.minimumPerDayMinutes),
      maximum: timeCriteriaToHoursMins(dynamicGroupData?.criteria?.maximumPerDayMinutes),
    };

    return {
      minimumHoursPeriod: period.minimum.hours as GroupsModalForm['minimumHoursPeriod'],
      minimumMinsPeriod: period.minimum.mins as GroupsModalForm['minimumMinsPeriod'],
      maximumHoursPeriod: period.maximum.hours as GroupsModalForm['maximumHoursPeriod'],
      maximumMinsPeriod: period.maximum.mins as GroupsModalForm['maximumMinsPeriod'],

      minimumHoursDay: day.minimum.hours as GroupsModalForm['minimumHoursDay'],
      minimumMinsDay: day.minimum.mins as GroupsModalForm['minimumMinsDay'],
      maximumHoursDay: day.maximum.hours as GroupsModalForm['maximumHoursDay'],
      maximumMinsDay: day.maximum.mins as GroupsModalForm['maximumMinsDay'],
    };
  }, [
    dynamicGroupData?.criteria?.maximumPerDayMinutes,
    dynamicGroupData?.criteria?.maximumPerPeriodMinutes,
    dynamicGroupData?.criteria?.minimumPerDayMinutes,
    dynamicGroupData?.criteria?.minimumPerPeriodMinutes,
  ]);

  const dynamicGroupInitialFormValues = useMemo(() => {
    return {
      employmentStatuses:
        (dynamicGroupData?.criteria
          ?.employmentStatuses as unknown as GroupsModalForm['employmentStatuses']) ??
        ([] as GroupsModalForm['employmentStatuses']),
      positionTypes:
        dynamicGroupData?.criteria?.positionTypes ?? ([] as GroupsModalForm['positionTypes']),

      ...enhancedDynamicGroupInitialFormValues,
    };
  }, [
    dynamicGroupData?.criteria?.employmentStatuses,
    dynamicGroupData?.criteria?.positionTypes,
    enhancedDynamicGroupInitialFormValues,
  ]);

  const form = useFormBehaviors({
    initialState: {
      groupType: data?.__typename,

      name: data?.name,

      // Static Group
      ...staticGroupInitialFormValues,

      // Dynamic Group
      ...dynamicGroupInitialFormValues,
    },
    validations: {},
    onSubmit: routeSubmit(groupsActiveModalView),
  });

  const minPeriodValidation = useMemo(() => {
    const minHrs = dropdownValueToNumber(form.values.minimumHoursPeriod);
    const maxHrs = dropdownValueToNumber(form.values.maximumHoursPeriod);

    const minMins = dropdownValueToNumber(form.values.minimumMinsPeriod);
    const maxMins = dropdownValueToNumber(form.values.maximumMinsPeriod);

    return criteriaTimeValidation({ minHrs, maxHrs, minMins, maxMins });
  }, [
    form.values.maximumHoursPeriod,
    form.values.maximumMinsPeriod,
    form.values.minimumHoursPeriod,
    form.values.minimumMinsPeriod,
  ]);

  const minDayValidation = useMemo(() => {
    const minHrs = dropdownValueToNumber(form.values.minimumHoursDay);
    const maxHrs = dropdownValueToNumber(form.values.maximumHoursDay);

    const minMins = dropdownValueToNumber(form.values.minimumMinsDay);
    const maxMins = dropdownValueToNumber(form.values.maximumMinsDay);

    return criteriaTimeValidation({ minHrs, maxHrs, minMins, maxMins });
  }, [
    form.values.maximumHoursDay,
    form.values.maximumMinsDay,
    form.values.minimumHoursDay,
    form.values.minimumMinsDay,
  ]);

  const value = useMemo(() => {
    return {
      ...form,
      groupDisplayId: data?.displayId || -1,
      onChange: form.onChange,
      onDelete: handleDeleteGroup,
      toggleMember: ({
        target: { node, isChecked },
      }: {
        target: {
          node: searchTeamMembersForGroup_fetchJanes_edges_node;
          isChecked: boolean;
        };
      }) => {
        if (node && node.id) {
          if (isChecked) handleDeleteMember(node.id);
          else addMember(node);
        }
      },
      minPeriodValidation,
      minDayValidation,
      deletedMemberIds,
      deleteMember: handleDeleteMember,
      formMembers: members,
    };
  }, [
    addMember,
    data?.displayId,
    deletedMemberIds,
    form,
    handleDeleteGroup,
    handleDeleteMember,
    members,
    minDayValidation,
    minPeriodValidation,
  ]);

  return (
    <GroupsModalFormContext.Provider value={value}>{children}</GroupsModalFormContext.Provider>
  );
};

export const useGroupsModalFormContext = () => useContext(GroupsModalFormContext);
