import { FC, createContext, useContext, useMemo } from 'react';
import { useShiftModalDataContext } from 'components/ShiftModal/ShiftModalProvider.data.context';
import { IShiftModalPropsContext } from 'components/ShiftModal/ShiftModal.types';
import { shiftModalRoutingService } from 'components/ShiftModal/ShiftModal.ui.context';
import { OrderStatusEnum } from 'types/graphql-types';
import { getCurrentlySelectedLocation } from 'utils/getCurrentlySelectedLocation';
import { isMultiLocationFeatureEnabledSelector } from 'store/selectors/featureFlagSelectors';
import { useSelector } from 'react-redux';

export const ShiftModalPropsContext = createContext<IShiftModalPropsContext>(
  {} as IShiftModalPropsContext,
);

export const ShiftModalPropsProvider: FC = ({ children }) => {
  const { data } = useShiftModalDataContext();

  const currentlySelectedLocation = getCurrentlySelectedLocation();

  const isMultiLocationFeatureEnabled = useSelector(
    isMultiLocationFeatureEnabledSelector(Number(currentlySelectedLocation?.value)),
  );

  const isSharedJanePrimaryCommunityDifferentFromSelectedLocation =
    isMultiLocationFeatureEnabled &&
    data &&
    data.fetchOrder?.status?.value !== OrderStatusEnum.accepted &&
    !data.fetchOrder?.mainJane?.agencyCommunity &&
    data.fetchOrder?.mainJane?.primaryCommunity?.id !==
      currentlySelectedLocation?.value?.toString();

  const contextValue = useMemo(
    () => ({
      isSharedJanePrimaryCommunityDifferentFromSelectedLocation,
      goToDeleteView: () => shiftModalRoutingService.goToView('DeleteView'),
      goToEditView: () => shiftModalRoutingService.goToView('EditView'),
      goToReleaseShiftView: () => shiftModalRoutingService.goToView('ReleaseShiftView'),
      goToSendToAgenciesView: () => shiftModalRoutingService.goToView('PreRequestOptionsView'),
      goToAwardTeamMemberView: () => shiftModalRoutingService.goToView('AwardTeamMemberView'),
      goToConfirmEditsView: () => shiftModalRoutingService.goToView('ConfirmEditsView'),
    }),
    [isSharedJanePrimaryCommunityDifferentFromSelectedLocation],
  );
  return (
    <ShiftModalPropsContext.Provider value={contextValue}>
      {children}
    </ShiftModalPropsContext.Provider>
  );
};

export const useShiftModalPropsContext = (): IShiftModalPropsContext =>
  useContext(ShiftModalPropsContext);
