import { FC, useContext, useEffect, useMemo } from 'react';
import { createGQLDataContext } from 'utils/createDataContext';
import { useGQLQuery } from 'hooks';
import {
  fetchSharingDetails,
  fetchSharingDetails_fetchCommunities_nodes,
} from 'types/graphql-types';
import { GQLAPIContextType } from 'types/common.types';
import { fetchSharingDetailsConfig } from 'components/ShiftDistributionRulesTieringModal/ShiftDistributionRulesTieringModal.constants';
import { SharingData } from 'components/ShiftDistributionRulesTieringModal/ShiftDistributionRulesTieringModal.types';

export const ShiftDistributionRulesTieringModalDataContext =
  createGQLDataContext<GQLAPIContextType<SharingData>>();

export const ShiftDistributionRulesTieringModalDataProvider: FC = ({ children }) => {
  const sharingDetailsData = useGQLQuery<
    fetchSharingDetails,
    fetchSharingDetails_fetchCommunities_nodes[],
    unknown
  >(fetchSharingDetailsConfig());

  useEffect(() => {
    sharingDetailsData.fetch();
  }, []); // eslint-disable-line

  const contextValues = useMemo(() => {
    return {
      sharingCommunities: [...sharingDetailsData.data],
    };
  }, [sharingDetailsData]);

  return (
    <ShiftDistributionRulesTieringModalDataContext.Provider value={contextValues}>
      {children}
    </ShiftDistributionRulesTieringModalDataContext.Provider>
  );
};

export const useShiftDistributionRulesTieringModalDataContext =
  (): GQLAPIContextType<SharingData> => useContext(ShiftDistributionRulesTieringModalDataContext);
