import { ShiftDistributionRulesGroupsModalComponent } from 'components/ShiftDistributionRulesGroupsModal/ShiftDistributionRulesGroupsModal.component';
import { ShiftDistributionRulesGroupsModalDataProvider } from 'components/ShiftDistributionRulesGroupsModal/ShiftDistributionRulesGroupsModal.data.context';
import { ShiftDistributionRulesGroupsModalUIProvider } from 'components/ShiftDistributionRulesGroupsModal/ShiftDistributionRulesGroupsModal.ui.context';

import { FC } from 'react';
import { useLocation } from 'react-router-dom';

export const ShiftDistributionRulesGroupsModal: FC = () => {
  const { pathname } = useLocation();
  const locationId = pathname && pathname.length > 2 ? pathname.split('/')[3] : 'default';
  return (
    <ShiftDistributionRulesGroupsModalUIProvider>
      <ShiftDistributionRulesGroupsModalDataProvider key={locationId}>
        <ShiftDistributionRulesGroupsModalComponent />
      </ShiftDistributionRulesGroupsModalDataProvider>
    </ShiftDistributionRulesGroupsModalUIProvider>
  );
};
