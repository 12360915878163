import { Flex } from 'components/Flex';
import { BKJButton } from '@bookjane2/bookjane-design-library';
import { FC, FormEvent } from 'react';
import { BKJSideModalFooter } from 'components/BKJSideModalFooter';
import {
  EmptyMemberView,
  GroupDetailsHeaderBorderStyle,
  GroupsDetailsWrapper,
  H3,
  SearchTeamMembersTitle,
} from 'components/GroupsModal/GroupsModal.styled';
import { IBKJComboBoxAsyncOption } from '@bookjane2/bookjane-design-library/lib/components/BKJComboBoxAsync/BKJComboBoxAsync.types';
import { useLocation } from 'react-router-dom';
import { useGroupsModalFormContext } from 'components/GroupsModal/GroupsModal.form.context';
import { MemberTableComponent } from 'components/GroupsModal/views/static/MemberTable.component';
import { SearchTeamMembersGroups } from 'components/GroupsModal/components/SearchTeamMembersGroups';
import { useTranslation } from 'hooks/useTranslation';

export const EditMembersComponent: FC<{ location: IBKJComboBoxAsyncOption }> = ({ location }) => {
  const { isSubmitting, values, groupDisplayId, toggleMember, onSubmit, formMembers } =
    useGroupsModalFormContext();
  const { t } = useTranslation();
  return (
    <Flex
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      minWidth="100%"
      flexGrow={1}
      borderTop={GroupDetailsHeaderBorderStyle}
    >
      <GroupsDetailsWrapper>
        <H3 paddingBottom="28px">{t('groups:EDIT_GROUP_MEMBERS')}</H3>
        <SearchTeamMembersTitle>{t('groups:ADD_TEAM_MEMBER')}</SearchTeamMembersTitle>
        <SearchTeamMembersGroups
          groupId={groupDisplayId}
          viewAllFacilities={false}
          location={location}
          name="memberSearch"
          placeholder={t('groups:SEARCH_MEMBER')}
          onChange={(e) => {
            toggleMember(e);
          }}
          value={values.memberSearch}
        />
      </GroupsDetailsWrapper>
      {formMembers.length < 1 && (
        <EmptyMemberView>{t('groups:SEARCH_MEMBER_CUSTOM_LIST')}</EmptyMemberView>
      )}
      <MemberTableComponent />
      <BKJSideModalFooter justifyContent="space-between" flexDirection="row">
        <div>
          {t('groups:GROUP_MEMBERS')}: {formMembers.length}
        </div>
        <BKJButton
          variant="PurpleSolid"
          width="180px"
          type="submit"
          disabled={formMembers.length < 1}
          isLoading={isSubmitting}
          onClick={(e) => onSubmit(e as unknown as FormEvent<HTMLFormElement>)}
        >
          {t('groups:SAVE')}
        </BKJButton>
      </BKJSideModalFooter>
    </Flex>
  );
};

export const EditMembers: FC = () => {
  const { pathname } = useLocation();
  const location_id = Number.parseInt(pathname.split('/')[3]);
  const location = { value: location_id } as IBKJComboBoxAsyncOption;
  return <EditMembersComponent location={location} />;
};
