import { GQLAPIContextType } from 'types/common.types';
import { createGQLDataContext } from 'utils/createDataContext';
import {
  fetchGroupDetails,
  fetchGroupDetails_node_StaticGroup,
  fetchGroupDetails_node_DynamicGroup,
  fetchGroupDetailsVariables,
} from 'types/graphql-types';
import { FC, useContext, useEffect, useMemo } from 'react';
import { useGQLQuery } from 'hooks';
import { useLocation } from 'react-router-dom';
import { fetchGroupConfig } from 'components/GroupsModal/GroupsModal.constants';
import { useGroupsDetailsModalUIContext } from 'components/GroupsModal/GroupsModal.ui.context';

export const GroupsModalDataContext =
  createGQLDataContext<
    GQLAPIContextType<fetchGroupDetails_node_StaticGroup | fetchGroupDetails_node_DynamicGroup>
  >();

export const GroupsModalDataProvider: FC = ({ children }) => {
  const { pathname } = useLocation();
  const location_id = pathname.split('/')[3];
  const { groupId } = useGroupsDetailsModalUIContext();

  const { data, fetch, ...restQuery } = useGQLQuery<
    fetchGroupDetails,
    Nullable<fetchGroupDetails_node_StaticGroup | fetchGroupDetails_node_DynamicGroup>,
    fetchGroupDetailsVariables
  >(fetchGroupConfig(groupId));

  useEffect(() => {
    if (location_id && groupId) fetch();
  }, [fetch, groupId, location_id]);

  const contextValues = useMemo(() => {
    return {
      data,
      fetch,
      ...restQuery,
    };
  }, [data, fetch, restQuery]);

  return (
    <GroupsModalDataContext.Provider value={contextValues}>
      {children}
    </GroupsModalDataContext.Provider>
  );
};

export const useGroupsModalDataContext = (): GQLAPIContextType<
  fetchGroupDetails_node_StaticGroup | fetchGroupDetails_node_DynamicGroup
> => useContext(GroupsModalDataContext);
