import { Flex } from 'components/Flex';

import {
  DeleteButton,
  UnableToEditShiftMessage,
} from 'components/ShiftModal/src/views/DetailView/DetailTabView/DetailTabView.styled';
import { BKJButton } from '@bookjane2/bookjane-design-library';
import { Fragment } from 'react';
import { useTranslation } from 'hooks/useTranslation';
import { UserGuard } from 'guards/UserGuard';
import { UserPermissionEnum } from 'guards/UserGuard.types';
import { IDetailViewFooterProps } from 'components/ShiftModal/src/views/DetailView/DetailView.types';
import { useSelector } from 'react-redux';
import { isEditCompletedShiftsFeatureEnabledSelector } from 'store/selectors/featureFlagSelectors';
import { OrderStatusEnum, TimeSheetStatusEnum } from 'types/graphql-types';
import { getCurrentlySelectedLocation } from 'utils/getCurrentlySelectedLocation';

export function MobileDetailViewFooter(props: IDetailViewFooterProps) {
  const { t } = useTranslation();
  const {
    data,
    openConfirmAvailabilityModal,
    agencyCommunityId,
    isUserAgencyAndAgencyLocationFilterForcedDisabled,
    goToSendToAgenciesView,
    goToReleaseShiftView,
    goToEditView,
    goToDeleteView,
  } = props;

  const { value: locationId } = getCurrentlySelectedLocation();

  const isEditCompletedShiftsFeatureEnabled = useSelector(
    isEditCompletedShiftsFeatureEnabledSelector(Number.parseInt(locationId)),
  );

  const isCompletedShiftNotEditable =
    data.fetchOrder?.status?.value === OrderStatusEnum.completed &&
    !data.fetchOrder?.isEditable &&
    isEditCompletedShiftsFeatureEnabled;

  return (
    <Fragment>
      {data.fetchOrder!.isJaneReleaseAllowed &&
        data.fetchOrder?.status?.value !== OrderStatusEnum.in_progress && (
          <BKJButton width="auto" variant="NoStyles" onClick={goToReleaseShiftView}>
            {t('shift_modal:RELEASE')}
          </BKJButton>
        )}
      <UserGuard
        userType={[
          UserPermissionEnum.USER_TYPE_B2B,
          UserPermissionEnum.USER_TYPE_PREMIUM_PRIMARY,
          UserPermissionEnum.USER_TYPE_PREMIUM_SECONDARY,
        ]}
      >
        {data.fetchOrder!.canSendToAgency && (
          <BKJButton
            width="100%"
            padding="22px"
            variant="PurpleSolid"
            onClick={goToSendToAgenciesView}
          >
            {t('shift_modal:SEND_TO_AGENCY')}
          </BKJButton>
        )}
      </UserGuard>
      <UserGuard
        userType={[
          UserPermissionEnum.USER_TYPE_AGENCY,
          UserPermissionEnum.USER_TYPE_AGENCY_PRIMARY,
          UserPermissionEnum.USER_TYPE_AGENCY_SECONDARY,
        ]}
      >
        {data.fetchOrder?.canAssign &&
          (!isUserAgencyAndAgencyLocationFilterForcedDisabled ? !!agencyCommunityId : true) && (
            <BKJButton
              width="100%"
              padding="22px"
              variant="PurpleSolid"
              onClick={openConfirmAvailabilityModal}
            >
              {t('shift_modal:ASSIGN')}
            </BKJButton>
          )}
      </UserGuard>

      <Flex width="100%" justifyContent="center" gap="48px" margin="20px 0 0">
        <Flex flexDirection="column">
          {(!!data.fetchOrder?.isEditable || isCompletedShiftNotEditable) && (
            <BKJButton
              width="auto"
              variant="NoStyles"
              onClick={goToEditView}
              disabled={!data.fetchOrder?.isEditable}
            >
              {t('shift_modal:EDIT')}
            </BKJButton>
          )}
          {isCompletedShiftNotEditable &&
          data.fetchOrder?.orderSchedule.timeSheet?.status.value ===
            TimeSheetStatusEnum.approved ? (
            <Flex margin="10px 0 0 0">
              <UnableToEditShiftMessage>
                {t('shift_modal:UNABLE_TO_EDIT_SHIFT_AFTER_TIME_SHEET_APPROVAL')}
              </UnableToEditShiftMessage>
            </Flex>
          ) : isCompletedShiftNotEditable &&
            data.fetchOrder?.orderSchedule.timeSheet?.status.value ===
              TimeSheetStatusEnum.no_show ? (
            <Flex margin="10px 0 0 0">
              <UnableToEditShiftMessage>
                {t('shift_modal:UNABLE_TO_EDIT_SHIFT_AFTER_TIME_SHEET_NO_SHOW')}
              </UnableToEditShiftMessage>
            </Flex>
          ) : (
            isCompletedShiftNotEditable && (
              <Flex margin="10px 0 0 0">
                <UserGuard userType={[UserPermissionEnum.USER_TYPE_B2B]}>
                  <UnableToEditShiftMessage>
                    {t('shift_modal:UNABLE_TO_EDIT_SHIFT_POST_COMPLETION', {
                      hours:
                        data.fetchOrder.community.b2bUserShiftEditThreshold &&
                        Math.floor(data.fetchOrder.community.b2bUserShiftEditThreshold),
                    })}
                  </UnableToEditShiftMessage>
                </UserGuard>
                <UserGuard userType={[UserPermissionEnum.USER_TYPE_PREMIUM_SECONDARY]}>
                  <UnableToEditShiftMessage>
                    {t('shift_modal:UNABLE_TO_EDIT_SHIFT_POST_COMPLETION', {
                      hours:
                        data.fetchOrder.community.secondaryUserShiftEditThreshold &&
                        Math.floor(data.fetchOrder.community.secondaryUserShiftEditThreshold),
                    })}
                  </UnableToEditShiftMessage>
                </UserGuard>
              </Flex>
            )
          )}
        </Flex>
        <UserGuard
          userType={[
            'USER_TYPE_B2B',
            'USER_TYPE_PREMIUM_SECONDARY',
            'USER_TYPE_ESSENTIAL_SECONDARY',
          ]}
        >
          {!!data.fetchOrder?.isEditable && (
            <Flex>
              <DeleteButton variant="RedText" onClick={goToDeleteView}>
                {t('shift_modal:DELETE')}
              </DeleteButton>
            </Flex>
          )}
        </UserGuard>
      </Flex>
    </Fragment>
  );
}
