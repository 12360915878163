import { BKJBadge } from '@bookjane2/bookjane-design-library';
import { ReactNode } from 'react';
import { OrderStatusEnum } from 'types/graphql-types';

export interface IShiftStatusBadge {
  status: keyof typeof OrderStatusEnum;
  children: ReactNode;
}

enum ShiftStatusBadgeEnum {
  'accepted' = 'AcceptedPill',
  'assigned' = 'AssignedPill',
  'cancelled' = 'CancelledPill',
  'completed' = 'CompletedPill',
  'in_progress' = 'InProgressPill',
  'open' = 'OpenPill',
  'pending' = 'PendingPill',
  'rejected' = 'RejectedPill',
  'requested' = 'RequestedPill',
  'unfulfilled' = 'UnfulfilledPill',
  'swapped' = 'SwappedPill',
}
// design library change required

export const ShiftStatusBadge = ({ status, children, ...rest }: IShiftStatusBadge): JSX.Element => {
  return (
    <BKJBadge variant={ShiftStatusBadgeEnum[status]} {...rest}>
      {children}
    </BKJBadge>
  );
};
