import { Flex } from '@bookjane2/bookjane-design-library';
import styled, { css } from 'styled-components';
import { ShiftStatusTextToColorEnum, ShiftStatusTextToColorEnumKey } from 'types/common.types';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100%;
`;

export const PurpleText = styled.div`
  text-transform: capitalize;
  font-size: 16px;
  ${({ theme }) => theme.fonts.PoppinsBold};
  line-height: 1.5;
  color: #4e4785;
  margin-bottom: 12px;
`;

export const MainTitle = styled.h1`
  ${({ theme }) => theme.fonts.PoppinsBold};
  font-size: 28px;
  color: #45424e;
  line-height: 34px;
  margin-bottom: 8px;
`;

export const DropdownLabel = styled.div`
  ${({ theme }) => theme.fonts.PoppinsBold};
  font-size: 12px;
  margin-bottom: 12px;
  line-height: 1.21;
`;

export const FormText = styled.div`
  ${({ theme }) => theme.fonts.PoppinsTextMedium};
  font-size: 14px;
  margin-bottom: 12px;
  line-height: 1.5;
`;

export const StyledSVG = styled.svg`
  margin-right: 4px;
`;

export const ShiftStatusTextWrapper = styled.span`
  line-height: 24px;
  margin-bottom: 8px;
`;

export const AgencyAccept = styled.span`
  color: #45424e;
`;
function darkenColor(hex: string, factor: number): string {
  // Parse the hex color code and extract RGB components
  const red = parseInt(hex.slice(1, 3), 16);
  const green = parseInt(hex.slice(3, 5), 16);
  const blue = parseInt(hex.slice(5, 7), 16);
  // Darken the color by multiplying each RGB component by the factor
  const darkenedRed = Math.round(red * factor);
  const darkenedGreen = Math.round(green * factor);
  const darkenedBlue = Math.round(blue * factor);
  // Ensure that the darkened values stay within the valid range (0-255)
  const newRed = Math.min(255, darkenedRed);
  const newGreen = Math.min(255, darkenedGreen);
  const newBlue = Math.min(255, darkenedBlue);
  // Convert the darkened RGB values back to a hex color code
  const darkenedHex = `#${((1 << 24) | (newRed << 16) | (newGreen << 8) | newBlue)
    .toString(16)
    .slice(1)}`;
  return darkenedHex;
}
export const ShiftStatusText = styled.li<{ type: ShiftStatusTextToColorEnumKey }>`
  color: ${({ theme, type }) => darkenColor(theme.colors[ShiftStatusTextToColorEnum[type]], 0.7)};
  ${({ theme }) => theme.fonts.PoppinsText};
  font-size: 16px;
`;

export const Span = styled.span`
  position: relative;
  left: 8px;
`;

export const Text = styled.span`
  color: #45424e;
  display: block;
  ${({ theme }) => theme.fonts.PoppinsText};
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 12px;
`;

export const GreyText = styled.span`
  color: #8b96a3;
  display: block;
  ${({ theme }) => theme.fonts.PoppinsText};
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 4px;
`;

export const BoldText = styled.span`
  color: #45424e;
  ${({ theme }) => theme.fonts.PoppinsBold};
  font-size: inherit;
  line-height: inherit;
  margin-bottom: 4px;
`;

export const NoShowText = styled.span`
  color: #45424e;
  ${({ theme }) => theme.fonts.PoppinsBold};
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-transform: capitalize;
`;

export const FormGroup = styled.div`
  margin-bottom: 24px;
  color: #45424e;
  &:focus-within {
    color: #7975b6;
  }
`;
export const FormGroupVisible = styled(FormGroup)<{ isVisible: boolean }>`
  visibility: hidden;
  ${({ isVisible }) =>
    isVisible &&
    css`
      visibility: visible;
    `}
`;

export const InnerHeaderWrapper = styled.div`
  padding: 32px 32px 30px 32px;
  border-bottom: 1px solid #dfe3e9;
  @media (max-width: ${({ theme }) => theme.breakpoints.Mobile}) {
    padding: 32px 20px;
  }
`;

export const NavigationButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  border-bottom: 1px solid #dfe3e9;
`;

export const NavigationButton = styled.button<{ isDisabled: boolean; isActive: boolean }>`
  position: relative;
  background-color: none;
  font-size: 14px;
  line-height: 21px;
  color: #45424e;
  height: 48px;
  min-height: 48px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  outline: none;
  padding: 0;
  transition: all 100ms linear;
  overflow: hidden;
  cursor: pointer;
  flex: 1 1 0px;
  ${({ theme }) => theme.fonts.PoppinsText};
  ${({ isDisabled }) =>
    isDisabled &&
    css`
      color: rgba(69, 66, 78, 0.3);
      pointer-events: none;
    `};

  &:hover {
    ${({ isActive }) =>
      !isActive &&
      css`
        &::after {
          transition: min-width 500ms ease-out, background-color 600ms linear;
          height: 4px;
          min-width: 35%;
          background-color: #8b96a3;
        }
      `};
  }
  &::after {
    will-change: min-width, background-color;
    transition: min-width 200ms ease-out, background-color 300ms linear;
    content: '';
    position: absolute;
    left: 50%;
    right: 50%;
    transform: translate(-50%, -50%);
    bottom: -2px;
    min-width: 0px;
    height: 4px;
    background-color: #00c18c00;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }
  ${({ isActive }) =>
    isActive &&
    css`
      &::after {
        height: 4px;
        min-width: calc(100% - 25px);
        background-color: #00c18c;
      }
      cursor: unset;
    `};
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const ButtonSpacer = styled.div`
  min-width: 20px;
`;

export const IconWrapper = styled.span`
  margin-right: 5px;
  display: inline-flex;
`;

export const DeleteButton = styled.button<{ isVisible: boolean }>`
  min-height: 48px;
  max-height: 48px;
  overflow: hidden;
  visibility: ${(props) => (props.isVisible ? 'visible' : 'hidden')};
  ${({ theme }) => theme.fonts.PoppinsBold};
  font-size: 16px;
  line-height: 1.25;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  transition: background-color 100ms linear;
  cursor: pointer;
  border-radius: 8px;
  letter-spacing: 0.27px;
  color: ${({ theme }) => theme.colors.ErrorDefault};
  background-color: transparent;
  margin-right: auto;
`;

export const ActualTimeDuration = styled.span`
  margin-bottom: 32px;
`;

export const Subtitle = styled.span`
  ${({ theme }) => theme.typography.H4};
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  font-size: 20px;
  margin-bottom: 32px;
`;

export const CommunityName = styled(Subtitle)`
  display: inline-flex;
  margin: 4px 0px;
`;

export const LabelText = styled.span`
  ${({ theme }) => theme.fonts.PoppinsTextBold};
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  margin: 0;
  font-size: 14px;
  line-height: 24px;
`;

export const TimeSheetsStartEndTimeContainer = styled(Flex)`
  flex-direction: row;
  @media (max-width: ${({ theme }) => theme.breakpoints.Mobile}) {
    flex-direction: column;
    gap: 30px;
  }
`;

export const ValueText = styled.span<{ marginLeft?: number; padding?: string }>`
  ${({ theme }) => theme.fonts.PoppinsText};
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  margin: 0;
  font-size: 16px;
  line-height: 24px;
  margin-left: ${({ marginLeft }) => (marginLeft ? `${marginLeft}px` : '0px')};
  padding: ${({ padding }) => (padding ? padding : '0')};
  word-break: break-all;
`;

export const Divider = styled.hr`
  color: ${({ theme }) => theme.colors.Grey5};
  width: 100%;
  height: 0.5px;
  margin: 32px 0px;
`;

export const Tags = styled.div`
  display: flex;

  * {
    margin-right: 8px;
  }
`;
