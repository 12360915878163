import { TieringData } from 'components/ShiftDistributionRulesTieringModal/ShiftDistributionRulesTieringModal.types';
import {
  SharingDetails,
  TieringDetails,
} from 'components/ShiftDistributionRulesTieringModal/views';
import { UseGQLQueryConfig } from 'hooks/useGQLQuery/useGQLQuery.types';
import {
  QUERY_shiftDistributionRulesSharingDetails,
  QUERY_shiftDistributionRulesTieringDetails,
} from 'queries';
import {
  fetchSharingDetails,
  fetchSharingDetails_fetchCommunities_nodes,
  fetchTieringDetails,
  fetchTieringDetailsVariables,
  fetchTieringDetails_fetchCommunity_communityFeatures,
} from 'types/graphql-types';

export const ShiftDistributionComponentMap = {
  SharingDetails,
  TieringDetails,
};
export type ShiftDistributionModalViewType = keyof typeof ShiftDistributionComponentMap;

export const extractTieringTypeFromCommunityFeatures = (
  communityFeatures: fetchTieringDetails_fetchCommunity_communityFeatures[] | null,
): 'Rotational' | 'Standard' => {
  const rotationalEnabled = communityFeatures?.find(
    (communityFeature) => communityFeature.feature?.name === 'ROTATIONAL',
  )?.enabled;

  return rotationalEnabled ? 'Rotational' : 'Standard';
};

export const fetchSharingDetailsConfig = () => {
  return {
    key: 'SHIFT_DISTRIBUTION_RULES_SHARING_MODAL_QUERY',
    query: QUERY_shiftDistributionRulesSharingDetails,
    responseTransformer: (
      data: fetchSharingDetails,
    ): fetchSharingDetails_fetchCommunities_nodes[] => {
      if (!data || !data.fetchCommunities || !data.fetchCommunities.nodes) {
        return [] as fetchSharingDetails_fetchCommunities_nodes[];
      }
      return data.fetchCommunities.nodes.filter((community) => {
        if (community && community?.communityFeatures) {
          const sharingFeature = community?.communityFeatures?.find(
            (communityFeature) =>
              communityFeature &&
              communityFeature?.feature &&
              communityFeature?.feature?.name === 'SHARING',
          );
          return !!sharingFeature;
        }
        return false;
      });
    },
  };
};

export const fetchTieringDetailsConfig = (
  locationId: number,
): UseGQLQueryConfig<
  fetchTieringDetails,
  TieringData,
  fetchTieringDetailsVariables,
  fetchTieringDetailsVariables
> => {
  return {
    key: 'SHIFT_DISTRIBUTION_RULES_TIERING_MODAL_QUERY',
    query: QUERY_shiftDistributionRulesTieringDetails,
    variables: {
      id: locationId,
    },
    responseTransformer: (data: fetchTieringDetails): TieringData => {
      let transformedData: TieringData = {
        shiftUrgency: { critical: -1, high: -1, medium: -1 },
        waitTime: { critical: -1, high: -1, medium: -1, low: -1 },
        tieringType: 'Standard',
      };

      if (!data) return transformedData;

      const {
        fetchCommunity: { escalationTimes, leadTimes, communityFeatures },
      } = data;

      leadTimes?.forEach((leadTime) => {
        switch (leadTime.priority) {
          case 'critical':
            transformedData.shiftUrgency.critical = leadTime.priorityTime;
            break;
          case 'high_priority':
            transformedData.shiftUrgency.high = leadTime.priorityTime;
            break;
          case 'vacation_loa':
            transformedData.shiftUrgency.medium = leadTime.priorityTime;
            break;
          default:
            console.log(`Received unknown leadTime: ${JSON.stringify(leadTime)}`);
            break;
        }
      });

      escalationTimes?.forEach((escalationTime) => {
        switch (escalationTime.priority) {
          case 'critical':
            transformedData.waitTime.critical = escalationTime.priorityTime;
            break;
          case 'high_priority':
            transformedData.waitTime.high = escalationTime.priorityTime;
            break;
          case 'vacation_loa':
            transformedData.waitTime.medium = escalationTime.priorityTime;
            break;
          case 'shift_exchange':
            transformedData.waitTime.low = escalationTime.priorityTime;
            break;
          default:
            console.log(`Received unknown escalationTime: ${JSON.stringify(escalationTime)}`);
            break;
        }
      });

      transformedData.tieringType = extractTieringTypeFromCommunityFeatures(communityFeatures);

      return transformedData;
    },
  };
};
