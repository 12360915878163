import { BKJButton, BKJTextInput } from '@bookjane2/bookjane-design-library';
import { BKJFormWrapper } from 'components/BKJFormWrapper';
import { Flex } from 'components/Flex';
import { SetupBanner } from 'components/SetupBanner/SetupBanner';

import {
  CompanyProfileHeader,
  CompanyProfilePageWrapper,
  CompanyProfileContentWrapper,
  CompanyProfileButtonWrapper,
  CompanyProfileImageWrapper,
} from 'pages/SettingsPage/CompanyProfilePage/CompanyProfilePage.styled';
import { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

import {
  CompanyProfilePageDataProvider,
  useCompanyProfilePageDataContext,
} from 'pages/SettingsPage/CompanyProfilePage/CompanyProfilePage.context';
import {
  CompanyProfilePageFormProvider,
  useCompanyProfilePageFormContext,
} from 'pages/SettingsPage/CompanyProfilePage/CompanyProfilePage.form.context';

import marketPlaceLogo from 'assets/marketPlaceLogo.svg';
import { LogoWrapper } from 'pages/SettingsPage/SettingsPage.styled';
const CompanyProfileView = () => {
  const { values, onChange, onSubmit, fieldErrors } = useCompanyProfilePageFormContext();

  const { t } = useTranslation();

  return (
    <CompanyProfilePageWrapper flexDirection="column" width="100%" padding="32px 40px">
      <CompanyProfileContentWrapper>
        <Flex flexDirection="column" flexGrow={1}>
          <CompanyProfileHeader data-testid="hdr-userprofile">
            {t('settings:COMPANY_PROFILE')}
          </CompanyProfileHeader>
          <div>{t('settings:GENERAL_DETAILS')}</div>

          <Flex padding="18px 0 0 0" maxWidth="500px">
            <BKJTextInput
              name="company_name"
              label={t('settings:COMPANY_NAME')}
              onChange={onChange}
              value={values?.company_name}
              error={fieldErrors?.company_name}
            />
          </Flex>
          <CompanyProfileImageWrapper>
            {t('settings:ACCOUNT_TYPE')}
            <LogoWrapper alt="marketPlaceLogo" src={marketPlaceLogo} size="500px" />
          </CompanyProfileImageWrapper>
          <CompanyProfileButtonWrapper>
            <BKJButton
              variant="PurpleSolid"
              width="192px"
              disabled={false}
              onClick={onSubmit as unknown as MouseEventHandler<HTMLButtonElement>}
              type="submit"
            >
              {t('auth:SAVE')}
            </BKJButton>
          </CompanyProfileButtonWrapper>
        </Flex>
      </CompanyProfileContentWrapper>
    </CompanyProfilePageWrapper>
  );
};

const CompanyProfileFormView = () => {
  const { data } = useCompanyProfilePageDataContext();
  if (!data) return null;
  return (
    <BKJFormWrapper>
      <CompanyProfilePageFormProvider>
        <SetupBanner />
        <CompanyProfileView />
      </CompanyProfilePageFormProvider>
    </BKJFormWrapper>
  );
};

export const CompanyProfilePage = () => {
  return (
    <CompanyProfilePageDataProvider>
      <CompanyProfileFormView />
    </CompanyProfilePageDataProvider>
  );
};
