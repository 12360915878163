import { Portal } from 'components/Portal/Portal';
import { QuerySwitch } from 'components/QuerySwitch';
import { IProps } from 'components/GroupsModal/components/SearchTeamMembersGroups';
import { searchTeamMembersGroupsPopperModifiers } from 'components/GroupsModal/components/SearchTeamMembersGroups/SearchTeamMembersGroups.constants';
import {
  CloseIconWrapper,
  DropdownWrapper,
  SearchTeamMembersGroupsWrapper,
} from 'components/GroupsModal/components/SearchTeamMembersGroups/SearchTeamMembersGroups.styled';
import { TeamMembersList } from 'components/GroupsModal/components/SearchTeamMembersGroups/src/TeamMemberList';
import { useClickOutside } from 'hooks';
import { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import { searchTeamMembersForGroup } from 'types/graphql-types';
import { mergeRefs } from 'utils';
import { getJ360ElementNode } from 'utils/getJ360ElementNode';
import { useGroupsModalFormContext } from 'components/GroupsModal/GroupsModal.form.context';
import {
  BKJSSFBarSearchInputWrapper,
  StyledBKJSSFBarSearchInput,
} from 'components/BKJSSFBarSearchInput/BKJSSFBarSearchInput.styled';
import { css } from 'styled-components';
import { BKJIcon } from '@bookjane2/bookjane-design-library';
import { IconButton } from 'components/IconButton';
import { SearchTeamMembersGroupsContext } from 'components/GroupsModal/components/SearchTeamMembersGroups/SearchTeamMembersGroups.context';

export const SearchTeamMembersGroupsComponent: FC<IProps> = ({
  name,
  onChange,
  value,
  placeholder,
  ...rest
}) => {
  const {
    values: queryContextValues,
    onChange: queryContextOnChange,
    loadMore,
  } = useContext(SearchTeamMembersGroupsContext);

  const { formMembers } = useGroupsModalFormContext();

  const dropdownRef = useRef<HTMLElement | null>(null);

  const [referenceElement, setReferenceElement] = useState<Element | null>(null);
  const [popperElement, setPopperElement] = useState(null);
  const [targetElement, setTargetElement] = useState<HTMLElement | null>(
    getJ360ElementNode('dropdown-root'),
  );
  const [isOpen, setOpen] = useState(false);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: searchTeamMembersGroupsPopperModifiers,
  });

  useEffect(() => {
    if (!targetElement) {
      const element = document.createElement('div');
      element.setAttribute('id', 'dropdown-root');
      document.body.appendChild(element);
      setTargetElement(element);
    }
  }, []); // eslint-disable-line

  const handleDropdownOpen = useCallback(() => {
    if (!isOpen) setOpen(true);
  }, [isOpen]);

  const handleDropdownClose = useCallback(() => {
    if (isOpen) setOpen(false);
  }, [isOpen]);

  const handleChange = useCallback(
    (event) => {
      queryContextOnChange(event);
      handleDropdownOpen();
    },
    [queryContextOnChange, handleDropdownOpen],
  );

  const handleSelect = useCallback(
    (event) => {
      const { value: nextValue } = event.target;
      const val = value === nextValue ? '' : event.target.value;
      onChange({ ...event, target: { ...event.target, name, value: val } });
    },
    [name, onChange, value],
  );

  useClickOutside(
    [dropdownRef && dropdownRef.current, targetElement && targetElement],
    handleDropdownClose,
  );

  const TeamMembersListWithProps = useCallback(
    ({ data }: { data: searchTeamMembersForGroup }) => (
      <TeamMembersList
        groupMemberIds={formMembers.map((member) => member.id)}
        data={data}
        onLoadMore={loadMore}
        name={name}
        value={value}
        onSelect={handleSelect}
      />
    ),
    [formMembers, handleSelect, loadMore, name, value],
  );

  const handleClearSearch = useCallback(() => {
    onChange({ target: { value: '', name: 'byFullName' } });
    queryContextOnChange({ target: { value: '', name: 'byFullName' } });
  }, [queryContextOnChange, onChange]);

  const [isFocused, setFocus] = useState<boolean>(false);

  const onFocused = useCallback(() => {
    if (!isFocused) setFocus(true);
  }, [isFocused]);

  const onBlur = useCallback(() => {
    if (isFocused) setFocus(false);
  }, [isFocused]);

  const nameInputValue = queryContextValues['byFullName'];

  return (
    <SearchTeamMembersGroupsWrapper isOpen={!!isOpen && !!targetElement}>
      <BKJSSFBarSearchInputWrapper
        ref={setReferenceElement}
        css={css`
          height: 36px;
          min-height: 36px;
          max-height: 36px;
        `}
      >
        <BKJIcon iconName="Search" />
        <StyledBKJSSFBarSearchInput
          {...rest}
          css={css`
            padding-left: 8px;
          `}
          name="byFullName"
          onChange={handleChange}
          onFocus={onFocused}
          onBlur={onBlur}
          role="combobox"
          type="text"
          placeholder={placeholder}
          value={nameInputValue}
          onClick={handleDropdownOpen}
          autoComplete="off"
        />
        {!!nameInputValue && (
          <CloseIconWrapper>
            <IconButton iconName="CloseBold" onClick={handleClearSearch} />
          </CloseIconWrapper>
        )}
      </BKJSSFBarSearchInputWrapper>
      {!!isOpen && !!targetElement && (
        <Portal targetElement={targetElement}>
          <DropdownWrapper
            ref={mergeRefs([dropdownRef, setPopperElement])}
            style={{ ...styles.popper }}
            {...attributes.popper}
          >
            <QuerySwitch
              loadingIconSize="XSmall"
              context={SearchTeamMembersGroupsContext}
              component={TeamMembersListWithProps}
            />
          </DropdownWrapper>
        </Portal>
      )}
    </SearchTeamMembersGroupsWrapper>
  );
};
