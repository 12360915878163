import { GroupsModalForm } from 'components/GroupsModal/GroupsModal.form.types';
import { AddMembersType, DeleteMembersType } from 'requests/PATCH_updateStaticGroup.types';
import {
  fetchGroupDetails_node_DynamicGroup,
  fetchGroupDetails_node_StaticGroup,
  fetchGroupDetails_node_StaticGroup_groupJanes_nodes,
  fetchGroupDetails_node_StaticGroup_groupJanes_nodes_jane,
} from 'types/graphql-types';

export const dropdownValueToNumber = (value: string[]) => (!!value?.length ? +value[0] : -1);

function dropdownValueToTime(hours: string[], mins: string[]) {
  return Number.parseInt(hours[0] ?? 0) + Number.parseInt(mins[0] ?? 0) * 60;
}

function isMinMoreThanMax({
  minimumHours,
  minimumMins,
  maximumHours,
  maximumMins,
}: {
  minimumHours: string[];
  minimumMins: string[];
  maximumHours: string[];
  maximumMins: string[];
}) {
  return (
    dropdownValueToTime(minimumHours, minimumMins) < dropdownValueToTime(maximumHours, maximumMins)
  );
}

export const hoursMinsToMins = ({ hours, mins }: { hours: string[]; mins: string[] }) => {
  const minutesNumber = !!mins[0] ? +mins[0] : null;
  const hoursNumber = !!hours[0] ? +hours[0] * 60 : null;
  if (minutesNumber === null && hoursNumber === null) return null;
  return (hoursNumber ?? 0) + (minutesNumber ?? 0);
};

export const timeCriteriaToHoursMins = (mins: number | null) =>
  !mins
    ? { mins: [], hours: [] }
    : {
        mins: [`${mins % 60}`],
        hours: [`${Math.floor(mins / 60)}`],
      };

export const isDynamicFormComplete = (formValues: GroupsModalForm) =>
  !!formValues?.employmentStatuses?.length &&
  !!formValues?.positionTypes?.length &&
  !isMinMoreThanMax({
    minimumHours: formValues.minimumHoursPeriod,
    minimumMins: formValues.minimumMinsPeriod,
    maximumHours: formValues.maximumHoursPeriod,
    maximumMins: formValues.maximumMinsPeriod,
  }) &&
  !isMinMoreThanMax({
    minimumHours: formValues.minimumHoursDay,
    minimumMins: formValues.minimumMinsDay,
    maximumHours: formValues.maximumHoursDay,
    maximumMins: formValues.maximumMinsDay,
  });

export const criteriaTimeValidation = ({
  minHrs,
  maxHrs,
  minMins,
  maxMins,
}: {
  minHrs: number;
  maxHrs: number;
  minMins: number;
  maxMins: number;
}) =>
  ((minHrs !== -1 || minMins !== -1) && minHrs > maxHrs) ||
  (minHrs === maxHrs && minMins > maxMins) ||
  (minHrs > 0 &&
    maxHrs > 0 &&
    minMins > 0 &&
    maxMins > 0 &&
    minHrs === maxHrs &&
    minMins === maxMins);

function generateGroupMembersInitialState(group: fetchGroupDetails_node_StaticGroup) {
  return (group?.groupJanes?.nodes ?? []).map((groupJane) => groupJane.jane);
}

export const generateMemberInitialState = (
  data: fetchGroupDetails_node_StaticGroup | fetchGroupDetails_node_DynamicGroup,
) => {
  if (!data) return [];
  switch (data.__typename) {
    case 'StaticGroup':
      return generateGroupMembersInitialState(data);
    case 'DynamicGroup':
      return [];
    default:
      return [];
  }
};
export function generateGroupPatch(
  data: fetchGroupDetails_node_StaticGroup,
  members: fetchGroupDetails_node_StaticGroup_groupJanes_nodes_jane[],
): { addMembers: AddMembersType[]; deleteMembers: DeleteMembersType[] } {
  const group_jane_ids = generateGroupMembersInitialState(data).map((jane) => jane.id);
  const jane_ids = members.map((member) => member.id);

  const addJanes = jane_ids.filter((id) => !group_jane_ids.includes(id));
  const deleteGroupJanes: fetchGroupDetails_node_StaticGroup_groupJanes_nodes[] = data?.groupJanes
    ?.nodes
    ? data.groupJanes.nodes.filter((groupJane) => !jane_ids.includes(groupJane.jane.id))
    : [];

  return {
    addMembers: addJanes.map((jane) => ({
      jane_id: jane,
    })),
    deleteMembers: deleteGroupJanes.map((groupJane) => ({
      id: Number.parseInt(groupJane.id),
      _destroy: true,
    })),
  };
}

export const staticGroupInitialFormValues = {
  memberSearch: '',
  memberFilter: '',
};

export const convertCriteriaDropdownsToRequestPayload = ({
  minimumHoursPeriod,
  minimumMinsPeriod,
  maximumHoursPeriod,
  maximumMinsPeriod,

  minimumHoursDay,
  minimumMinsDay,
  maximumHoursDay,
  maximumMinsDay,
}: Pick<
  GroupsModalForm,
  | 'minimumHoursPeriod'
  | 'minimumMinsPeriod'
  | 'maximumHoursPeriod'
  | 'maximumMinsPeriod'
  | 'minimumHoursDay'
  | 'minimumMinsDay'
  | 'maximumHoursDay'
  | 'maximumMinsDay'
>) => {
  const minimum_per_day_minutes =
    hoursMinsToMins({
      hours: minimumHoursDay,
      mins: minimumMinsDay,
    }) ?? 0;

  const maximum_per_day_minutes = hoursMinsToMins({
    mins: maximumMinsDay,
    hours: maximumHoursDay,
  });

  const minimum_per_period_minutes =
    hoursMinsToMins({
      mins: minimumMinsPeriod,
      hours: minimumHoursPeriod,
    }) ?? 0;

  const maximum_per_period_minutes = hoursMinsToMins({
    mins: maximumMinsPeriod,
    hours: maximumHoursPeriod,
  });

  return {
    minimum_per_day_minutes,
    maximum_per_day_minutes: maximum_per_day_minutes === 0 ? null : maximum_per_day_minutes, // remove upper boundary if max is 0
    minimum_per_period_minutes,
    maximum_per_period_minutes:
      maximum_per_period_minutes === 0 ? null : maximum_per_period_minutes, // remove upper boundary if max is 0
  };
};
