import { BKJThemeColorType } from '@bookjane2/bookjane-design-library';
import { css } from 'styled-components';
import { OrderStatusEnum } from 'types/graphql-types';

export const calendarDayStatusCardStyleMap = {
  [OrderStatusEnum.unfulfilled]: css`
    background-color: ${({ theme }) => theme.colors.ShiftUnfulfilledStroke};
    color: #fff;
  `,
  [OrderStatusEnum.requested]: css`
    background-color: ${({ theme }) => theme.colors.ShiftRequested};
    color: ${({ theme }) => theme.colors.TextHighlight1};
  `,
  [OrderStatusEnum.cancelled]: css`
    background-color: ${({ theme }) => theme.colors.ShiftCancelled};
    color: ${({ theme }) => theme.colors.TextHighlight1};
  `,
  [OrderStatusEnum.completed]: css`
    background-color: ${({ theme }) => theme.colors.ShiftCompleted};
    color: #fff;
  `,
  [OrderStatusEnum.pending]: css``,
  open: css`
    background-color: ${({ theme }) => theme.colors.ShiftPending};
    color: ${({ theme }) => theme.colors.TextHighlight1};
  `,
  [OrderStatusEnum.in_progress]: css`
    background-color: ${({ theme }) => theme.colors.ShiftInProgress};
    color: ${({ theme }) => theme.colors.TextHighlight1};
  `,
  [OrderStatusEnum.assigned]: css`
    background-color: ${({ theme }) => theme.colors.ShiftAssigned};
    color: ${({ theme }) => theme.colors.TextHighlight1};
  `,
  [OrderStatusEnum.accepted]: css`
    background-color: ${({ theme }) => theme.colors.ShiftAccepted};
    color: ${({ theme }) => theme.colors.TextHighlight1};
  `,
  [OrderStatusEnum.rejected]: css`
    background-color: ${({ theme }) => theme.colors.ShiftRejected};
    color: ${({ theme }) => theme.colors.TextHighlight1};
  `,
  [OrderStatusEnum.swapped]: css`
    background-color: ${({ theme }) => theme.colors.ShiftSwapped};
    color: ${({ theme }) => theme.colors.TextHighlight1};
  `,
};

export const calendarTotalStatusStyleMap: Partial<
  Record<keyof typeof OrderStatusEnum, BKJThemeColorType>
> & { open: BKJThemeColorType } = {
  [OrderStatusEnum.unfulfilled]: 'ShiftUnfulfilledStroke',
  [OrderStatusEnum.requested]: 'ShiftRequested',
  [OrderStatusEnum.cancelled]: 'ShiftCancelled',
  [OrderStatusEnum.completed]: 'ShiftCompleted',
  [OrderStatusEnum.pending]: 'ShiftPending',
  open: 'ShiftOpen',
  [OrderStatusEnum.in_progress]: 'ShiftInProgress',
  [OrderStatusEnum.assigned]: 'ShiftAssigned',
  [OrderStatusEnum.accepted]: 'ShiftAccepted',
  [OrderStatusEnum.swapped]: 'ShiftSwapped',
};
