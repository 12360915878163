import { GenericErrorMessage } from '@bookjane2/bookjane-design-library';
import { GlobalLoading } from 'components/GlobalLoading';
import {
  ILocationFilterProps,
  LocationOptionType,
} from 'components/LocationFilterInput/LocationFilterInput.types';
import { getIsInitialOptionIncludedInData } from 'components/LocationFilterInput/LocationFilterInput.utils';
import { FEATURE_SELECTORS_MAP } from 'guards/FeatureGuard.constants';
import { useGQLQuery } from 'hooks';
import {
  getDefaultLocationDropdownValue,
  useLocationFilterQueryConfig,
} from 'providers/AuthRouteDataProvider/AuthRouteDataProvider.constants';
import { AuthRouteDataContextsType } from 'providers/AuthRouteDataProvider/AuthRouteDataProvider.types';
import { FC, useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { ssfPersistenceService } from 'services/ssfPersistenceService';
import { locationDropdownData } from 'types/graphql-types';
import { createGQLDataContext } from 'utils/createDataContext';
import { withMemo } from 'utils/withMemo';
import { WhiteLabelAccessor } from 'utils/whiteLabelAccessor';
import { useTranslation } from 'hooks/useTranslation';

const { isWhiteLabelled } = WhiteLabelAccessor;

export const GlobalLocationFilterDataContext = createGQLDataContext<LocationOptionType[]>(
  {} as LocationOptionType[],
);

export const AuthRouteDataProvider: FC = withMemo(({ children }) => {
  const { t } = useTranslation();
  const { fetch, data, status, isLoading, ...rest } = useGQLQuery<
    locationDropdownData,
    LocationOptionType[],
    undefined
  >(useLocationFilterQueryConfig());
  const dataRef = useRef<LocationOptionType[]>(
    ssfPersistenceService.getGlobalCachedSSFValue<LocationOptionType[]>('locationFilter') || [],
  );
  const [initLocValue, setInitLocValue] = useState<ILocationFilterProps['value']>(
    ssfPersistenceService.getGlobalCachedSSFValue<ILocationFilterProps['value']>('location'),
  );

  const isViewAllFacilitiesFeatureEnabled = useSelector(
    FEATURE_SELECTORS_MAP['FEATURE_TYPE_ALL_FACILITIES_FILTER'],
  );

  if (!isViewAllFacilitiesFeatureEnabled) {
    ssfPersistenceService.setGlobalCachedSSFValue({
      name: 'viewAllFacilities',
      value: false,
    });
  }
  const contexts: AuthRouteDataContextsType = {
    locationFilter: {
      ...GlobalLocationFilterDataContext,
      initialValue: initLocValue,
      data: data.length > 0 ? data : dataRef.current,
      status,
      ...rest,
    },
  };
  useEffect(() => {
    fetch();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (initLocValue && data) {
      const isInitialOptIncInData = getIsInitialOptionIncludedInData(initLocValue, data);
      if (!isInitialOptIncInData) {
        const defaultLocationDropdownValue = getDefaultLocationDropdownValue(data);
        if (defaultLocationDropdownValue) {
          ssfPersistenceService.setGlobalCachedSSFValue({
            name: 'location',
            value: defaultLocationDropdownValue,
          });
          setInitLocValue(defaultLocationDropdownValue);
        }
      }
    }
    if (!initLocValue && data) {
      const defaultLocationDropdownValue = getDefaultLocationDropdownValue(data);
      if (defaultLocationDropdownValue) {
        ssfPersistenceService.setGlobalCachedSSFValue({
          name: 'location',
          value: defaultLocationDropdownValue,
        });
        setInitLocValue(defaultLocationDropdownValue);
      }
    }
  }, [data, initLocValue]);

  useEffect(() => {
    if (data) {
      dataRef.current = data;

      ssfPersistenceService.setGlobalCachedSSFValue({
        name: 'locationFilter',
        value: data,
      });
    }
  }, [data]);

  const contextArray = Object.values(contexts);
  const hasError = contextArray.some((context) => context.status === 'ERROR');

  if (hasError)
    return (
      <GenericErrorMessage
        showLogo={!isWhiteLabelled()}
        errorMessage={t('errors:SOMETHING_WENT_WRONG_TRY_REFRESHING_PAGE')}
        width="100%"
      />
    );
  if (!initLocValue) return <GlobalLoading />;

  return (
    <GlobalLocationFilterDataContext.Provider value={contexts}>
      {children}
    </GlobalLocationFilterDataContext.Provider>
  );
});

export const useAuthRouteDataContext = (): AuthRouteDataContextsType =>
  useContext(GlobalLocationFilterDataContext);
