import { formatCurrencyCAD } from 'format/formatCurrencyCAD';
import { formatCurrencyGBP } from 'format/formatCurrencyGBP';
import { FC, Fragment, useMemo, useCallback } from 'react';
import { getCurrentlySelectedLocation } from 'utils/getCurrentlySelectedLocation';

interface IFormatCurrencyProps {
  amount: string;
}

const FormatCurrencyView: FC<IFormatCurrencyProps> = ({ amount }) => {
  const { country } = getCurrentlySelectedLocation();

  const formatAmount = useCallback(
    (value: string) => {
      const numericValue = parseFloat(value.replace('$', '').trim());
      return country === 'United Kingdom'
        ? formatCurrencyGBP(numericValue)
        : formatCurrencyCAD(numericValue);
    },
    [country],
  );

  const formattedAmount = useMemo(() => {
    if (amount.includes('-')) {
      const [start, end] = amount.split('-');
      return `${formatAmount(start)} - ${formatAmount(end)}`;
    } else {
      return formatAmount(amount);
    }
  }, [amount, formatAmount]);

  return <Fragment>{formattedAmount}</Fragment>;
};

export const FormatCurrency: FC<IFormatCurrencyProps> = ({ amount }) => {
  return <FormatCurrencyView amount={amount} />;
};
