import { useFormBehaviors } from 'hooks';
import { createContext, FC, useCallback, useContext, useMemo } from 'react';
import { toastService } from 'services';
import { useAPIActions } from 'store/reducers/api/apiSlice';
import { UseFormBehaviors } from 'hooks/useFormBehaviors/useFormBehaviors.types';
import { useCompanyProfilePageDataContext } from 'pages/SettingsPage/CompanyProfilePage/CompanyProfilePage.context';
import { handleGenericErrorMessage } from 'utils/handleGenericErrorMessage';
import { METHOD_PUT } from 'requests';
import { LIABILITY_INSURANCE_PATH } from 'constants/endpoints';
import { CompanyProfilePageForm } from 'pages/SettingsPage/CompanyProfilePage/CompanyProfilePage.constants';
import { useTranslation } from 'hooks/useTranslation';

export const CompanyProfilePageFormContext = createContext(
  {} as UseFormBehaviors<CompanyProfilePageForm>,
);

export const CompanyProfilePageFormProvider: FC = ({ children }) => {
  const { data } = useCompanyProfilePageDataContext();
  const { apiRequest } = useAPIActions();
  const { t } = useTranslation();
  const handleSubmit = useCallback(async () => {
    const liability = data?.me?.agency?.liability;
    const { id, coverageAmount, insuranceNumber, expiryDate } = liability || {};

    await apiRequest(
      {
        __typename: 'LegacyAPI',
        method: METHOD_PUT,
        url: `${LIABILITY_INSURANCE_PATH}/${Number(id)}`,
        body: {
          liability: {
            coverageAmount,
            insuranceNumber,
            expiryDate: expiryDate || null,
          },
        },
      },
      {
        onSuccess: () => {
          toastService.success(t('success:CHANGES_SUCCESSFULLY_SAVED'));
        },
        onError: handleGenericErrorMessage,
      },
    );
  }, [apiRequest, data?.me?.agency?.liability, t]);

  const form = useFormBehaviors<CompanyProfilePageForm>({
    initialState: {
      company_name: data.me?.agency?.name,
    },
    onSubmit: handleSubmit,
  });

  const contextValue = useMemo(() => form, [form]);

  return (
    <CompanyProfilePageFormContext.Provider value={contextValue}>
      {children}
    </CompanyProfilePageFormContext.Provider>
  );
};

export const useCompanyProfilePageFormContext = () => useContext(CompanyProfilePageFormContext);
