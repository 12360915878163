import { BKJThemeTextInput } from '@bookjane2/bookjane-design-library/lib/components/BKJTextInput/BKJTextInput.theme';
import { BKJThemeListItem } from '@bookjane2/bookjane-design-library/lib/components/BKJListItem/BKJListItem.theme';
import { css } from 'styled-components';
import { BKJThemeBadge } from '@bookjane2/bookjane-design-library/lib/components/BKJBadge/BKJBadge.theme';
import { BKJThemeCheck } from '@bookjane2/bookjane-design-library/lib/components/BKJCheckbox/src/BKJCheck.theme';
import { BKJThemeChip } from '@bookjane2/bookjane-design-library/lib/components/BKJChip/BKJChip.theme';
import { BKJThemeDialog } from '@bookjane2/bookjane-design-library/lib/components/BKJDialog/BKJDialog.theme';
import { BKJThemeDropdown } from '@bookjane2/bookjane-design-library/lib/components/BKJDropdown/BKJDropdown.theme';
import { BKJThemeHorizontalRule } from '@bookjane2/bookjane-design-library/lib/components/BKJHorizontalRule/BKJHorizontalRule.types';
import { BKJThemeLoadingSpinner } from '@bookjane2/bookjane-design-library/lib/components/BKJLoadingSpinner/BKJLoadingSpinner.theme';
import { BKJThemeSelect } from '@bookjane2/bookjane-design-library/lib/components/BKJSelect/BKJSelect.theme';
import { BKJThemeToastMessage } from '@bookjane2/bookjane-design-library/lib/components/BKJToastMessage/BKJToastMessage.theme';
import { BKJThemeToggle } from '@bookjane2/bookjane-design-library/lib/components/BKJToggleInput/BKJToggleInput.theme';
import { BKJThemeTypography } from '@bookjane2/bookjane-design-library/lib/components/BKJTypography/BKJTypography.theme';
import {
  IXtmTheme,
  IXtmThemeBorder,
  IXtmThemeBreakpoint,
  IXtmThemeColor,
  IXtmThemeColumn,
  IXtmThemeCommon,
  IXtmThemeFont,
  IXtmThemeShadow,
  IXtmThemeSpacing,
} from './XtmThemeProvider.types';
import { XtmThemeButton } from 'providers/ThemeProviders/XtmButton.theme';
import { XtmThemeCheckbox } from 'providers/ThemeProviders/XtmCheckbox.theme';

export const XtmThemeColor: IXtmThemeColor = {
  TextDarkGrey: '#45424E',
  TextSubtleGrey: '#6D7682',
  TextDisabled: '#8B96A3',
  TextWhite: '#FFFFFF',
  TextHighlight1: '#212121',
  TextHighlight2: '#212121',
  ErrorClick: '#B93751',
  ErrorDefault: '#AC1936',
  ErrorHover: '#991630',
  SuccessClick: '#008A7A',
  SuccessDefault: '#006F62',
  SuccessHover: '#005C52',
  PrimaryClick: '#EDEDED',
  PrimaryDefault: '#212121',
  PrimaryHover: '#6E7683',
  SecondaryPendingDefault: '#F5C9A0',
  SecondaryPendingHover: '#F2B57D',
  SecondaryPendingDraftHover: '#FDF5EE',
  SecondaryAcceptedDefault: '#73DDC0',
  SecondaryAcceptedHover: '#40D0A9',
  SecondaryAcceptedDraftHover: '#EBFAF6',
  OtherDefault: '#BFBFDC',
  OtherHover: '#B5B5D1',
  OtherDraftHover: '#F2F2F8',
  WarningBackground: '#FAE1CB',
  ErrorBackground: '#FFE9ED',
  NewlyEdited: '#FFF1C0',
  GreyDivider: '#c5cad1',
  Grey1: '#F7F9FC',
  Grey2: '#EDF1F5',
  Grey3: '#DAE1E9',
  Grey4: '#AEB8C5',
  Grey5: '#8B96A3',
  Grey6: '#6D7682',
  Grey7: '#45424E',
  White: '#FFFFFF',
  AccentGreen: '#00C18C',
  AccentBrand: '#FFCB5A',
  AccentPurple: '#212121',
  Transparent: 'transparent',
  ShiftInProgress: '#ADEFF3',
  ShiftAccepted: '#73DDC0',
  ShiftAssigned: '#73DDC0',
  ShiftCancelled: '#DAE1E9',
  ShiftCompleted: '#707985',
  ShiftOpen: '#F5C9A0',
  ShiftPending: '#F5C9A0',
  ShiftRejected: '#45424E',
  ShiftRequested: '#F5C9A0',
  ShiftUnfulfilled: '#45424E',
  ShiftTimeOff: '#F7F9FC',
  ShiftOtherPosition: '#bfbfdc',
  ShiftUnfulfilledStroke: '#961E36',
  ShiftSwapped: '#3F6FA8',
};

export const BKJThemeFont: IXtmThemeFont = {
  PoppinsSemiBold: css`
    font-family: 'Poppins SemiBold';
    font-weight: normal;
    font-style: normal;
  `,
  PoppinsMedium: css`
    font-family: 'Poppins Medium';
    font-weight: 400;
    font-style: normal;
  `,
  Poppins: css`
    font-family: 'Poppins Medium';
    font-weight: normal;
    font-style: normal;
  `,
  PoppinsLight: css`
    font-family: 'Poppins Medium';
    font-weight: 300;
    font-style: normal;
  `,
  PoppinsBold: css`
    font-family: 'Poppins SemiBold';
    font-weight: bold;
    font-style: normal;
  `,
  PoppinsText: css`
    font-family: 'Poppins Medium';
    font-weight: normal;
    font-style: normal;
  `,
  PoppinsTextLight: css`
    font-family: 'Poppins Medium';
    font-weight: 300;
    font-style: normal;
  `,
  PoppinsTextMedium: css`
    font-family: 'Poppins Medium';
    font-weight: normal;
    font-style: normal;
  `,
  PoppinsTextBold: css`
    font-family: 'Poppins SemiBold';
    font-weight: bold;
    font-style: normal;
  `,
};

export const BKJThemeShadows: IXtmThemeShadow = {
  Default: css`
    box-shadow: 0px 2px 8px rgba(13, 51, 32, 0.3);
  `,
  DropdownBase: css`
    box-shadow: 0px 2px 8px 0px #0d33204d;
  `,
  InnerShadow: css`
    box-shadow: inset 0px -2px 4px rgba(0, 0, 0, 0.05);
  `,
  TableShadow: css`
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  `,
};

export const BKJThemeColumns: IXtmThemeColumn = {
  TwelveColumn: css`
    display: grid;
    grid-template-columns: repeat(12, 1fr);
  `,
  TenColumn: css`
    display: grid;
    grid-template-columns: repeat(10, 1fr);
  `,
  EightColumn: css`
    display: grid;
    grid-template-columns: repeat(8, 1fr);
  `,
  SixColumn: css`
    display: grid;
    grid-template-columns: repeat(6, 1fr);
  `,
  FourColumn: css`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  `,
  ThreeColumn: css`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  `,
  TwoColumn: css`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  `,
};

export const BKJThemeBorders: IXtmThemeBorder = {
  DefaultBorder: css`
    border-radius: 8px;
  `,
  RoundBorder: css`
    border-radius: 99px;
  `,
  BoxBorder: css`
    border-radius: 2px;
  `,
};

export const BKJThemeBreakpoints: IXtmThemeBreakpoint = {
  Mobile: '500px',
  Tablet: '768px',
  Desktop: '1024px',
};

export const BKJThemeSpacings: IXtmThemeSpacing = {
  Spacing4: '4px',
  Spacing8: '8px',
  Spacing16: '16px',
  Spacing24: '24px',
  Spacing32: '32px',
  Spacing40: '40px',
};

export const BKJThemeCommon: IXtmThemeCommon = {
  Ellipsis: css`
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  `,
};

export const XtmTheme: IXtmTheme = {
  __discriminator: 'BKJThemeType',
  loadingSpinner: BKJThemeLoadingSpinner,
  badge: BKJThemeBadge,
  button: XtmThemeButton,
  textInput: BKJThemeTextInput,
  dialog: BKJThemeDialog,
  check: BKJThemeCheck,
  checkbox: XtmThemeCheckbox,
  chip: BKJThemeChip,
  dropdown: BKJThemeDropdown,
  hr: BKJThemeHorizontalRule,
  fonts: BKJThemeFont,
  colors: XtmThemeColor,
  typography: BKJThemeTypography,
  select: BKJThemeSelect,
  shadows: BKJThemeShadows,
  columns: BKJThemeColumns,
  borders: BKJThemeBorders,
  spacing: BKJThemeSpacings,
  breakpoints: BKJThemeBreakpoints,
  common: BKJThemeCommon,
  toast: BKJThemeToastMessage,
  toggle: BKJThemeToggle,
  listItem: BKJThemeListItem,
};
